import { vpApi } from './vpApi'

const markNotificationAsRead = (id: string) => {
  return vpApi.patch(`/v1/notifications/${id}/mark_read/`)
}

const markAllNotificationsAsRead = () => {
  return vpApi.patch('/v1/notifications/mark_all_read/')
}

export const notificationApi = {
  markNotificationAsRead,
  markAllNotificationsAsRead
}
