import { ActionIcon, Checkbox, Group, Text, Tooltip } from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { useIntl } from 'react-intl'
import { WeekdayValue } from '@/types/notificationSettings'
import { getDayOfWeekName } from '@/utils/date'

type ScheduleItemProps = {
  startTime: string
  endTime: string
  selectedDays: WeekdayValue[]
  isChecked?: boolean
  onToggle: () => void
  onRemove: () => void
  onEdit: () => void
}

export const ScheduleItem = ({
  startTime,
  endTime,
  selectedDays,
  isChecked,
  onToggle,
  onRemove,
  onEdit
}: ScheduleItemProps) => {
  const intl = useIntl()

  return (
    <Group gap="lg">
      <Checkbox
        size="xs"
        checked={isChecked}
        label={
          <Group>
            <Text size="xs" fw={700}>
              {startTime} - {endTime}
            </Text>

            <Text size="xs" c="dimmed">
              {selectedDays.map((d) => getDayOfWeekName(d, 'short')).join(', ')}
            </Text>
          </Group>
        }
        onChange={onToggle}
      />

      <Group gap={4}>
        <Tooltip
          label={intl.formatMessage({
            id: 'deployments.notificationSettings.schedule.update'
          })}
        >
          <ActionIcon size="sm" variant="subtle" onClick={onEdit}>
            <IconPencil style={{ width: '80%', height: '80%' }} />
          </ActionIcon>
        </Tooltip>

        <Tooltip
          label={intl.formatMessage({
            id: 'deployments.notificationSettings.schedule.remove'
          })}
        >
          <ActionIcon size="sm" variant="subtle" onClick={onRemove}>
            <IconTrash style={{ width: '80%', height: '80%' }} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  )
}
