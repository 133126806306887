import { Button, Group, Modal, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { DatasetVersionStatus } from '@/types/dataset'
import { DatasetStatusChip } from '../DatasetStatusChip/DatasetStatusChip'

type ConfirmDatasetCompletionProps = {
  opened: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDatasetCompletion = ({
  opened,
  onClose,
  onConfirm
}: ConfirmDatasetCompletionProps) => {
  return (
    <Modal
      title={<FormattedMessage id="datasets.confirmCompletion.title" />}
      size="lg"
      opened={opened}
      onClose={onClose}
    >
      <Group wrap="nowrap" mb="xl" align="start">
        <ThemeIcon color="green" radius="xl" size="md">
          <IconCheck style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>

        <Stack>
          <Text size="sm" component="div">
            <FormattedMessage
              id="datasets.confirmCompletion.line1"
              values={{
                completed: (
                  <DatasetStatusChip status={DatasetVersionStatus.Completed} />
                )
              }}
            />
          </Text>

          <Text size="sm">
            <FormattedMessage id="datasets.confirmCompletion.line2" />
          </Text>
        </Stack>
      </Group>

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button miw={120} onClick={onConfirm}>
          <FormattedMessage id="datasets.confirmCompletion.ok" />
        </Button>
      </Group>
    </Modal>
  )
}
