import { Box, Button, Center, Group, Loader, Stack, Text } from '@mantine/core'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate, useSearchParams } from 'react-router'
import { useValidateToken } from '@/hooks/useValidateToken'
import { useAcceptWorkspaceInvitation } from '@/queries/workspaceQueries'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'

const WORKSPACE_ID_PARAM = 'workspace_id'

export const AcceptWorkspaceInvitation = () => {
  const navigate = useNavigate()
  const { refreshSession } = useSession()

  const { isTokenValid, token, isPending: isPendingToken } = useValidateToken()

  const {
    mutateAsync: acceptInvitation,
    isPending: isPendingAccept,
    isError,
    isSuccess
  } = useAcceptWorkspaceInvitation()

  const [searchParams] = useSearchParams()
  const workspaceId = searchParams.get(WORKSPACE_ID_PARAM)

  useEffect(() => {
    if (isTokenValid && token && workspaceId) {
      const handleAcceptInvitation = async () => {
        await acceptInvitation({
          workspaceId: workspaceId,
          token: token
        })

        await refreshSession()
        setTimeout(() => void navigate(RouterPath.root), 1000)
      }

      void handleAcceptInvitation()
    }
  }, [
    isTokenValid,
    token,
    workspaceId,
    acceptInvitation,
    refreshSession,
    navigate
  ])

  const showLoader = isPendingToken || isPendingAccept
  const showError = isError || (!isTokenValid && !isPendingToken)

  return (
    <AuthLayout variant="large">
      {showLoader && (
        <Center mih={100}>
          <Group wrap="nowrap">
            <Loader size="xs" />

            <Text size="sm">
              <FormattedMessage id="workspace.members.invite.accept.loading" />
            </Text>
          </Group>
        </Center>
      )}

      {showError && (
        <Stack gap="xl">
          <PlatformAlert variant="error" compact>
            {isTokenValid ? (
              <FormattedMessage id="workspace.members.invite.accept.error" />
            ) : (
              <FormattedMessage id="workspace.members.invite.accept.tokenExpired" />
            )}
          </PlatformAlert>

          <Box ta="center">
            <Button size="xs" component={Link} to={RouterPath.root}>
              <FormattedMessage id="returnHome" />
            </Button>
          </Box>
        </Stack>
      )}

      {isSuccess && (
        <PlatformAlert variant="success" compact>
          <FormattedMessage id="workspace.members.invite.accept.success" />
        </PlatformAlert>
      )}
    </AuthLayout>
  )
}
