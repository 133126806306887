import { Center, Group, Image, Loader } from '@mantine/core'
import VpLogoSrc from '@/assets/vp-logo-480-dark.png'

export const PlatformLoader = () => {
  return (
    <Center mih={400}>
      <Group wrap="nowrap">
        <Image src={VpLogoSrc} w={240} />
        <Loader size="sm" />
      </Group>
    </Center>
  )
}
