import { useNavigate } from 'react-router'
import {
  ApplicationNestedPath,
  ModelNestedPath,
  buildAppLink,
  buildModelDetailsLink
} from '@/router/paths'

export const useModelStepNavigation = ({
  appId,
  modelId
}: {
  appId: string
  modelId: string
}) => {
  const navigate = useNavigate()

  const goToModelsScreen = (replace = false) => {
    void navigate(buildAppLink(appId, ApplicationNestedPath.models), {
      replace
    })
  }

  const goToBaseModelScreen = (replace = false) => {
    void navigate(
      buildModelDetailsLink(appId, modelId, ModelNestedPath.selectBaseModel),
      {
        replace
      }
    )
  }

  const goToDatasetSelectionScreen = (replace = false) => {
    void navigate(
      buildModelDetailsLink(appId, modelId, ModelNestedPath.selectDataset),
      {
        replace
      }
    )
  }

  const goToLabelDataScreen = (replace = false) => {
    void navigate(
      {
        pathname: buildModelDetailsLink(
          appId,
          modelId,
          ModelNestedPath.labelData
        )
      },
      {
        replace
      }
    )
  }

  const goToModelTrainingScreen = (replace = false) => {
    void navigate(
      buildModelDetailsLink(appId, modelId, ModelNestedPath.training),
      {
        replace
      }
    )
  }

  return {
    goToModelsScreen,
    goToBaseModelScreen,
    goToDatasetSelectionScreen,
    goToLabelDataScreen,
    goToModelTrainingScreen
  }
}
