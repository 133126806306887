import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text
} from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeactivateWorkspaceMember } from '@/queries/workspaceQueries'
import { showToast } from '@/theme/notifications'
import { WorkspaceMember } from '@/types/workspace'

type DeactivateMemberModalProps = {
  opened: boolean
  member: WorkspaceMember | null
  workspaceName: string
  workspaceId: string
  onClose: () => void
}

export const DeactivateMemberModal = ({
  opened,
  member,
  workspaceName,
  workspaceId,
  onClose
}: DeactivateMemberModalProps) => {
  const intl = useIntl()
  const { mutateAsync: updateMember, isPending } =
    useDeactivateWorkspaceMember()

  const handleUpdateMember = async () => {
    try {
      await updateMember({
        workspaceId,
        memberId: member?.id || ''
      })

      showToast(
        intl.formatMessage({ id: 'workspace.members.deactivate.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'workspace.members.deactivate.error' })

      showToast(message, 'error')
    }

    onClose()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="workspace.members.deactivate" />}
      mah={100}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {member && (
          <Stack>
            <Text size="sm" mb="lg">
              <FormattedMessage
                id="workspace.members.deactivate.areYouSure"
                values={{
                  memberName: <strong>{member.user.full_name}</strong>,
                  workspaceName: <strong>{workspaceName}</strong>
                }}
              />
            </Text>

            <Group justify="end">
              <Button miw={120} variant="default" onClick={onClose}>
                <FormattedMessage id="cancel" />
              </Button>

              <Button
                type="submit"
                color="red"
                onClick={() => void handleUpdateMember()}
              >
                <FormattedMessage id="workspace.members.deactivate" />
              </Button>
            </Group>
          </Stack>
        )}
      </Box>
    </Modal>
  )
}
