import {
  AspectRatio,
  Box,
  Button,
  Group,
  Image,
  SegmentedControl,
  rem
} from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { showToast } from '@/theme/notifications'

type MediaType = 'video' | 'image'

type MediaItemProps = {
  eventId: string
  video_path: string
  video_bbox_path: string
  image_path: string
  type: MediaType
}

export const MediaItem = ({
  eventId,
  video_path,
  video_bbox_path,
  image_path,
  type
}: MediaItemProps) => {
  const intl = useIntl()
  const [isDownloading, setIsDownloading] = useState(false)
  const [videoType, setVideoType] = useState<'original' | 'labeled'>('original')

  const handleVideoDownload = async (name: string, path: string) => {
    setIsDownloading(true)

    try {
      const response = await fetch(path)
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.setAttribute('download', `${name}.mp4`)
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    } catch {
      const message = intl.formatMessage({ id: 'events.download.video.error' })
      showToast(message, 'error')
    }

    setIsDownloading(false)
  }

  const activeVideoPath =
    videoType === 'original' ? video_path : video_bbox_path

  if (!image_path && !video_path && !video_bbox_path) {
    return null
  }

  return (
    <Box pos="relative">
      <AspectRatio ratio={16 / 9}>
        {type === 'video' ? (
          <Box>
            <video
              key={activeVideoPath}
              width="100%"
              style={{ borderRadius: 8 }}
              controls
            >
              <source src={activeVideoPath} type="video/mp4" />
            </video>
          </Box>
        ) : (
          <Box>
            <Image
              src={image_path}
              fallbackSrc={ImagePlaceholder}
              radius={8}
              h="100%"
              fit="contain"
            />
          </Box>
        )}
      </AspectRatio>

      {type === 'video' && (
        <Box pos="absolute" top={8} right={8}>
          <Group>
            {video_path && video_bbox_path && (
              <SegmentedControl
                color="brand-secondary"
                size="xs"
                withItemsBorders={false}
                value={videoType}
                styles={{
                  root: {
                    padding: '2px'
                  },
                  innerLabel: {
                    fontSize: rem(11),
                    lineHeight: 'normal' // gets overriden by image gallery styles, so we need to reset it
                  }
                }}
                data={[
                  {
                    value: 'original',
                    label: intl.formatMessage({ id: 'events.video.original' })
                  },
                  {
                    value: 'labeled',
                    label: intl.formatMessage({ id: 'events.video.labeled' })
                  }
                ]}
                onChange={(value) =>
                  setVideoType(value as 'original' | 'labeled')
                }
              />
            )}

            <Button
              size="compact-xs"
              color="brand-secondary"
              leftSection={<IconDownload size={12} />}
              loading={isDownloading}
              onClick={() => void handleVideoDownload(eventId, activeVideoPath)}
            >
              <FormattedMessage id="download" />
            </Button>
          </Group>
        </Box>
      )}
    </Box>
  )
}
