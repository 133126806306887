import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Select,
  Stack,
  Text,
  TextInput
} from '@mantine/core'
import { isEmail, useForm } from '@mantine/form'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MemberInvitation, WorkspaceMemberRole } from '@/types/workspace'
import { generateUUID } from '@/utils/uuid'
import { useRoleDropdownOptions } from '../useRoleDropdownOptions'

type MemberValues = MemberInvitation & {
  uuid: string
}

type FormValues = {
  members: MemberValues[]
}

export type SubmitValues = {
  members: MemberInvitation[]
}

type InviteMembersFormProps = {
  showSkipButton?: boolean
  onSkip?: () => void
  onSubmit: (values: SubmitValues) => void
}

export const InviteMembersForm = ({
  showSkipButton,
  onSkip,
  onSubmit
}: InviteMembersFormProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      members: [
        {
          uuid: generateUUID(),
          email: '',
          role: WorkspaceMemberRole.Admin
        }
      ]
    },
    validateInputOnBlur: true,
    validate: {
      members: {
        email: isEmail(
          intl.formatMessage({
            id: 'validation.invalidEmail'
          })
        )
      }
    }
  })

  const workspaceMemberRoleOptions = useRoleDropdownOptions()

  const handleAddMember = () => {
    form.insertListItem('members', {
      uuid: generateUUID(),
      email: '',
      role: WorkspaceMemberRole.Admin
    })
  }

  const handleRemoveMember = (index: number) => {
    form.removeListItem('members', index)
  }

  const handleSkip = () => {
    onSkip?.()
  }

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      members: values.members.map((item) => ({
        email: item.email,
        role: item.role
      }))
    })
  }

  return (
    <form noValidate onSubmit={form.onSubmit(handleSubmit)}>
      <Text size="sm" mb="lg">
        <FormattedMessage id="workspace.members.description" />
      </Text>

      <Stack gap="md">
        {form.getValues().members.map((item, index) => (
          <Flex key={item.uuid} gap="xs">
            <TextInput
              placeholder={intl.formatMessage({ id: 'email' })}
              style={{ flex: 1 }}
              {...form.getInputProps(`members.${index}.email`)}
            />

            <Select
              allowDeselect={false}
              value={String(form.getValues().members[index].role)}
              placeholder={intl.formatMessage({ id: 'workspace.members.role' })}
              data={workspaceMemberRoleOptions}
              onChange={(value) => {
                form.setFieldValue(`members.${index}.role`, Number(value))
              }}
            />

            <ActionIcon
              variant="subtle"
              radius="xl"
              size="lg"
              disabled={form.getValues().members.length === 1}
              onClick={() => handleRemoveMember(index)}
            >
              <IconTrash style={{ width: '50%', height: '50%' }} />
            </ActionIcon>
          </Flex>
        ))}
      </Stack>

      <Box mt="sm">
        <Button
          leftSection={<IconPlus size={14} />}
          variant="subtle"
          size="compact-sm"
          onClick={handleAddMember}
        >
          <FormattedMessage id="workspace.members.add" />
        </Button>
      </Box>

      <Group justify="end" mt="xl">
        {showSkipButton && (
          <Button type="button" variant="outline" onClick={handleSkip}>
            <FormattedMessage id="workspace.members.invite.skip" />
          </Button>
        )}

        <Button type="submit">
          <FormattedMessage id="workspace.members.invite" />
        </Button>
      </Group>
    </form>
  )
}
