export enum WorkspaceType {
  Organization = 1,
  Personal = 2
}

export type Workspace = {
  id: string
  name: string
  workspace_type: WorkspaceType
  created_by: string
  created_at: string
  updated_at: string
}

export enum WorkspaceMemberRole {
  Owner = 1,
  Admin = 2,
  User = 3,
  Viewer = 4,
  Labeler = 5
}

export enum WorkspaceMemberStatus {
  Active = 1,
  Inactive = 2,
  Pending = 3
}

export type WorkspaceMember = {
  id: string
  role: WorkspaceMemberRole
  is_active: boolean
  invite_accepted: boolean
  is_selected: boolean
  created_at: string
  updated_at: string
  user: {
    id: string
    email: string
    full_name: string
  }
  status: WorkspaceMemberStatus
  workspace: Workspace
}

export type MemberInvitation = {
  email: string
  role: WorkspaceMemberRole
}
