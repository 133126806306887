import { Anchor, Card, Group, ThemeIcon } from '@mantine/core'
import { IconBell, IconBolt, IconRocket } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { CrudAction, Resource } from '@/types/permissions'
import classes from './AppNavLinks.module.css'

type NavItemProps = {
  isActive: boolean
  icon: ReactNode
  label: string
  to: string
}

const NavItem = ({ isActive, icon, label, to }: NavItemProps) => {
  return (
    <li>
      <Card bg={isActive ? 'brand-primary.0' : undefined} p={6}>
        <Anchor
          w="100%"
          size="sm"
          c="brand-primary.9"
          underline="never"
          fw={500}
          component={Link}
          to={to}
        >
          <Group wrap="nowrap" gap="xs">
            <ThemeIcon
              variant="transparent"
              c="brand-primary.9"
              size="md"
              radius="xl"
            >
              {icon}
            </ThemeIcon>

            <FormattedMessage id={label} />
          </Group>
        </Anchor>
      </Card>
    </li>
  )
}

type AppNavLinksProps = {
  appId: string
}

export const AppNavLinks = ({ appId }: AppNavLinksProps) => {
  const { pathname } = useLocation()

  const [canReadMlModels] = useHasPermissions(Resource.MlModels, [
    CrudAction.Read
  ])

  const [canReadDeployments] = useHasPermissions(Resource.Deployments, [
    CrudAction.Read
  ])

  const [canReadEvents] = useHasPermissions(Resource.Events, [CrudAction.Read])

  return (
    <ul className={classes['app-nav-links']}>
      {canReadMlModels && (
        <NavItem
          isActive={pathname.includes(ApplicationNestedPath.models)}
          icon={<IconBolt style={{ width: '80%', height: '80%' }} />}
          label="appNav.models"
          to={buildAppLink(appId, ApplicationNestedPath.models)}
        />
      )}

      {canReadDeployments && (
        <NavItem
          isActive={pathname.includes(ApplicationNestedPath.deployments)}
          icon={<IconRocket style={{ width: '80%', height: '80%' }} />}
          label="appNav.deployments"
          to={buildAppLink(appId, ApplicationNestedPath.deployments)}
        />
      )}

      {canReadEvents && (
        <NavItem
          isActive={pathname.includes(ApplicationNestedPath.events)}
          icon={<IconBell style={{ width: '80%', height: '80%' }} />}
          label="appNav.events"
          to={buildAppLink(appId, ApplicationNestedPath.events)}
        />
      )}
    </ul>
  )
}
