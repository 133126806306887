import { Divider, Group, Input, Select } from '@mantine/core'
import { ReactNode } from 'react'

const HOURS = Array.from({ length: 24 }, (_, index) =>
  index.toString().padStart(2, '0')
)

const MINUTES = ['00', '15', '30', '45', '59']

type TimePickerProps = {
  value?: string
  label?: string | ReactNode
  error?: string
  disabled?: boolean
  size?: string
  maxWidth?: number
  onChange: (value: string) => void
}

export const TimePicker = ({
  value,
  label,
  error,
  disabled,
  size,
  maxWidth,
  onChange
}: TimePickerProps) => {
  const hourValue = value?.split(':')[0]
  const minuteValue = value?.split(':')[1]

  return (
    <Input.Wrapper label={label} error={error} w="100%" maw={maxWidth}>
      <Group
        gap={0}
        mb={error ? 4 : 0}
        wrap="nowrap"
        styles={(theme) => ({
          root: {
            border: `1px solid ${error ? theme.colors.red[6] : theme.colors.gray[4]}`,
            borderRadius: theme.radius.sm
          }
        })}
      >
        <Select
          size={size}
          value={hourValue}
          data={HOURS}
          disabled={disabled}
          allowDeselect={false}
          maxDropdownHeight={320}
          checkIconPosition="right"
          w="100%"
          styles={{
            input: {
              border: 'none'
            }
          }}
          onChange={(value) => {
            onChange(`${value}:${minuteValue || '00'}`)
          }}
        />

        <Divider size="xs" orientation="vertical" />

        <Select
          size={size}
          value={minuteValue}
          data={MINUTES}
          disabled={disabled}
          allowDeselect={false}
          maxDropdownHeight={320}
          checkIconPosition="right"
          w="100%"
          styles={{
            input: {
              border: 'none'
            }
          }}
          onChange={(value) => {
            onChange(`${hourValue || '12'}:${value}`)
          }}
        />
      </Group>
    </Input.Wrapper>
  )
}
