import {
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  CloseButton,
  Group,
  NumberInput,
  Popover,
  Radio,
  Stack,
  Text,
  ThemeIcon,
  Tooltip
} from '@mantine/core'
import { IconSquareCheck } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'

type AlertConfigProps = {
  alertType: 'alert_on_enter' | 'alert_on_exit'
  dwellTime: number | null
  isAlertOnExitDisabled?: boolean
  alertOnExitDisabledTooltip?: string | ReactNode
  onChange: (
    alertType: 'alert_on_enter' | 'alert_on_exit',
    dwellTime: number | null
  ) => void
}

export const AlertConfig = ({
  alertType,
  dwellTime,
  isAlertOnExitDisabled,
  alertOnExitDisabledTooltip,
  onChange
}: AlertConfigProps) => {
  const intl = useIntl()

  const [isPopoverOpened, setIsPopoverOpened] = useState(false)
  const [cachedAlertType, setCachedAlertType] = useState(alertType)
  const [cachedDwellTime, setCachedDwellTime] = useState<number | ''>(
    dwellTime ?? ''
  )

  const handleSave = () => {
    onChange(
      cachedAlertType,
      cachedDwellTime === '' || cachedAlertType === 'alert_on_exit'
        ? null
        : cachedDwellTime
    )

    setIsPopoverOpened(false)
  }

  return (
    <Popover
      opened={isPopoverOpened}
      width={480}
      position="bottom"
      shadow="md"
      withOverlay
      onChange={setIsPopoverOpened}
    >
      <Popover.Target>
        <Anchor
          component="button"
          type="button"
          size="xs"
          styles={{
            root: {
              textTransform: 'lowercase'
            }
          }}
          onClick={() => setIsPopoverOpened((o) => !o)}
        >
          <Group wrap="nowrap" gap={4} align="center">
            <ThemeIcon variant="transparent" color="brand-primary" size="xs">
              <IconSquareCheck
                style={{ width: '90%', height: '90%' }}
                stroke={3}
              />
            </ThemeIcon>

            {alertType === 'alert_on_enter' ? (
              <FormattedMessage id="logic.alert.onEnter.label" />
            ) : (
              <FormattedMessage id="logic.alert.onExit.label" />
            )}

            {alertType === 'alert_on_enter' && dwellTime && (
              <Badge
                size="xs"
                styles={{
                  label: {
                    textTransform: 'lowercase'
                  }
                }}
              >
                <FormattedMessage
                  id="logic.alert.seconds"
                  values={{
                    value: dwellTime
                  }}
                />
              </Badge>
            )}
          </Group>
        </Anchor>
      </Popover.Target>

      <Popover.Dropdown>
        <Group wrap="nowrap" justify="space-between" align="start">
          <Text fw={600} size="sm" mb="xl">
            <FormattedMessage id="logic.alert.configure" />
          </Text>

          <CloseButton size="sm" onClick={() => setIsPopoverOpened(false)} />
        </Group>

        <Stack gap="xl" mb="xl">
          <Box>
            <Radio
              mb="sm"
              checked={cachedAlertType === 'alert_on_enter'}
              label={<FormattedMessage id="logic.alert.onEnter.label" />}
              description={
                <FormattedMessage id="logic.alert.onEnter.description" />
              }
              styles={{
                label: {
                  fontWeight: 600
                }
              }}
              onChange={() => setCachedAlertType('alert_on_enter')}
            />

            {cachedAlertType === 'alert_on_enter' && (
              <Card bg="gray.1" pl="xl">
                <NumberInput
                  maw={240}
                  value={cachedDwellTime}
                  placeholder={intl.formatMessage({
                    id: 'logic.alert.dwellTime.placeholder'
                  })}
                  label={<FormattedMessage id="logic.alert.dwellTime.label" />}
                  description={
                    <FormattedMessage id="logic.alert.dwellTime.description" />
                  }
                  inputWrapperOrder={['label', 'input', 'description', 'error']}
                  min={0}
                  max={999999} // around 11.5 days
                  onChange={(value) =>
                    setCachedDwellTime(typeof value === 'number' ? value : '')
                  }
                />
              </Card>
            )}
          </Box>

          <Tooltip
            w={220}
            disabled={!isAlertOnExitDisabled}
            label={alertOnExitDisabledTooltip}
            position="bottom-start"
            refProp="rootRef"
            multiline
          >
            <Radio
              disabled={isAlertOnExitDisabled}
              checked={cachedAlertType === 'alert_on_exit'}
              label={<FormattedMessage id="logic.alert.onExit.label" />}
              description={
                <FormattedMessage id="logic.alert.onExit.description" />
              }
              styles={{
                label: {
                  fontWeight: 600
                }
              }}
              onChange={() => setCachedAlertType('alert_on_exit')}
            />
          </Tooltip>
        </Stack>

        <PlatformAlert variant="info" mb="xl" compact>
          <FormattedMessage id="logic.alert.info" />
        </PlatformAlert>

        <Button type="button" size="xs" fullWidth onClick={handleSave}>
          <FormattedMessage id="save" />
        </Button>
      </Popover.Dropdown>
    </Popover>
  )
}
