import { Checkbox, Group, MultiSelect, MultiSelectProps } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { LabelWithHint } from '@/components/ui-shared/LabelWithHint/LabelWithHint'
import { BlurOptions } from '@/types/deployment'

type OptionWithCheckboxProps = {
  name: string
  isChecked: boolean
}

const OptionWithCheckbox = ({ name, isChecked }: OptionWithCheckboxProps) => {
  return (
    <Group gap="sm">
      <Checkbox
        checked={isChecked}
        tabIndex={-1}
        style={{ pointerEvents: 'none' }}
        aria-hidden
        onChange={() => {}}
      />
      <span>{name}</span>
    </Group>
  )
}

type BlurObjectSelectProps = {
  size?: MultiSelectProps['size']
  blurOptions: BlurOptions
  onChange: (blurOptions: BlurOptions) => void
}

export const BlurObjectSelect = ({
  size,
  blurOptions,
  onChange
}: BlurObjectSelectProps) => {
  const intl = useIntl()

  const value = ['blur_face', 'blur_lp'].filter(
    (key) => blurOptions[key as keyof BlurOptions]
  )

  const selectOptions = [
    {
      label: intl.formatMessage({ id: 'events.blur.face' }),
      value: 'blur_face'
    },
    {
      label: intl.formatMessage({ id: 'events.blur.licensePlate' }),
      value: 'blur_lp'
    }
  ]

  const handleOnChange = (value: string[]) => {
    const newBlurOptions = {
      ...blurOptions,
      blur_face: value.includes('blur_face'),
      blur_lp: value.includes('blur_lp')
    }

    onChange(newBlurOptions)
  }

  return (
    <MultiSelect
      size={size}
      label={
        <LabelWithHint
          label={<FormattedMessage id="events.blur.objects" />}
          hint={<FormattedMessage id="events.blur.tooltip" />}
        />
      }
      placeholder={
        value.length === 0
          ? intl.formatMessage({
              id: 'events.blur.placeholder'
            })
          : undefined
      }
      value={value}
      data={selectOptions}
      styles={{
        pill: {
          backgroundColor: 'var(--mantine-color-brand-primary-4)',
          color: 'var(--mantine-color-white)',
          textTransform: 'uppercase',
          fontWeight: 600
        }
      }}
      renderOption={({ option, checked }) => (
        <OptionWithCheckbox name={option.label} isChecked={checked || false} />
      )}
      onChange={handleOnChange}
    />
  )
}
