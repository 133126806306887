import { DeploymentConfig } from '@/types/deployment'
import { vpApi } from './vpApi'

type CreateDeploymentResponse = {
  task_id: string
}

const createDeployment = async (data: DeploymentConfig) => {
  const res = await vpApi.post<CreateDeploymentResponse>(
    '/v1/deployments/',
    data
  )
  return res.data
}

const updateDeployment = async (data: DeploymentConfig) => {
  const res = await vpApi.put<CreateDeploymentResponse>(
    '/v1/deployments/update-deployments/',
    data
  )
  return res.data
}

const stopDeployment = (deploymentId: string) => {
  return vpApi.post(`/v1/deployments/${deploymentId}/stop/`)
}

const startDeployment = (deploymentId: string) => {
  return vpApi.post(`/v1/deployments/${deploymentId}/start/`)
}

const removeAppDeployment = (applicationId: string) => {
  return vpApi.delete(`/v1/deployments/delete-deployments/${applicationId}/`)
}

export const deploymentApi = {
  createDeployment,
  updateDeployment,
  stopDeployment,
  startDeployment,
  removeAppDeployment
}
