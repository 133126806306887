export type PermissionsMap = {
  [key in Resource]?: ResourceAction[]
}

export enum Resource {
  Subscriptions = 'subscriptions',
  Workspaces = 'workspaces',
  WorkspaceUsers = 'workspace_users',
  Applications = 'applications',
  MlModels = 'ml_models',
  Datasets = 'datasets',
  Annotations = 'annotations',
  Trainings = 'trainings',
  Devices = 'devices',
  Cameras = 'cameras',
  Deployments = 'deployments',
  Events = 'events'
}

export type ResourceAction = CrudAction | EventAction

export enum CrudAction {
  Read = 'retrieve',
  Create = 'create',
  Update = 'update',
  Delete = 'destroy'
}

export enum EventAction {
  AddNote = 'add_note',
  Download = 'download'
}
