import {
  Anchor,
  Box,
  Center,
  Paper,
  useMantineColorScheme
} from '@mantine/core'
import { ReactNode } from 'react'
import { Link } from 'react-router'
import { VpLogo } from '@/components/ui-shared/VpLogo/VpLogo'
import { useDeviceDetect } from '@/hooks/useDeviceDetect'
import { RouterPath } from '@/router/paths'

type Variant = 'small' | 'large'

type AuthLayoutProps = {
  variant?: Variant
  children: ReactNode
}

const SMALL_MAX_WIDTH = 480
const LARGE_MAX_WIDTH = 780

export const AuthLayout = ({
  children,
  variant = 'small'
}: AuthLayoutProps) => {
  const { isDesktop } = useDeviceDetect()
  const { colorScheme } = useMantineColorScheme()
  const isDarkMode = colorScheme === 'dark'
  const maxWidth = variant === 'small' ? SMALL_MAX_WIDTH : LARGE_MAX_WIDTH

  return (
    <Center mih={600}>
      <Box w="100%" maw={isDesktop ? maxWidth : undefined} p="xs">
        <Box w={240} mx="auto" my="md">
          <Anchor component={Link} to={RouterPath.login}>
            <VpLogo variant={isDarkMode ? 'light' : 'dark'} />
          </Anchor>
        </Box>

        <Paper px={isDesktop ? 'lg' : 'md'} py="xl" shadow="sm" withBorder>
          {children}
        </Paper>
      </Box>
    </Center>
  )
}
