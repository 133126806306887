import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { deploymentApi } from '@/api/deploymentApi'
import { getPageByUrl } from '@/api/vpApi'
import { LibraryLogic } from '@/types/businessLogic'
import { Deployment } from '@/types/deployment'
import { PaginatedResponse } from '@/types/pagination'
import { objectToQueryString } from '@/utils/url'

export const deploymentQueryKeys = {
  all: ['deployments'] as const,
  list: (appId: string) => [...deploymentQueryKeys.all, 'list', appId] as const,
  libraryLogics: () => [...deploymentQueryKeys.all, 'libraryLogics'] as const
}

export const useGetLibraryLogics = () => {
  return useInfiniteQuery({
    queryKey: deploymentQueryKeys.libraryLogics(),
    queryFn: ({ pageParam }) =>
      getPageByUrl<PaginatedResponse<LibraryLogic[]>>(pageParam),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/deployments/business-logics/?${objectToQueryString({
      limit: 100
    })}`
  })
}

export const useGetDeployments = (appId: string) => {
  return useInfiniteQuery({
    queryKey: deploymentQueryKeys.list(appId),
    queryFn: ({ pageParam }) =>
      getPageByUrl<PaginatedResponse<Deployment[]>>(pageParam),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/deployments/?${objectToQueryString({
      limit: 100,
      application_id: appId
    })}`
  })
}

export const useCreateDeployment = () => {
  return useMutation({
    mutationFn: deploymentApi.createDeployment
  })
}

export const useUpdateDeployment = () => {
  return useMutation({
    mutationFn: deploymentApi.updateDeployment
  })
}

export const useStopDeployment = () => {
  return useMutation({
    mutationFn: deploymentApi.stopDeployment
  })
}

export const useStartDeployment = () => {
  return useMutation({
    mutationFn: deploymentApi.startDeployment
  })
}

export const useRemoveAppDeployment = () => {
  return useMutation({
    mutationFn: deploymentApi.removeAppDeployment
  })
}

// export const useUpdateNotificationSettings = (appId: string) => {
//   const queryClient = useQueryClient()

//   return useMutation({
//     mutationFn: deploymentApi.updateNotificationSettings,
//     onSuccess: () => {
//       void queryClient.invalidateQueries({
//         queryKey: deploymentQueryKeys.list(appId)
//       })
//     }
//   })
// }
