import { Scope, ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { ReactNode, useState } from 'react'
import { generateUUID } from '@/utils/uuid'
import { ErrorPage } from './ErrorPage/ErrorPage'

type ErrorBoundaryProps = {
  children: ReactNode
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const [errorId, setErrorId] = useState<string>('')

  const handleBeforeCapture = (scope: Scope) => {
    const id = generateUUID()
    setErrorId(id)
    scope.setTag('errorId', id)
  }

  return (
    <SentryErrorBoundary
      fallback={<ErrorPage errorId={errorId} />}
      beforeCapture={handleBeforeCapture}
    >
      {children}
    </SentryErrorBoundary>
  )
}
