import { ReactNode, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useSearchParams } from 'react-router'
import baseMessages from './translations/en.json'

const DEFAULT_LOCALE = 'en'

type I18nProviderProps = {
  children: ReactNode
}

type Messages = Record<string, string>
type MessagesModule = { default: Messages }

type LogMissingTranslationsParams = {
  base: Messages
  target: Messages
  locale: string
}

const logMissingTranslations = ({
  base,
  target,
  locale
}: LogMissingTranslationsParams) => {
  const baseKeys = Object.keys(base)
  const targetKeys = Object.keys(target)

  const missingKeys = baseKeys.filter(
    (baseKey) => !targetKeys.includes(baseKey)
  )

  if (process.env.NODE_ENV === 'development') {
    console.warn(
      `Some translations are missing for locale '${locale}':`,
      missingKeys
    )
  }
}

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const [messages, setMessages] = useState<Messages>(baseMessages)
  const [messagesAreLoaded, setMessagesAreLoaded] = useState(false)
  const [searchParams] = useSearchParams()
  const locale = searchParams.get('locale') || DEFAULT_LOCALE

  useEffect(() => {
    // dynamically import json based on current locale
    const importMessages = async () => {
      try {
        const data = (await import(
          `./translations/${locale}.json`
        )) as MessagesModule

        logMissingTranslations({
          base: baseMessages,
          target: data.default,
          locale
        })

        setMessages({ ...baseMessages, ...data.default })
      } catch (err) {
        console.error('Unable to import module:', err)
      }

      setMessagesAreLoaded(true)
    }

    if (locale === DEFAULT_LOCALE) {
      setMessagesAreLoaded(true)
    } else {
      void importMessages()
    }
  }, [locale])

  return (
    messagesAreLoaded && (
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    )
  )
}
