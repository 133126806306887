import { NotificationData, notifications } from '@mantine/notifications'
import { IconCheck, IconMessageCircleFilled, IconX } from '@tabler/icons-react'

type NotificationType = 'success' | 'error' | 'info'

export const showToast = (
  message: string,
  type: NotificationType,
  options?: Partial<NotificationData>
) => {
  switch (type) {
    case 'success':
      notifications.show({
        message,
        color: 'teal',
        icon: <IconCheck size={16} stroke={3} />,
        withBorder: true,
        autoClose: 3000,
        style: {
          backgroundColor: `var(--mantine-color-green-0)`,
          borderColor: `var(--mantine-color-green-4)`
        },
        ...options
      })
      break

    case 'error':
      notifications.show({
        message,
        color: 'red',
        icon: <IconX size={16} stroke={3} />,
        withBorder: true,
        autoClose: 6000,
        style: {
          backgroundColor: `var(--mantine-color-red-0)`,
          borderColor: `var(--mantine-color-red-4)`
        },

        ...options
      })
      break

    case 'info':
      notifications.show({
        message,
        color: 'blue',
        icon: <IconMessageCircleFilled size={14} />,
        withBorder: true,
        autoClose: 6000,
        style: {
          backgroundColor: `var(--mantine-color-blue-0)`,
          borderColor: `var(--mantine-color-blue-4)`
        },
        ...options
      })
      break

    default:
      return null
  }
}
