import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useUpdateWorkspaceMember } from '@/queries/workspaceQueries'
import { showToast } from '@/theme/notifications'
import { WorkspaceMember } from '@/types/workspace'
import { SubmitValues, UpdateMemberForm } from './UpdateMemberForm'

type UpdateMemberModalProps = {
  workspaceId: string
  member: WorkspaceMember | null
  opened: boolean
  onClose: () => void
}

export const UpdateMemberModal = ({
  workspaceId,
  member,
  opened,
  onClose
}: UpdateMemberModalProps) => {
  const intl = useIntl()

  const { mutateAsync: updateMember, isPending } = useUpdateWorkspaceMember()

  const handleUpdateMember = async (formValues: SubmitValues) => {
    try {
      await updateMember({
        workspaceId,
        memberId: member?.id || '',
        ...formValues
      })

      showToast(
        intl.formatMessage({ id: 'workspace.members.update.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'workspace.members.update.error' })

      showToast(message, 'error')
    }

    onClose()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="workspace.members.update" />}
      mah={100}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {member && (
          <UpdateMemberForm
            name={member.user.full_name}
            email={member.user.email}
            role={member.role}
            onCancel={onClose}
            onUpdate={(formValues) => void handleUpdateMember(formValues)}
          />
        )}
      </Box>
    </Modal>
  )
}
