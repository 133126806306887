import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { useUpdateApplication } from '@/queries/applicationQueries'
import { showToast } from '@/theme/notifications'
import { Application } from '@/types/app'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import {
  FormValues,
  UpdateApplicationForm
} from './UpdateApplicationForm/UpdateApplicationForm'

type UpdateAppErrorProps = {
  error: ApiError
}

const UpdateAppError = ({ error }: UpdateAppErrorProps) => {
  const { errorMessage } = error

  return (
    <PlatformAlert variant="error" compact>
      {errorMessage || <FormattedMessage id="apps.updateApp.error" />}
    </PlatformAlert>
  )
}

type UpdateApplicationModalProps = {
  app: Application
  opened: boolean
  onClose: () => void
  onAppUpdated: () => void
}

export const UpdateApplicationModal = ({
  app,
  opened,
  onClose,
  onAppUpdated
}: UpdateApplicationModalProps) => {
  const intl = useIntl()

  const {
    mutateAsync: updateApp,
    isPending,
    isError,
    error
  } = useUpdateApplication(app.id)

  const handleCreateApp = async ({ appName }: FormValues) => {
    const data = { name: appName }
    await updateApp({ id: app.id, data })

    showToast(intl.formatMessage({ id: 'apps.updateApp.success' }), 'success')

    onAppUpdated()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="apps.updateApp" />}
      onClose={onClose}
    >
      <Box pos="relative">
        {isError && (
          <Box mb="lg">
            <UpdateAppError error={getApiError(error)} />
          </Box>
        )}

        <LoadingOverlay visible={isPending} />

        <UpdateApplicationForm
          appName={app.name}
          onCancel={onClose}
          onSubmit={(values) => void handleCreateApp(values)}
        />
      </Box>
    </Modal>
  )
}
