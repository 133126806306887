import { SCENARIO_SIMULATOR_QUERY_PARAM } from '@/config/main'

export const isScenarioSimualatorEnabled = () => {
  const params = new URLSearchParams(window.location.search)

  return (
    process.env.NODE_ENV === 'development' &&
    params.has(SCENARIO_SIMULATOR_QUERY_PARAM)
  )
}
