import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { eventApi } from '@/api/eventApi'
import { getPageByUrl } from '@/api/vpApi'
import { Event } from '@/types/event'
import { PaginatedResponse } from '@/types/pagination'
import { objectToQueryString } from '@/utils/url'

export const eventQueryKeys = {
  all: ['events'] as const,
  list: (appId: string, queryParamFilters: Record<string, string>) =>
    [...eventQueryKeys.all, 'list', appId, queryParamFilters] as const,
  details: (eventId: string) =>
    [...eventQueryKeys.all, 'details', eventId] as const,
  eventDownloadUrl: (appId: string, taskId: string) =>
    [...eventQueryKeys.all, 'pdf-url', appId, taskId] as const
}

export const useGetEvents = (
  appId: string,
  sortingAndFilterParams: Record<string, string>
) => {
  const queryString = objectToQueryString({
    limit: 20,
    ...sortingAndFilterParams
  })

  const initialPageParam = `/v1/applications/${appId}/events/?${queryString}`

  return useInfiniteQuery({
    queryKey: eventQueryKeys.list(appId, sortingAndFilterParams),
    queryFn: ({ pageParam }) =>
      getPageByUrl<PaginatedResponse<Event[]>>(pageParam),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam
  })
}

export const useDeleteEvent = () => {
  return useMutation({
    mutationFn: eventApi.deleteEvent
  })
}

export const useAddEventNote = () => {
  return useMutation({
    mutationFn: eventApi.addEventNote
  })
}

export const useDeleteEventNote = () => {
  return useMutation({
    mutationFn: eventApi.deleteEventNote
  })
}

export const useGetEventDetails = (appId: string, eventId: string) => {
  return useQuery({
    queryKey: eventQueryKeys.details(eventId),
    queryFn: () => eventApi.getEventDetails({ appId, eventId })
  })
}

export const useGenerateEventsAs = () => {
  return useMutation({
    mutationFn: eventApi.generateEventsAs
  })
}

export const useFetchDownloadEventUrl = (
  appId: string,
  taskId: string,
  enabled: boolean
) => {
  return useQuery({
    queryKey: eventQueryKeys.eventDownloadUrl(appId, taskId),
    queryFn: () => eventApi.fetchDownloadEventUrl({ appId, taskId }),
    enabled
  })
}
