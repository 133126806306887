import { Routes as BrowserRoutes, Navigate, Route } from 'react-router'
import { ApplicationsPage } from '@/components/applications/ApplicationsPage'
import { DatasetDetailsPage } from '@/components/datasets/DatasetDetailsPage'
import { SelectDatasetPage } from '@/components/datasets/SelectDatasetPage'
import { EditDeploymentPage } from '@/components/deployments/deployment-editor/EditDeploymentPage'
import { DeploymentsPage } from '@/components/deployments/deployment-list/DeploymentsPage'
import { DeviceActivationPage } from '@/components/devices/DeviceActivationPage/DeviceActivationPage'
import { EventsPage } from '@/components/events/EventsPage'
import {
  AppDetailsLayoutWrapper,
  AppDetailsProvider,
  ModelDetailsLayoutWrapper,
  ModelDetailsProvider
} from '@/components/layout/AppDetailsLayout/AppDetailsLayoutWrapper'
import { PageContainer } from '@/components/layout/PageContainer/PageContainer'
import { BaseModelSelectionPage } from '@/components/models/BaseModelSelectionPage'
import { ModelDetailsPage } from '@/components/models/ModelDetails/ModelDetailsPage'
import { ModelStatusGuard } from '@/components/models/ModelDetails/ModelStatusGuard'
import { ModelsPage } from '@/components/models/ModelsPage'
import { MySubscriptionPage } from '@/components/subscriptions/MySubscriptionPage'
import { TrainingDetailsPage } from '@/components/trainings/TrainingDetailsPage'
import { AcceptWorkspaceInvitation } from '@/components/workspaces/AcceptWorkspaceInvitation'
import { CreateWorkspacePage } from '@/components/workspaces/CreateWorkspacePage'
import { InviteMembersPage } from '@/components/workspaces/InviteMembers/InviteMembersPage'
import { WorkspacePage } from '@/components/workspaces/WorkspacePage'
import { CanAccessRoute } from '@/permissions/CanAccessRoute'
import { CrudAction, Resource } from '@/types/permissions'
import { PageLayout } from '../components/layout/MainLayout/MainLayout'
import { LoginPage } from '../components/login/LoginPage'
import { ForgotPasswordPage } from '../components/password-reset/ForgotPasswordPage'
import { SetNewPasswordPage } from '../components/password-reset/SetNewPasswordPage'
import { SignUpPage } from '../components/signup/SignUpPage'
import { CompleteSignUpPage } from '../components/signup/complete-registration/CompleteSignUpPage'
import { PageNotFound } from '../components/ui-shared/PageNotFound/PageNotFound'
import { PrivateRoute } from './PrivateRoute'
import { PublicAuthRoute } from './PublicAuthRoute'
import { SubscriptionChecker } from './SubscriptionChecker'
import { WorkspaceChecker } from './WorkspaceChecker'
import { ApplicationNestedPath, ModelNestedPath, RouterPath } from './paths'

export const Routes = () => {
  return (
    <BrowserRoutes>
      <Route element={<PrivateRoute />}>
        <Route element={<SubscriptionChecker />}>
          <Route element={<WorkspaceChecker />}>
            <Route path={RouterPath.root} element={<PageLayout />}>
              <Route element={<Navigate to={RouterPath.apps} />} index />

              <Route
                path={RouterPath.apps}
                element={
                  <CanAccessRoute
                    allowFor={[[Resource.Applications, [CrudAction.Read]]]}
                  >
                    <ApplicationsPage />
                  </CanAccessRoute>
                }
              />

              <Route
                path={RouterPath.mySubscription}
                element={
                  <CanAccessRoute
                    allowFor={[[Resource.Subscriptions, [CrudAction.Read]]]}
                  >
                    <MySubscriptionPage />
                  </CanAccessRoute>
                }
              />

              <Route
                path={RouterPath.deviceClaim}
                element={<DeviceActivationPage />}
              />

              <Route path={RouterPath.workspace} element={<WorkspacePage />} />
            </Route>

            <Route
              path={RouterPath.appDetails}
              element={<AppDetailsProvider />}
            >
              <Route element={<AppDetailsLayoutWrapper />}>
                <Route
                  element={
                    <Navigate to={ApplicationNestedPath.models} replace />
                  }
                  index
                />

                <Route
                  path={ApplicationNestedPath.models}
                  element={
                    <CanAccessRoute
                      allowFor={[[Resource.MlModels, [CrudAction.Read]]]}
                    >
                      <ModelsPage />
                    </CanAccessRoute>
                  }
                />

                <Route
                  path={ApplicationNestedPath.deployments}
                  element={
                    <CanAccessRoute
                      allowFor={[[Resource.Deployments, [CrudAction.Read]]]}
                    >
                      <DeploymentsPage />
                    </CanAccessRoute>
                  }
                />

                <Route
                  path={ApplicationNestedPath.createDeployment}
                  element={
                    <CanAccessRoute
                      allowFor={[
                        [
                          Resource.Deployments,
                          [CrudAction.Create, CrudAction.Update]
                        ]
                      ]}
                    >
                      <EditDeploymentPage />
                    </CanAccessRoute>
                  }
                />

                <Route
                  path={ApplicationNestedPath.events}
                  element={
                    <CanAccessRoute
                      allowFor={[[Resource.Events, [CrudAction.Read]]]}
                    >
                      <PageContainer fullWidth>
                        <EventsPage />
                      </PageContainer>
                    </CanAccessRoute>
                  }
                />
              </Route>

              <Route
                path={ApplicationNestedPath.modelDetails}
                element={<ModelDetailsProvider />}
              >
                <Route
                  element={
                    <CanAccessRoute
                      allowFor={[[Resource.MlModels, [CrudAction.Read]]]}
                    >
                      <ModelDetailsLayoutWrapper />
                    </CanAccessRoute>
                  }
                >
                  <Route element={<ModelDetailsPage />} index />

                  <Route element={<ModelStatusGuard />}>
                    <Route
                      path={ModelNestedPath.selectBaseModel}
                      element={<BaseModelSelectionPage />}
                    />

                    <Route
                      path={ModelNestedPath.selectDataset}
                      element={
                        <CanAccessRoute
                          allowFor={[[Resource.Datasets, [CrudAction.Read]]]}
                        >
                          <SelectDatasetPage />
                        </CanAccessRoute>
                      }
                    />

                    <Route
                      path={ModelNestedPath.labelData}
                      element={<DatasetDetailsPage />}
                    />
                  </Route>

                  <Route
                    path={ModelNestedPath.training}
                    element={
                      <CanAccessRoute
                        allowFor={[[Resource.Trainings, [CrudAction.Read]]]}
                      >
                        <TrainingDetailsPage />
                      </CanAccessRoute>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route
            path={RouterPath.createWorkspace}
            element={<CreateWorkspacePage />}
          />

          <Route
            path={RouterPath.inviteMembers}
            element={<InviteMembersPage />}
          />

          <Route
            path={RouterPath.workspaceAcceptInvite}
            element={<AcceptWorkspaceInvitation />}
          />
        </Route>
      </Route>

      <Route element={<PublicAuthRoute />}>
        <Route path={RouterPath.login} element={<LoginPage />} />

        <Route path={RouterPath.signup} element={<SignUpPage />} />

        <Route
          path={RouterPath.signupComplete}
          element={<CompleteSignUpPage />}
        />

        <Route
          path={RouterPath.forgotPassword}
          element={<ForgotPasswordPage />}
        />

        <Route
          path={RouterPath.setNewPassword}
          element={<SetNewPasswordPage />}
        />
      </Route>

      <Route path="*" element={<PageNotFound homeLink={RouterPath.root} />} />
    </BrowserRoutes>
  )
}
