import { Outlet } from 'react-router'
import { SubscriptionsLayout } from '@/components/layout/SubscriptionsLayout/SubscriptionsLayout'
import { ChooseSubscriptionPage } from '@/components/subscriptions/choose-plan/ChooseSubscriptionPage'
import { DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP } from '@/config/main'
import { useSession } from '@/session/SessionContext'

export const SubscriptionChecker = () => {
  const { isAuthenticated, hasSubscription } = useSession()

  if (DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP === 'true') {
    console.warn(
      'Subscription selection is disabled, add VITE_DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP=false to local.env file enable it.'
    )
  }

  if (
    isAuthenticated &&
    !hasSubscription &&
    DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP !== 'true'
  ) {
    return (
      <SubscriptionsLayout>
        <ChooseSubscriptionPage />
      </SubscriptionsLayout>
    )
  }

  return <Outlet />
}
