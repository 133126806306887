import { Box } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { accountApi } from '@/api/accountApi'
import { setCsrfToken } from '@/api/vpApi'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { PlatformLoader } from '../ui-shared/PlatformLoader/PlatformLoader'

const QUERY_KEY = 'csrf'

type CsrfHandlerProps = {
  children: ReactNode
}

const TokenError = () => {
  return (
    <Box m="20px auto" maw={640}>
      <PlatformAlert
        variant="error"
        title={<FormattedMessage id="appConfigLoadError.title" />}
        compact
      >
        <FormattedMessage id="appConfigLoadError.description" />
      </PlatformAlert>
    </Box>
  )
}

export const CsrfHandler = ({ children }: CsrfHandlerProps) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: accountApi.getCsrfToken,
    retry: 6 // Retry up to 6 times with exponential backoff: 2s, 4s, 8s, 16s, 30s, 30s (total 90 seconds)
  })

  if (isLoading) {
    return <PlatformLoader />
  }

  if (isError) {
    return <TokenError />
  }

  if (data) {
    setCsrfToken(data)

    // Render children components only after csrf header was added to axios default config
    return children
  }
}
