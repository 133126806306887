import { Card } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { CHATBOT_APIHOST, CHATBOT_CHATFLOWID } from '@/config/main'

type ChatbotProps = {
  maxWidth?: number
}

export const Chatbot = ({ maxWidth = 310 }: ChatbotProps) => {
  const ref = useRef<HTMLElement | null>(null)
  const hasRun = useRef(false) // prevent chat from initializing twice in react strict mode
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (hasRun.current) {
      return
    }

    hasRun.current = true

    const loadFlowiseEmbed = async () => {
      const { default: Chatbot } = await import('flowise-embed/dist/web')

      Chatbot.initFull({
        chatflowid: CHATBOT_CHATFLOWID,
        apiHost: CHATBOT_APIHOST,
        // @ts-expect-error - remove after https://github.com/FlowiseAI/FlowiseChatEmbed/issues/105 is resolved
        theme: {
          button: {
            backgroundColor: '#171b40'
          },
          chatWindow: {
            welcomeMessage:
              'Welcome to VisionPlatform! How can I assist you today?',
            showTitle: true,
            title: 'AI Assistant',
            height: 640,
            fontSize: 13,
            poweredByTextColor: 'transparent',
            textInput: {
              autoFocus: false
            }
          }
        }
      })

      setIsLoaded(true)
    }

    void loadFlowiseEmbed()
  }, [])

  useEffect(() => {
    if (!ref.current || !isLoaded) {
      return
    }

    // Hide the Flowise link
    const style = document.createElement('style')
    style.innerHTML = '.lite-badge { display: none; }'
    ref.current.shadowRoot?.appendChild(style)
  }, [isLoaded])

  return (
    <Card
      maw={maxWidth}
      p={0}
      styles={{
        root: {
          borderColor: '#171b40',
          borderWidth: '1px'
        }
      }}
      withBorder
    >
      <flowise-fullchatbot ref={ref} />
    </Card>
  )
}
