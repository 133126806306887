import { Box, Button, Group, Select, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { WorkspaceMemberRole } from '@/types/workspace'
import { useRoleDropdownOptions } from '../../useRoleDropdownOptions'

type FormValues = {
  role: string
}

export type SubmitValues = {
  role: WorkspaceMemberRole
}

type UpdateMemberFormProps = {
  name: string
  email: string
  role: WorkspaceMemberRole
  onCancel: () => void
  onUpdate: ({ role }: SubmitValues) => void
}

export const UpdateMemberForm = ({
  name,
  email,
  role,
  onCancel,
  onUpdate
}: UpdateMemberFormProps) => {
  const intl = useIntl()

  const workspaceMemberRoleOptions = useRoleDropdownOptions()

  const form = useForm<FormValues>({
    initialValues: {
      role: String(role)
    }
  })

  const handleUpdate = (formValues: FormValues) => {
    onUpdate({
      role: Number(formValues.role)
    })
  }

  return (
    <form onSubmit={form.onSubmit(handleUpdate)}>
      <Stack gap="xl">
        <Box>
          <Text size="sm" mb="sm">
            <FormattedMessage
              id="workspace.members.name"
              values={{
                memberName: <strong>{name || '-'}</strong>
              }}
            />
          </Text>

          <Text size="sm">
            <FormattedMessage
              id="workspace.members.email"
              values={{
                memberEmail: <strong>{email}</strong>
              }}
            />
          </Text>
        </Box>

        <Select
          {...form.getInputProps('role')}
          placeholder={intl.formatMessage({ id: 'workspace.members.role' })}
          data={workspaceMemberRoleOptions}
          label={intl.formatMessage({ id: 'workspace.members.role' })}
          autoFocus
        />

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button type="submit">
            <FormattedMessage id="workspace.members.update" />
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
