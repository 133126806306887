import { Button, Checkbox, Code, Group, Tabs, Text } from '@mantine/core'
import { FormErrors, useForm } from '@mantine/form'
import { IconVideo } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  LabelGroupType,
  LibraryLogic,
  NormalizedBusinessLogicParameter,
  NormalizedStreamBusinessLogic
} from '@/types/businessLogic'
import { CameraStreamWithDeviceId } from '@/types/device'
import { deepClone } from '@/utils/clone'
import { getFieldErrors } from '../helpers/getFieldErrors'
import { StreamLogicConfigurator } from './StreamLogicConfigurator'

type LogicHandlerProps = {
  libraryLogic: LibraryLogic
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: NormalizedStreamBusinessLogic[]
  liveInferenceCameraStreamIds: string[]
  onCancel: () => void
  onSave: (savedLogics: NormalizedStreamBusinessLogic[]) => void
}

export const LogicHandler = ({
  libraryLogic,
  cameraStreams,
  savedStreamLogics,
  liveInferenceCameraStreamIds,
  onCancel,
  onSave
}: LogicHandlerProps) => {
  const intl = useIntl()

  const form = useForm<{ cameraStreams: NormalizedStreamBusinessLogic[] }>({
    initialValues: {
      cameraStreams: cameraStreams.map((cameraStream) => {
        const savedLogics = savedStreamLogics
          .filter(
            (streamLogic) => streamLogic.logic_type === libraryLogic.logic_type
          )
          .find(
            (streamLogic) => streamLogic.camera_id === cameraStream.camera_id
          )

        return {
          logic_id: libraryLogic.id,
          logic_type: libraryLogic.logic_type,
          logic_name: libraryLogic.name,
          camera_id: cameraStream.camera_id,
          camera_name: cameraStream.camera_name,
          camera_image: cameraStream.image,
          device_id: cameraStream.device_id,
          live_inference: liveInferenceCameraStreamIds.includes(
            cameraStream.camera_id
          ),
          parameters: savedLogics ? deepClone(savedLogics.parameters) : []
        }
      })
    },
    validate: {
      cameraStreams: {
        parameters: {
          rules: {
            label_groups: (value) => {
              if (value.length === 0) {
                return intl.formatMessage({
                  id: 'logic.rules.object.required'
                })
              }

              if (value.some((item) => item.label_id === '')) {
                return intl.formatMessage({
                  id: 'logic.rules.object.select.required'
                })
              }

              if (
                value.some(
                  (condition) =>
                    condition.type === LabelGroupType.Group &&
                    condition.sub_label_ids.length === 0
                )
              ) {
                return intl.formatMessage({
                  id: 'logic.rules.object.select.required'
                })
              }

              return null
            }
          }
        }
      }
    }
  })

  const [tabValue, setTabValue] = useState(cameraStreams[0].camera_id || '')

  const handleParameterAdd = (
    streamIndex: number,
    parameter: NormalizedBusinessLogicParameter
  ) => {
    form.insertListItem(`cameraStreams.${streamIndex}.parameters`, parameter)
    form.clearErrors()
  }

  const handleParameterChange = (
    streamIndex: number,
    parameterIndex: number,
    parameter: NormalizedBusinessLogicParameter
  ) => {
    form.setFieldValue(
      `cameraStreams.${streamIndex}.parameters.${parameterIndex}`,
      parameter
    )
    form.clearErrors()
  }

  const handleParameterRemove = (
    streamIndex: number,
    parameterIndex: number
  ) => {
    form.removeListItem(
      `cameraStreams.${streamIndex}.parameters`,
      parameterIndex
    )
    form.clearErrors()
  }

  const handleSubmit = (values: NormalizedStreamBusinessLogic[]) => {
    onSave(values)
  }

  const activateTabWithError = (errors: FormErrors) => {
    const firstError = Object.keys(errors)[0]
    const errorStreamIndex = Number(firstError.split('.')[1])
    const streamWithErrors = form.values.cameraStreams[errorStreamIndex]

    if (streamWithErrors) {
      setTabValue(streamWithErrors.camera_id)
    }
  }

  const handleLiveInferenceChange = (streamIndex: number, value: boolean) => {
    form.setFieldValue(`cameraStreams.${streamIndex}.live_inference`, value)
  }

  return (
    <>
      <form
        noValidate
        onSubmit={form.onSubmit(
          (values) => handleSubmit(values.cameraStreams),
          (errors) => activateTabWithError(errors)
        )}
      >
        <Tabs
          variant="outline"
          value={tabValue}
          onChange={(value) => setTabValue(value || '')}
        >
          <Tabs.List mb="md">
            {form.values.cameraStreams.map((cameraStream) => (
              <Tabs.Tab
                key={cameraStream.camera_id}
                value={cameraStream.camera_id}
              >
                <Group gap="xs" wrap="nowrap">
                  <IconVideo size={16} />

                  <Text size="xs" maw={140} truncate>
                    {cameraStream.camera_name}
                  </Text>
                </Group>
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {form.values.cameraStreams.map((cameraStream, streamIndex) => (
            <Tabs.Panel
              key={cameraStream.camera_id}
              value={cameraStream.camera_id}
            >
              <StreamLogicConfigurator
                active={tabValue === cameraStream.camera_id}
                deviceId={cameraStream.device_id}
                cameraStreamId={cameraStream.camera_id}
                logicType={libraryLogic.logic_type}
                enabledLogicParameters={libraryLogic.parameters.map(
                  (param) => param.parameter_type
                )}
                parameters={form.values.cameraStreams[streamIndex].parameters}
                streamErrors={getFieldErrors(
                  form.errors,
                  'cameraStreams',
                  streamIndex
                )}
                onParameterAdd={(parameter) =>
                  handleParameterAdd(streamIndex, parameter)
                }
                onParameterChange={(parameterIndex, parameter) => {
                  handleParameterChange(streamIndex, parameterIndex, parameter)
                }}
                onParameterRemove={(parameterIndex) => {
                  handleParameterRemove(streamIndex, parameterIndex)
                }}
              />

              <Checkbox
                mt="md"
                checked={form.values.cameraStreams[streamIndex].live_inference}
                label={<FormattedMessage id="logic.liveInference.enable" />}
                description={
                  <FormattedMessage
                    id="logic.liveInference.hint"
                    values={{
                      command: <Code>sudo xhost +</Code>
                    }}
                  />
                }
                onChange={(event) =>
                  handleLiveInferenceChange(
                    streamIndex,
                    event.currentTarget.checked
                  )
                }
              />
            </Tabs.Panel>
          ))}
        </Tabs>

        <Group justify="end" mt="xl">
          <Button miw={160} variant="default" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button miw={160} type="submit">
            <FormattedMessage id="save" />
          </Button>
        </Group>
      </form>
    </>
  )
}
