import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'
import { accountApi } from '@/api/accountApi'
import { getApiError } from '@/api/helpers/apiError'

const UID_PARAM = 'uid'
const TOKEN_PARAM = 'token'

export const useValidateToken = () => {
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [email, setEmail] = useState('')
  const [searchParams] = useSearchParams()
  const uid = searchParams.get(UID_PARAM)
  const token = searchParams.get(TOKEN_PARAM)

  const { mutate, isPending } = useMutation({
    mutationFn: accountApi.validateToken,
    onSuccess: ({ data }) => {
      setIsTokenValid(data.verified)
      setEmail(data.email)
    },
    onError: (err) => {
      const { data } = getApiError(err)

      if (data) {
        setEmail(data.email as string)
      }

      setIsTokenValid(false)
    }
  })

  useEffect(() => {
    if (uid && token) {
      mutate({ uid, token })
    }
  }, [mutate, uid, token])

  return {
    isTokenValid,
    token,
    uid,
    email,
    isPending
  }
}
