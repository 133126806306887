import { Navigate, Outlet } from 'react-router'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { ModelNestedPath, buildModelDetailsLink } from '@/router/paths'
import { MLModelStatus } from '@/types/model'

export const ModelStatusGuard = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  if (!application || !model) {
    return null
  }

  if (model.status === MLModelStatus.Trained || model.training !== null) {
    return (
      <Navigate
        to={buildModelDetailsLink(
          application.id,
          model.id,
          ModelNestedPath.training
        )}
        replace
      />
    )
  }

  return <Outlet />
}
