import { Box, Flex, Stack, Title } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useSession } from '@/session/SessionContext'
import { CrudAction, Resource } from '@/types/permissions'
import { UpdateWorkspaceModal } from './UpdateWorkspaceModal'
import { WorkspaceInfo } from './WorkspaceInfo/WorkspaceInfo'
import { WorkspaceMembers } from './WorkspaceMembers/WorkspaceMembers'

export const WorkspacePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { activeWorkspaceMembership, user } = useSession()

  const [canUpdateWorkspace] = useHasPermissions(Resource.Workspaces, [
    CrudAction.Update
  ])

  const onClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Flex justify="center">
        <Box w="100%" maw={1200}>
          <Title order={2} mb="xl">
            <FormattedMessage id="workspace" />
          </Title>

          {activeWorkspaceMembership && (
            <Stack gap={60}>
              <WorkspaceInfo
                workspace={activeWorkspaceMembership.workspace}
                showUpdateButton={canUpdateWorkspace}
                onUpdateClick={() => setIsModalOpen(true)}
              />

              <WorkspaceMembers
                workspaceId={activeWorkspaceMembership.workspace.id}
                workspaceName={activeWorkspaceMembership.workspace.name}
                userId={user?.id || ''}
              />
            </Stack>
          )}
        </Box>
      </Flex>

      {activeWorkspaceMembership && (
        <UpdateWorkspaceModal
          workspace={activeWorkspaceMembership.workspace}
          isOpen={isModalOpen}
          onClose={() => {
            void onClose()
          }}
        />
      )}
    </>
  )
}
