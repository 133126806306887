import { Box, LoadingOverlay } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { accountApi } from '@/api/accountApi'
import { RouterPath } from '@/router/paths'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { PasswordResetConfirmation } from './PasswordResetConfirmation/PasswordResetConfirmation'
import {
  FormValues,
  PasswordResetForm
} from './PasswordResetForm/PasswordResetForm'

export const ForgotPasswordPage = () => {
  const [showConfirmation, setShowConfirmation] = useState(false)

  const { mutate, isPending, isError } = useMutation({
    mutationFn: accountApi.sendPasswordResetEmail,
    onSuccess: () => {
      setShowConfirmation(true)
    }
  })

  const onTryAgainClick = () => {
    setShowConfirmation(false)
  }

  const onSubmit = (formValues: FormValues) => {
    if (!isPending) {
      mutate(formValues.email)
    }
  }

  return showConfirmation ? (
    <AuthLayout>
      <PasswordResetConfirmation onTryAgainClick={onTryAgainClick} />
    </AuthLayout>
  ) : (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {isError && (
          <PlatformAlert variant="error" mb="md" compact>
            <FormattedMessage id="passwordReset.sendEmailError" />
          </PlatformAlert>
        )}

        <PasswordResetForm loginLink={RouterPath.login} onSubmit={onSubmit} />
      </Box>
    </AuthLayout>
  )
}
