import {
  Box,
  Group,
  Loader,
  LoadingOverlay,
  Stepper,
  Text,
  rem
} from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import { IconCheck } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { getApiError } from '@/api/helpers/apiError'
import { useApplicationContext } from '@/providers/ApplicationContext'
import {
  useCreateDeployment,
  useUpdateDeployment
} from '@/queries/deploymentQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import { Application, ApplicationType } from '@/types/app'
import { NormalizedStreamBusinessLogic } from '@/types/businessLogic'
import {
  BlurOptions,
  ConfidenceThreshold,
  EventDestination
} from '@/types/deployment'
import { CameraStreamWithDeviceId } from '@/types/device'
import { MLModel, MLModelType } from '@/types/model'
import { NotificationSettings } from '@/types/notificationSettings'
import { getBrowserTimezone } from '@/utils/date'
import { CameraStreamSelection } from './CameraStreamSelection/CameraStreamSelection'
import { DeployPreview } from './DeployPreview/DeployPreview'
import { DeploymentStepperProvider } from './DeploymentStepperContext'
import { DeviceSelection } from './DeviceSelection/DeviceSelection'
import {
  IntegrationSelection,
  IntegrationSubmitValues,
  SavedEventEndpoint
} from './IntegrationSelection/IntegrationSelection'
import {
  DEFAULT_EVENT_VIDEO_DURATION_TIME,
  FRAME_HEIGHT,
  FRAME_WIDTH
} from './LogicSelection/LogicHandler/constants'
import { LogicSelection } from './LogicSelection/LogicSelection'
import { ModelSelection } from './ModelSelection/ModelSelection'
import { buildDeploymentData } from './helpers/buildDeploymentData'
import { extractConfidenceThresholds } from './helpers/extractConfidenceThresholds'

type DeploymentHandlerState = {
  selectedPrimaryModel: MLModel | null
  selectedSecondaryModel: MLModel | null
  selectedDeviceIds: string[]
  selectedCameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: NormalizedStreamBusinessLogic[]
  savedEventEndpoints: SavedEventEndpoint[]
  liveInferenceCameraStreamIds: string[]
  confidenceThresholds: ConfidenceThreshold[]
  blurOptions: BlurOptions
  eventVideoDurationTime: number
  notificationSettings: NotificationSettings
}

type DeploymentHandlerProps = {
  mode: 'create' | 'edit'
  initialStep?: number
  initialPrimaryModel?: MLModel
  initialSecondaryModel?: MLModel
  initialDeviceIds?: string[]
  initialCameraStreams?: CameraStreamWithDeviceId[]
  initialStreamLogics?: NormalizedStreamBusinessLogic[]
  initialEventEndpoints?: SavedEventEndpoint[]
  initialInferenceStreamIds?: string[]
  initialConfidenceThresholds?: ConfidenceThreshold[]
  initialBlurOptions?: BlurOptions
  initialEventVideoDurationTime?: number
  initialNotificationSettings?: NotificationSettings
}

export const DeploymentHandler = ({
  mode,
  initialStep,
  initialPrimaryModel,
  initialSecondaryModel,
  initialDeviceIds,
  initialCameraStreams,
  initialStreamLogics,
  initialEventEndpoints,
  initialInferenceStreamIds,
  initialConfidenceThresholds,
  initialBlurOptions,
  initialEventVideoDurationTime,
  initialNotificationSettings
}: DeploymentHandlerProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { application } = useApplicationContext()
  const { application_type = ApplicationType.SingleStage } =
    application as Application // TODO: remove test value after API integration

  const [step, setStep] = useState(initialStep ?? 0)

  const [state, setState] = useSetState<DeploymentHandlerState>({
    selectedPrimaryModel: initialPrimaryModel || null,
    selectedSecondaryModel: initialSecondaryModel || null,
    selectedDeviceIds: initialDeviceIds || [],
    selectedCameraStreams: initialCameraStreams || [],
    savedStreamLogics: initialStreamLogics || [],
    savedEventEndpoints: initialEventEndpoints || [
      {
        service: EventDestination.Visionplatform,
        endpoint: ''
      }
    ],
    liveInferenceCameraStreamIds: initialInferenceStreamIds || [],
    confidenceThresholds: initialConfidenceThresholds || [],
    blurOptions: initialBlurOptions || {
      blur_face: false,
      blur_lp: false,
      blur_label_ids: []
    },
    eventVideoDurationTime:
      initialEventVideoDurationTime || DEFAULT_EVENT_VIDEO_DURATION_TIME,
    notificationSettings: initialNotificationSettings
      ? {
          ...initialNotificationSettings,
          timezone: initialNotificationSettings.is_default
            ? getBrowserTimezone()
            : initialNotificationSettings.timezone
        }
      : {
          is_default: true,
          workspace_users: [],
          schedules: [],
          timezone: getBrowserTimezone()
        }
  })

  const { mutateAsync: createDeployment, isPending: isCreatePending } =
    useCreateDeployment()
  const { mutateAsync: updateDeployment, isPending: isUpdatePending } =
    useUpdateDeployment()

  const isPending = isCreatePending || isUpdatePending

  const goNextStep = () => {
    setStep((prev) => prev + 1)
  }

  const goPrevStep = () => {
    setStep((prev) => prev - 1)
  }

  const handlePrimaryModelSelection = (model: MLModel | null) => {
    setState({ selectedPrimaryModel: model })
  }

  const handleSecondaryModelSelection = (model: MLModel | null) => {
    setState({ selectedSecondaryModel: model })
  }

  const handleDeviceSelectionChange = (deviceIds: string[]) => {
    setState({ selectedDeviceIds: deviceIds })
  }

  const handleCameraStreamSelectionChange = (
    cameraStreams: CameraStreamWithDeviceId[]
  ) => {
    const filteredSavedStreamLogics = state.savedStreamLogics.filter(
      (savedLogic) =>
        cameraStreams.some(
          (stream) => stream.camera_id === savedLogic.camera_id
        )
    )

    setState({
      selectedCameraStreams: cameraStreams,
      savedStreamLogics: filteredSavedStreamLogics
    })
  }

  const handleStreamLogicsSave = (logics: NormalizedStreamBusinessLogic[]) => {
    setState({ savedStreamLogics: logics })
    setState({
      confidenceThresholds: extractConfidenceThresholds(
        state.confidenceThresholds,
        logics
      )
    })
  }

  const handleIntegrationsSave = ({
    integrations,
    confidenceThresholds,
    blurOptions,
    eventVideoDurationTime,
    notificationSettings
  }: IntegrationSubmitValues) => {
    setState({
      savedEventEndpoints: integrations,
      confidenceThresholds,
      blurOptions,
      eventVideoDurationTime,
      notificationSettings
    })

    goNextStep()
  }

  const handleLiveInferenceChange = (cameraStreamIds: string[]) => {
    setState({
      liveInferenceCameraStreamIds: cameraStreamIds
    })
  }

  const handleDeployModel = async () => {
    try {
      const deploymentData = buildDeploymentData({
        appId: application?.id || '',
        primaryModelId: state.selectedPrimaryModel?.id || '',
        secondaryModelId: state.selectedSecondaryModel?.id,
        width: FRAME_WIDTH,
        height: FRAME_HEIGHT,
        cameraStreams: state.selectedCameraStreams,
        savedStreamLogics: state.savedStreamLogics.filter(
          (logic) => logic.parameters.length > 0
        ),
        savedEventEndpoints: state.savedEventEndpoints,
        liveInferenceCameraStreamIds: state.liveInferenceCameraStreamIds,
        confidenceThresholds: state.confidenceThresholds,
        blurOptions: state.blurOptions,
        eventVideoDurationTime: state.eventVideoDurationTime,
        notificationSettings: state.notificationSettings
      })

      await (mode === 'create'
        ? createDeployment(deploymentData)
        : updateDeployment(deploymentData))

      void navigate(
        buildAppLink(application?.id || '', ApplicationNestedPath.deployments)
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'deployments.error' })
      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={isPending}
        loaderProps={{
          children: (
            <Group>
              <Loader size={32} />

              <Text fw="bold">
                <FormattedMessage id="deployments.inProgress" />
              </Text>
            </Group>
          )
        }}
      />

      <DeploymentStepperProvider
        appType={application_type}
        primaryModel={state.selectedPrimaryModel}
        secondaryModel={state.selectedSecondaryModel}
      >
        <Stepper
          active={step}
          size="sm"
          iconSize={32}
          completedIcon={
            <IconCheck style={{ width: rem(18), height: rem(18) }} stroke={3} />
          }
          color="green"
          styles={{
            steps: {
              marginBottom: rem(20)
            }
          }}
        >
          <Stepper.Step
            label={
              application_type === ApplicationType.MultiStage ? (
                <FormattedMessage id="deployments.step1a" />
              ) : (
                <FormattedMessage id="deployments.step1" />
              )
            }
          >
            <ModelSelection
              title={
                application_type === ApplicationType.MultiStage ? (
                  <FormattedMessage id="deployments.step1a" />
                ) : (
                  <FormattedMessage id="deployments.step1" />
                )
              }
              applicationId={application?.id || ''}
              selectedModel={state.selectedPrimaryModel}
              modelType={MLModelType.Primary}
              onSelectionChange={handlePrimaryModelSelection}
              onContinue={goNextStep}
            />
          </Stepper.Step>

          {application_type === ApplicationType.MultiStage && (
            <Stepper.Step label={<FormattedMessage id="deployments.step2" />}>
              <ModelSelection
                title={<FormattedMessage id="deployments.step2" />}
                applicationId={application?.id || ''}
                selectedModel={state.selectedSecondaryModel}
                modelType={MLModelType.Secondary}
                showBackButton
                onSelectionChange={handleSecondaryModelSelection}
                onGoBack={goPrevStep}
                onContinue={goNextStep}
              />
            </Stepper.Step>
          )}

          <Stepper.Step label={<FormattedMessage id="deployments.step3" />}>
            <DeviceSelection
              selectedDeviceIds={state.selectedDeviceIds}
              onSelectionChange={handleDeviceSelectionChange}
              onContinue={goNextStep}
              onGoBack={goPrevStep}
            />
          </Stepper.Step>

          <Stepper.Step label={<FormattedMessage id="deployments.step4" />}>
            <CameraStreamSelection
              deviceIds={state.selectedDeviceIds}
              selectedCameraStreams={state.selectedCameraStreams}
              onSelectionChange={handleCameraStreamSelectionChange}
              onContinue={goNextStep}
              onGoBack={goPrevStep}
            />
          </Stepper.Step>

          <Stepper.Step label={<FormattedMessage id="deployments.step5" />}>
            <LogicSelection
              cameraStreams={state.selectedCameraStreams}
              savedStreamLogics={state.savedStreamLogics}
              liveInferenceCameraStreamIds={state.liveInferenceCameraStreamIds}
              onLiveInferenceChange={handleLiveInferenceChange}
              onContinue={goNextStep}
              onGoBack={goPrevStep}
              onStreamLogicsSave={handleStreamLogicsSave}
            />
          </Stepper.Step>

          <Stepper.Step label={<FormattedMessage id="deployments.step6" />}>
            <IntegrationSelection
              selectedDeviceIds={state.selectedDeviceIds}
              savedEventEndpoints={state.savedEventEndpoints}
              confidenceThresholds={state.confidenceThresholds}
              blurOptions={state.blurOptions}
              eventVideoDurationTime={state.eventVideoDurationTime}
              showNotificationSettings={state.savedStreamLogics
                .flatMap((stream) => stream.parameters)
                .some((param) => param.notification)}
              notificationSettings={state.notificationSettings}
              onGoBack={goPrevStep}
              onContinue={handleIntegrationsSave}
            />
          </Stepper.Step>

          <Stepper.Step label={<FormattedMessage id="deployments.step7" />}>
            <DeployPreview
              primaryModel={state.selectedPrimaryModel}
              secondaryModel={state.selectedSecondaryModel}
              cameraStreams={state.selectedCameraStreams}
              savedStreamLogics={state.savedStreamLogics}
              savedEventEndpoints={state.savedEventEndpoints}
              onGoBack={goPrevStep}
              onDeployModel={() => void handleDeployModel()}
            />
          </Stepper.Step>
        </Stepper>
      </DeploymentStepperProvider>
    </Box>
  )
}
