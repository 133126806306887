import {
  ActionIcon,
  Anchor,
  Burger,
  Flex,
  Group,
  Text,
  useMantineColorScheme
} from '@mantine/core'
import { IconMoon, IconSun } from '@tabler/icons-react'
import { Link } from 'react-router'
import { NotificationDropdown } from '@/components/Notifications/NotificationDropdown'
import { VpLogo } from '@/components/ui-shared/VpLogo/VpLogo'
import { useDeviceDetect } from '@/hooks/useDeviceDetect'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { PlatformManuals } from './PlatformManuals'
import { UserMenu } from './UserMenu'

export const HEADER_HEIGHT = 60

const LOGO_WIDTH_MOBILE = 130
const LOGO_WIDTH_DESKTOP = 220

type LogoProps = {
  isMobile: boolean
  isDarkMode: boolean
}

const Logo = ({ isMobile, isDarkMode }: LogoProps) => {
  return (
    <VpLogo
      width={isMobile ? LOGO_WIDTH_MOBILE : LOGO_WIDTH_DESKTOP}
      variant={isDarkMode ? 'light' : 'dark'}
    />
  )
}

type DarkModeToggleProps = {
  isDarkMode: boolean
  onClick: () => void
}

const DarkModeToggle = ({ isDarkMode, onClick }: DarkModeToggleProps) => {
  return (
    <ActionIcon size="sm" onClick={onClick}>
      {isDarkMode ? <IconSun /> : <IconMoon />}
    </ActionIcon>
  )
}

type HeaderProps = {
  isMenuOpen: boolean
  onBurgerMenuClick: () => void
}

export const Header = ({ isMenuOpen, onBurgerMenuClick }: HeaderProps) => {
  const { activeWorkspaceMembership } = useSession()
  const { isMobile } = useDeviceDetect()
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const isDarkMode = colorScheme === 'dark'

  return (
    <Flex align="center" justify="space-between" h="100%">
      <Group gap="sm" wrap="nowrap">
        {isMobile && (
          <Burger
            color="white"
            size="sm"
            opened={isMenuOpen}
            onClick={onBurgerMenuClick}
          />
        )}

        <Anchor component={Link} to={RouterPath.root}>
          <Logo isMobile={isMobile} isDarkMode />
        </Anchor>

        {activeWorkspaceMembership && !isMobile && (
          <Text w={320} size="xs" c="white" fw={600} truncate>
            ({activeWorkspaceMembership.workspace.name})
          </Text>
        )}
      </Group>

      <Group gap="md" wrap="nowrap">
        <PlatformManuals />

        <NotificationDropdown />

        <DarkModeToggle isDarkMode={isDarkMode} onClick={toggleColorScheme} />

        <UserMenu />
      </Group>
    </Flex>
  )
}
