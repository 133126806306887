import { Navigate, Outlet, useLocation } from 'react-router'
import { useSession } from '../session/SessionContext'
import { RouterPath } from './paths'

export const PrivateRoute = () => {
  const { isAuthenticated, logoutReason } = useSession()
  const location = useLocation()

  // Remember the user's intended URL for redirecting them there after they log in
  const currentPath = location.pathname + location.search

  if (!isAuthenticated) {
    return (
      <Navigate
        to={RouterPath.login}
        state={{
          redirectTo:
            logoutReason === 'user_logout' ? RouterPath.root : currentPath
        }}
        replace
      />
    )
  }

  return <Outlet />
}
