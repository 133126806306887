import { Box, Button, Group, Text, ThemeIcon } from '@mantine/core'
import { IconBell } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type NotificationSettingsInfoProps = {
  mainUserName?: string
  otherUserCount: number
  hasCustomSchedule?: boolean
  onEdit: () => void
}
export const NotificationSettingsInfo = ({
  mainUserName,
  otherUserCount,
  hasCustomSchedule,
  onEdit
}: NotificationSettingsInfoProps) => {
  return (
    <Group wrap="nowrap" align="start" gap="xs">
      <ThemeIcon size="sm" variant="transparent">
        <IconBell style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>

      <Box mt={2}>
        <Text size="xs" fw={600}>
          {mainUserName && otherUserCount === 0 && (
            <FormattedMessage
              id="deployments.notificationSettings.subscribers.named"
              values={{ user: mainUserName }}
            />
          )}

          {mainUserName && otherUserCount > 0 && (
            <FormattedMessage
              id="deployments.notificationSettings.subscribers.combined"
              values={{ user: mainUserName, count: otherUserCount }}
            />
          )}

          {!mainUserName && (
            <FormattedMessage
              id="deployments.notificationSettings.subscribers.other"
              values={{ count: otherUserCount }}
            />
          )}
        </Text>

        {hasCustomSchedule && (
          <Text size="xs">
            {' '}
            (
            <FormattedMessage id="deployments.notificationSettings.customSchedule" />
            )
          </Text>
        )}

        <Button size="compact-xs" mt="md" onClick={onEdit}>
          <FormattedMessage id="deployments.notificationSettings.editLabel" />
        </Button>
      </Box>
    </Group>
  )
}
