import { List, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { getDayOfWeekName } from '@/utils/date'

export const ScheduleInfoBox = () => {
  return (
    <PlatformAlert variant="info" compact>
      <Stack gap="sm">
        <Text size="xs">
          <FormattedMessage id="deployments.notificationSettings.schedule.add.description1" />
        </Text>

        <Text size="xs">
          <FormattedMessage
            id="deployments.notificationSettings.schedule.add.description2"
            values={{
              dayRange: `${getDayOfWeekName(1)} - ${getDayOfWeekName(5)}`
            }}
          />
        </Text>

        <List size="xs">
          {[
            ['17:00', '23:59', 1, 5],
            ['00:00', '09:00', 1, 5],
            ['00:00', '23:59', 6, 7]
          ].map(([startTime, endTime, startDay, endDay], index) => (
            <List.Item key={index}>
              <Text size="xs" fw={600}>
                {`${startTime} - ${endTime} ${getDayOfWeekName(startDay as number, 'short')} - ${getDayOfWeekName(endDay as number, 'short')}`}
              </Text>
            </List.Item>
          ))}
        </List>
      </Stack>
    </PlatformAlert>
  )
}
