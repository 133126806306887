import { Box, LoadingOverlay, Title } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { accountApi } from '@/api/accountApi'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { RouterPath } from '@/router/paths'
import { useLogin } from '@/session/useLogin'
import { showToast } from '@/theme/notifications'
import { AuthLayout } from '../../layout/AuthLayout/AuthLayout'
import { SignUpForm, SubmitValues } from './SignUpForm/SignUpForm'

const messages = {
  accountCreated: {
    id: 'completeRegistration.accountCreated'
  }
}

type SignUpErrorProps = {
  error: ApiError
}

const SignUpError = ({ error }: SignUpErrorProps) => {
  const { errorMessage } = error

  return (
    <PlatformAlert variant="error" mb="lg" compact>
      {errorMessage || (
        <FormattedMessage id="completeRegistration.genericError" />
      )}
    </PlatformAlert>
  )
}

type SignUpFormHandlerProps = {
  email: string
  token: string
}

export const SignUpFormHandler = ({ email, token }: SignUpFormHandlerProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { login } = useLogin()

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: accountApi.completeRegistration,
    onSuccess: async (_, variables) => {
      try {
        const { password } = variables
        await login({ email, password })
      } catch {
        void navigate(RouterPath.login)
      }

      showToast(intl.formatMessage(messages.accountCreated), 'success')
    }
  })

  const handleSubmit = (formValues: SubmitValues) => {
    const { firstName, lastName, password } = formValues

    if (!isPending) {
      mutate({
        first_name: firstName,
        last_name: lastName,
        password,
        email,
        token
      })
    }
  }

  return (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Title order={2} mb="xl">
          <FormattedMessage id="completeRegistration" />
        </Title>

        {isError && <SignUpError error={getApiError(error)} />}

        <SignUpForm initialEmail={email} onSubmit={handleSubmit} />
      </Box>
    </AuthLayout>
  )
}
