import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useUpdateWorkspace } from '@/queries/workspaceQueries'
import { useSession } from '@/session/SessionContext'
import { showToast } from '@/theme/notifications'
import { Workspace } from '@/types/workspace'
import { SubmitValues, WorkspaceForm } from './WorkspaceForm/WorkspaceForm'

type UpdateWorkspaceModalProps = {
  isOpen: boolean
  workspace: Workspace
  onClose: () => void
}

export const UpdateWorkspaceModal = ({
  isOpen,
  workspace,
  onClose
}: UpdateWorkspaceModalProps) => {
  const intl = useIntl()

  const { mutateAsync: updateWorkspace, isPending } = useUpdateWorkspace()
  const { refreshSession } = useSession()

  const handleSubmit = async (formValues: SubmitValues) => {
    try {
      await updateWorkspace({
        workspaceId: workspace.id,
        payload: formValues
      })

      showToast(
        intl.formatMessage({ id: 'workspace.update.success' }),
        'success'
      )

      onClose()
      await refreshSession()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'workspace.update.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="lg"
      opened={isOpen}
      title={<FormattedMessage id="workspace.update" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <WorkspaceForm
          submitLabel={intl.formatMessage({ id: 'workspace.update' })}
          workspace={workspace}
          onSubmit={(formValues) => void handleSubmit(formValues)}
        />
      </Box>
    </Modal>
  )
}
