import {
  Button,
  Input,
  SegmentedControl,
  Stack,
  TextInput
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useIntl } from 'react-intl'
import { Workspace, WorkspaceType } from '@/types/workspace'

const alphanumericRegex = /^[a-zA-Z0-9\s]*$/

type FormValues = {
  workspace_type: string
  name: string
}

export type SubmitValues = {
  workspace_type: WorkspaceType
  name: string
}

type WorkspaceFormParam = {
  workspace?: Workspace
  submitLabel: string
  onSubmit: (formValues: SubmitValues) => void
}

export const WorkspaceForm = ({
  workspace,
  submitLabel,
  onSubmit
}: WorkspaceFormParam) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      workspace_type: String(
        workspace?.workspace_type || WorkspaceType.Personal
      ),
      name: workspace?.name || ''
    },

    validate: {
      name: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({ id: 'workspace.create.nameRequired' })
        }

        if (!alphanumericRegex.test(value)) {
          return intl.formatMessage({ id: 'workspace.create.invalidName' })
        }
      }
    }
  })

  const handleSubmit = (formValues: FormValues) => {
    onSubmit({
      workspace_type: Number(formValues.workspace_type),
      name: formValues.name
    })
  }

  return (
    <form noValidate onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="lg">
        <TextInput
          label={intl.formatMessage({ id: 'workspace.create.name' })}
          {...form.getInputProps('name')}
        />

        <Input.Wrapper
          label={intl.formatMessage({ id: 'workspace.create.type' })}
        >
          <SegmentedControl
            {...form.getInputProps('workspace_type')}
            data={[
              {
                label: intl.formatMessage({ id: 'workspace.type.personal' }),
                value: String(WorkspaceType.Personal)
              },
              {
                label: intl.formatMessage({
                  id: 'workspace.type.organization'
                }),
                value: String(WorkspaceType.Organization)
              }
            ]}
            fullWidth
          />
        </Input.Wrapper>

        <Button type="submit" mt="sm" fullWidth>
          {submitLabel}
        </Button>
      </Stack>
    </form>
  )
}
