import { ReactNode, useEffect } from 'react'
import { useSearchParams } from 'react-router'
import { useTriggerServerException } from '@/queries/generalQueries'

const TRIGGER_FE_ERROR_PARAM = 'trigger-fe-error'
const TRIGGER_BE_ERROR_PARAM = 'trigger-be-error'

type ErrorTesterProps = {
  children: ReactNode
}

export const ErrorTester = ({ children }: ErrorTesterProps) => {
  const [searchParams] = useSearchParams()
  const { mutateAsync: triggerServerException } = useTriggerServerException()

  const feErrorParam = searchParams.get(TRIGGER_FE_ERROR_PARAM)
  const beErrorParam = searchParams.get(TRIGGER_BE_ERROR_PARAM)

  useEffect(() => {
    if (feErrorParam === '1') {
      throw new Error('test error')
    }

    if (beErrorParam === '1') {
      void triggerServerException()
    }
  }, [feErrorParam, beErrorParam, triggerServerException])

  return <>{children}</>
}
