import { Modal } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type ScheduleModalProps = {
  opened: boolean
  variant: 'add' | 'update'
  children: ReactNode
  onClose: () => void
}

export const ScheduleModal = ({
  opened,
  onClose,
  variant,
  children
}: ScheduleModalProps) => {
  return (
    <Modal
      opened={opened}
      size="lg"
      title={
        <FormattedMessage
          id={
            variant === 'update'
              ? 'deployments.notificationSettings.schedule.update'
              : 'deployments.notificationSettings.schedule.add'
          }
        />
      }
      onClose={onClose}
    >
      {children}
    </Modal>
  )
}
