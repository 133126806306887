import { Box, LoadingOverlay, Title } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { getApiError } from '@/api/helpers/apiError'
import { AuthLayout } from '@/components/layout/AuthLayout/AuthLayout'
import { useInviteMembers } from '@/queries/workspaceQueries'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { showToast } from '@/theme/notifications'
import { InviteMembersForm, SubmitValues } from './InviteMembersForm'

export const InviteMembersPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { activeWorkspaceMembership } = useSession()
  const { mutateAsync: inviteMembers, isPending } = useInviteMembers()

  const handleInviteMembers = async ({ members }: SubmitValues) => {
    if (!activeWorkspaceMembership?.workspace.id) {
      return
    }

    try {
      await inviteMembers({
        workspaceId: activeWorkspaceMembership.workspace.id,
        members
      })

      showToast(
        intl.formatMessage({ id: 'workspace.members.invite.success' }),
        'success'
      )

      void navigate(RouterPath.root, { replace: true })
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'workspace.members.invite.error' })

      showToast(message, 'error')
    }
  }

  return (
    <AuthLayout variant="large">
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Title order={2} mb="xl">
          <FormattedMessage id="workspace.members.invite" />
        </Title>

        <InviteMembersForm
          showSkipButton
          onSkip={() => void navigate(RouterPath.root)}
          onSubmit={(values) => void handleInviteMembers(values)}
        />
      </Box>
    </AuthLayout>
  )
}
