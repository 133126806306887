import { accountApi } from '@/api/accountApi'
import { normalizeApiPermissions } from '@/permissions/normalizeApiPermissions'
import { PermissionsMap } from '@/types/permissions'
import { UserSubscription } from '@/types/subscription'
import { User } from '@/types/user'
import { WorkspaceMember } from '@/types/workspace'

export type UserSession = {
  user: User
  subscriptions: UserSubscription[]
  workspaceMemberships: WorkspaceMember[]
  permissions: PermissionsMap
}

export const getUserSession = async (): Promise<UserSession> => {
  const { user } = await accountApi.whoAmI()

  const [subscriptions, workspaceMemberships] = await Promise.all([
    accountApi.getMySubscriptions(),
    accountApi.getMyWorkspaceMemberships()
  ])

  return {
    user,
    subscriptions,
    workspaceMemberships,
    permissions: normalizeApiPermissions(user.workspace_permissions)
  }
}
