import { BusinessLogic } from './businessLogic'
import { MilestoneCameraAttributes } from './device'
import { NotificationSettings } from './notificationSettings'

export type DeploymentConfig = {
  application_id: string
  primary_model_id: string
  secondary_model_id?: string
  config_width: number
  config_height: number
  confidence_threshold: ConfidenceThreshold[]
  blur_options: BlurOptions
  event_video_duration_time: number
  streams: StreamConfig[]
  notification_settings: NotificationSettings
}

type StreamConfig = {
  stream_id: string
  device_id: string
  frame_width: number
  frame_height: number
  eglsink: boolean
  business_logics: BusinessLogic[]
  event_endpoints: EventEndpoint[]
}

export type EventEndpoint = {
  service: EventDestination
  endpoint?: string
  attributes?: MilestoneCameraAttributes
}

export enum EventDestination {
  Visionplatform = 'visionplatform',
  CustomApi = 'custom_api',
  Milestone = 'milestone'
}

export type Deployment = {
  id: string
  application: string
  status: DeploymentStatus
  status_display: string
  error_body: string
  notification_settings: NotificationSettings
  settings_payload: StreamConfig & {
    confidence_threshold: ConfidenceThreshold[]
    blur_options: BlurOptions
    event_video_duration_time: number
  }
  primary_ml_model: {
    id: string
    name: string
  }
  secondary_ml_model: {
    id: string
    name: string
  } | null
  created_at: string
}

export enum DeploymentStatus {
  InProgress = 1,
  Inactive = 2,
  Running = 3,
  Failed = 4,
  Removing = 5,
  Stopping = 6,
  Stopped = 7
}

export type ConfidenceThreshold = {
  label_id: string
  threshold: number
}

export type BlurOptions = {
  blur_face: boolean
  blur_lp: boolean
  blur_label_ids: string[]
}
