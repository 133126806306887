import { Box, LoadingOverlay, Title } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { getApiError } from '@/api/helpers/apiError'
import { useCreateWorkspace } from '@/queries/workspaceQueries'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { showToast } from '@/theme/notifications'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import { SubmitValues, WorkspaceForm } from './WorkspaceForm/WorkspaceForm'

export const CreateWorkspacePage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { refreshSession, isRefreshing } = useSession()
  const { mutateAsync: createWorkspace, isPending } = useCreateWorkspace()

  const handleSubmit = async (formValues: SubmitValues) => {
    try {
      await createWorkspace(formValues)

      showToast(
        intl.formatMessage({ id: 'workspace.create.success' }),
        'success'
      )

      await refreshSession()
      void navigate(RouterPath.inviteMembers, { replace: true })
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'workspace.create.error' })

      showToast(message, 'error')
    }
  }

  return (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending || isRefreshing} />

        <Title order={2} mb="xl">
          <FormattedMessage id="workspace.create" />
        </Title>

        <WorkspaceForm
          submitLabel={intl.formatMessage({ id: 'workspace.create' })}
          onSubmit={(formValues) => void handleSubmit(formValues)}
        />
      </Box>
    </AuthLayout>
  )
}
