import {
  Dataset,
  DatasetLabelingType,
  DatasetType,
  DatasetVersion,
  UploadStatus
} from '@/types/dataset'
import { Label } from '@/types/label'
import { FileUploadNotificationContext } from '@/types/notifications'
import { vpApi } from './vpApi'

const getDatasetDetails = async (datasetId: string) => {
  const res = await vpApi.get<Dataset>(`/v1/datasets/${datasetId}/`)
  return res.data
}

type CreateDatasetData = {
  name: string
  description: string
  labels: Pick<Label, 'name' | 'color'>[]
  labeling_type: DatasetLabelingType
  dataset_type: DatasetType
  is_library: boolean
}

const createDataset = async (data: CreateDatasetData) => {
  const res = await vpApi.post<Dataset>('/v1/datasets/', data)
  return res.data
}

type CreateDatasetVersionParams = {
  datasetId: string
  parentVersionId: string
}

const createDatasetVersion = async ({
  datasetId,
  parentVersionId
}: CreateDatasetVersionParams) => {
  const res = await vpApi.post<DatasetVersion>(
    `/v1/datasets/${datasetId}/versions/new-version/`,
    {
      parent_version_id: parentVersionId
    }
  )
  return res.data
}

type UpdateDatasetParams = {
  datasetId: string
  data: Pick<
    Partial<CreateDatasetData>,
    'name' | 'description' | 'labeling_type' | 'labels'
  >
}

const updateDataset = ({ datasetId, data }: UpdateDatasetParams) => {
  return vpApi.patch(`/v1/datasets/${datasetId}/`, data)
}

const deleteDataset = (datasetId: string) => {
  return vpApi.delete(`/v1/datasets/${datasetId}/`)
}

const deleteDatasetVersion = (versionId: string) => {
  return vpApi.delete(`/v1/datasets/versions/${versionId}/`)
}

type UploadFilesParams = {
  versionId: string
  media_files: File[]
  notification_context: FileUploadNotificationContext
}

type UploadFilesResponse = {
  batch_id: string
}

const uploadFilesToDataset = ({
  versionId,
  media_files,
  notification_context
}: UploadFilesParams) => {
  const formData = new FormData()

  media_files.forEach((file) => formData.append('media_files', file))

  Object.entries(notification_context).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return vpApi.put<UploadFilesResponse>(
    `/v1/datasets/versions/${versionId}/add-media/`,
    formData
  )
}

const getFileUploadStatus = async (batchId: string) => {
  const res = await vpApi.get<UploadStatus>(
    `/v1/datasets/${batchId}/upload-status/`
  )
  return res.data
}

type DeleteFilesParams = {
  versionId: string
  fileIds: string[]
}

const deleteFiles = ({ versionId, fileIds }: DeleteFilesParams) => {
  return vpApi.delete(`/v1/datasets/versions/${versionId}/delete-media/`, {
    data: {
      media_ids: fileIds
    }
  })
}

type CropDatasetParams = {
  sourceVersionId: string
  data: {
    dataset_version_id: string
    label_ids: string[]
  }
}

type CropDataseResponse = {
  task_id: string
}

const cropDataset = async ({ sourceVersionId, data }: CropDatasetParams) => {
  const res = await vpApi.post<CropDataseResponse>(
    `/v1/datasets/versions/${sourceVersionId}/crop/`,
    data
  )
  return res.data
}

type AnnotationCount = {
  videos: number
  images: number
}

const getAnnotationCount = async (versionId: string) => {
  const res = await vpApi.get<AnnotationCount>(
    `/v1/datasets/versions/${versionId}/annotation-count/`
  )
  return res.data
}

export const datasetApi = {
  getDatasetDetails,
  createDataset,
  createDatasetVersion,
  updateDataset,
  deleteDataset,
  deleteDatasetVersion,
  uploadFilesToDataset,
  getFileUploadStatus,
  deleteFiles,
  cropDataset,
  getAnnotationCount
}
