import { Button, Card, Code, List, ListItem, Text } from '@mantine/core'
import { IconRefresh } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { PLATFORM_NAME } from '@/config/main'

type DeviceRegistrationStepsProps = {
  onRefresh: () => void
}

export const DeviceRegistrationSteps = ({
  onRefresh
}: DeviceRegistrationStepsProps) => {
  return (
    <>
      <Text size="sm" mb="lg">
        <FormattedMessage
          id="devices.registerSteps.followTheSteps"
          values={{
            appName: PLATFORM_NAME
          }}
        />
      </Text>

      <Card bg="gray.1" mb="md">
        <Text size="xs" fw="bold" mb="sm">
          <FormattedMessage id="devices.registerSteps.step1Title" />
        </Text>

        <List size="xs" spacing="xs" withPadding>
          <ListItem>
            <FormattedMessage id="devices.registerSteps.step1Instruction1" />
          </ListItem>

          <ListItem>
            <FormattedMessage id="devices.registerSteps.step1Instruction2" />
          </ListItem>
        </List>
      </Card>

      <Card bg="gray.1" mb="md">
        <Text size="xs" fw="bold" mb="sm">
          <FormattedMessage
            id="devices.registerSteps.step2Title"
            values={{
              appName: PLATFORM_NAME
            }}
          />
        </Text>

        <List size="xs" spacing="xs" withPadding>
          <ListItem>
            <FormattedMessage id="devices.registerSteps.step2Instruction1" />
          </ListItem>

          <ListItem>
            <FormattedMessage id="devices.registerSteps.step2Instruction2" />
          </ListItem>
          <ListItem>
            <FormattedMessage id="devices.registerSteps.step2Instruction3" />
          </ListItem>

          <ListItem>
            <FormattedMessage
              id="devices.registerSteps.step2Instruction4"
              values={{
                appName: PLATFORM_NAME
              }}
            />
            <Code block>
              <Text size="xs" mb="sm">
                {'sudo bash -c "$(wget -qO - http://bit.ly/vp-installer)"'}
              </Text>
            </Code>
          </ListItem>
          <ListItem>
            <FormattedMessage
              id="devices.registerSteps.step2Instruction5"
              values={{
                appName: PLATFORM_NAME
              }}
            />
          </ListItem>
        </List>
      </Card>

      <Card bg="gray.1" mb="md">
        <Text size="xs" fw="bold" mb="sm">
          <FormattedMessage id="devices.registerSteps.step3Title" />
        </Text>

        <List size="xs" spacing="xs" withPadding>
          <ListItem>
            <FormattedMessage
              id="devices.registerSteps.step3Instruction1"
              values={{
                appName: PLATFORM_NAME
              }}
            />
          </ListItem>

          <ListItem>
            <FormattedMessage id="devices.registerSteps.step3Instruction2" />
          </ListItem>
        </List>
      </Card>

      <Card bg="blue.0" mb="xl">
        <Text size="xs">
          <FormattedMessage id="devices.registerSteps.additionalHelp" />
        </Text>
      </Card>

      <Button
        rightSection={<IconRefresh size={16} />}
        fullWidth
        onClick={onRefresh}
      >
        <FormattedMessage id="refresh" />
      </Button>
    </>
  )
}
