export const getFormattedDate = (
  dateTimeString: string,
  options?: Intl.DateTimeFormatOptions
) => {
  return new Date(dateTimeString).toLocaleDateString(navigator.language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    ...options
  })
}

export const getFormattedDateWithTime = (dateTimeString: string | number) => {
  return new Date(dateTimeString).toLocaleDateString(navigator.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  })
}

export const getDayOfWeekName = (
  day: number,
  format?: 'short' | 'long' | 'narrow'
) => {
  return new Intl.DateTimeFormat(navigator.language, {
    weekday: format || 'long'
  }).format(new Date(1970, 0, 4 + day)) // Jan 4, 1970 was a Sunday, so 4 + dayNumber aligns to Monday as 1
}

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC'
}
