import {
  Anchor,
  Box,
  Button,
  Card,
  Group,
  Radio,
  Stack,
  Text,
  ThemeIcon
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { IconTimezone } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { WorkspaceUsersDropdown } from '@/components/workspaces/WorkspaceUsersDropdown/WorkspaceUsersDropdown'
import { NotificationSettings, Schedule } from '@/types/notificationSettings'
import { getBrowserTimezone } from '@/utils/date'
import { generateUUID } from '@/utils/uuid'
import {
  ScheduleForm,
  ScheduleFormValues
} from '../Schedule/ScheduleForm/ScheduleForm'
import { ScheduleHeader } from '../Schedule/ScheduleHeader'
import { ScheduleItem } from '../Schedule/ScheduleItem/ScheduleItem'
import { ScheduleModal } from '../Schedule/ScheduleModal'

type ScheduleWithUuid = Schedule & { uuid: string }

type NotificationFormValues = Omit<
  NotificationSettings,
  'is_default' | 'schedules'
> & {
  type: 'default' | 'custom'
  schedules: ScheduleWithUuid[]
}

type NotificationSettingsFormProps = {
  initialValues?: NotificationSettings
  workspaceId: string
  defaultEmail: string
  onCancel: () => void
  onSave: (values: NotificationSettings) => void
}

export const NotificationSettingsForm = ({
  initialValues,
  workspaceId,
  defaultEmail,
  onCancel,
  onSave
}: NotificationSettingsFormProps) => {
  const intl = useIntl()
  const browserTimezone = getBrowserTimezone()

  const [
    isScheduleModalOpened,
    { open: openScheduleModal, close: closeScheduleModal }
  ] = useDisclosure()

  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState<
    number | null
  >(null)

  const form = useForm<NotificationFormValues>({
    initialValues: initialValues
      ? {
          ...initialValues,
          type: initialValues.is_default ? 'default' : 'custom',
          schedules: initialValues.schedules.map((schedule) => ({
            ...schedule,
            uuid: generateUUID()
          }))
        }
      : {
          timezone: browserTimezone,
          type: 'default',
          workspace_users: [],
          schedules: []
        },
    validate: (values) =>
      values.type === 'custom'
        ? {
            workspace_users:
              values.workspace_users.length === 0
                ? intl.formatMessage({
                    id: 'deployments.notificationSettings.validation.emailsRequired'
                  })
                : null,
            schedules: !values.schedules.some((schedule) => schedule.is_enabled)
              ? intl.formatMessage({
                  id: 'deployments.notificationSettings.validation.schedulesRequired'
                })
              : null
          }
        : {}
  })

  const formValues = form.getValues()

  const selectedSchedule =
    selectedScheduleIndex !== null
      ? formValues.schedules[selectedScheduleIndex]
      : undefined

  const handleCloseModal = () => {
    setSelectedScheduleIndex(null)
    closeScheduleModal()
  }

  const handleEditSchedule = (index: number) => {
    setSelectedScheduleIndex(index)
    openScheduleModal()
  }

  const handleScheduleSave = (newValues: ScheduleFormValues) => {
    if (selectedScheduleIndex === null) {
      form.insertListItem('schedules', {
        uuid: generateUUID(),
        is_enabled: true,
        start_time: newValues.startTime,
        end_time: newValues.endTime,
        selected_days: newValues.selectedDays
      })
    } else {
      form.replaceListItem('schedules', selectedScheduleIndex, {
        ...formValues.schedules[selectedScheduleIndex],
        start_time: newValues.startTime,
        end_time: newValues.endTime,
        selected_days: newValues.selectedDays
      })
    }

    form.clearFieldError('schedules')
    handleCloseModal()
  }

  const handleToggleSchedule = (index: number) => {
    form.replaceListItem('schedules', index, {
      ...formValues.schedules[index],
      is_enabled: !formValues.schedules[index].is_enabled
    })
  }

  const handleRemoveSchedule = (index: number) => {
    form.removeListItem('schedules', index)
    form.clearFieldError('schedules')
  }

  const handleSubmit = (values: NotificationFormValues) => {
    const { type, ...restValues } = values

    onSave({
      ...restValues,
      is_default: type === 'default',
      schedules: type === 'default' ? [] : values.schedules,
      workspace_users: type === 'default' ? [] : values.workspace_users
    })
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <PlatformAlert variant="info" mb="lg" compact>
          <Text size="xs" mb="sm">
            <FormattedMessage id="deployments.notificationSettings.info" />
          </Text>

          <Text size="xs" fw={600}>
            <FormattedMessage id="deployments.notificationSettings.info.note" />
          </Text>
        </PlatformAlert>

        <Card padding="md" withBorder>
          <Radio.Group {...form.getInputProps('type')}>
            <Stack>
              <Radio
                value="default"
                styles={{
                  label: {
                    fontWeight: 600
                  }
                }}
                label={
                  <FormattedMessage id="deployments.notificationSettings.type.default" />
                }
                description={
                  <FormattedMessage
                    id="deployments.notificationSettings.type.default.description"
                    values={{
                      email: <b>{defaultEmail}</b>
                    }}
                  />
                }
              />

              <Radio
                value="custom"
                styles={{
                  label: {
                    fontWeight: 600
                  }
                }}
                label={
                  <FormattedMessage id="deployments.notificationSettings.type.custom" />
                }
                description={
                  <FormattedMessage id="deployments.notificationSettings.type.custom.description" />
                }
              />
            </Stack>
          </Radio.Group>

          {formValues.type === 'custom' && (
            <Card mt="xl" bg="gray.1" padding="lg">
              <WorkspaceUsersDropdown
                {...form.getInputProps('workspace_users')}
                workspaceId={workspaceId}
                label={
                  <FormattedMessage id="deployments.notificationSettings.email.label" />
                }
                placeholder={
                  <FormattedMessage id="deployments.notificationSettings.email.placeholder" />
                }
                fallbackName={
                  <FormattedMessage id="deployments.notificationSettings.email.inactive" />
                }
              />

              <Box mt={40}>
                <ScheduleHeader onAddSchedule={openScheduleModal} />

                <Stack
                  gap="xs"
                  pl="xs"
                  mt={formValues.schedules.length > 0 ? 'lg' : 0}
                >
                  {formValues.schedules.map((schedule, index) => (
                    <ScheduleItem
                      key={schedule.uuid}
                      startTime={schedule.start_time}
                      endTime={schedule.end_time}
                      selectedDays={schedule.selected_days}
                      isChecked={schedule.is_enabled}
                      onToggle={() => handleToggleSchedule(index)}
                      onRemove={() => handleRemoveSchedule(index)}
                      onEdit={() => handleEditSchedule(index)}
                    />
                  ))}
                </Stack>

                {form.errors.schedules && (
                  <Text c="red" size="xs" mt="sm">
                    {form.errors.schedules}
                  </Text>
                )}

                <Group gap="xs" wrap="nowrap" align="start" mt={40}>
                  <ThemeIcon
                    variant="transparent"
                    color="brand-primary"
                    size="sm"
                  >
                    <IconTimezone style={{ width: '80%', height: '80%' }} />
                  </ThemeIcon>

                  <Stack mt={4}>
                    <Text size="xs" fw={600}>
                      <FormattedMessage
                        id="deployments.notificationSettings.schedule.timezone"
                        values={{
                          timezone: formValues.timezone
                        }}
                      />
                    </Text>

                    {formValues.timezone !== browserTimezone && (
                      <Anchor
                        component="button"
                        type="button"
                        size="xs"
                        onClick={() =>
                          form.setFieldValue('timezone', browserTimezone)
                        }
                      >
                        <FormattedMessage
                          id="deployments.notificationSettings.schedule.timezone.sync"
                          values={{
                            timezone: browserTimezone
                          }}
                        />
                      </Anchor>
                    )}
                  </Stack>
                </Group>
              </Box>
            </Card>
          )}
        </Card>

        <Group justify="end" mt={80}>
          <Button miw={120} variant="default" onClick={onCancel}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button miw={120} type="submit">
            <FormattedMessage id="save" />
          </Button>
        </Group>
      </form>

      <ScheduleModal
        variant={selectedScheduleIndex === null ? 'add' : 'update'}
        opened={isScheduleModalOpened}
        onClose={handleCloseModal}
      >
        <ScheduleForm
          initialValues={
            selectedSchedule
              ? {
                  startTime: selectedSchedule.start_time,
                  endTime: selectedSchedule.end_time,
                  selectedDays: selectedSchedule.selected_days
                }
              : undefined
          }
          onCancel={handleCloseModal}
          onSave={handleScheduleSave}
        />
      </ScheduleModal>
    </>
  )
}
