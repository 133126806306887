export const FRAME_WIDTH = 960
export const FRAME_HEIGHT = 540

export const DEFAULT_COUNT_THRESHOLD = 1

export const DEFAULT_EVENT_SETTINGS = {
  notification: false,
  upload_video: true,
  upload_bbox_video: false
}

export const DEFAULT_EVENT_VIDEO_DURATION_TIME = 10 // in seconds
export const MIN_EVENT_VIDEO_DURATION_TIME = 10
export const MAX_EVENT_VIDEO_DURATION_TIME = 60
