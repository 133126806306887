import {
  BusinessLogic,
  NormalizedStreamBusinessLogic
} from '@/types/businessLogic'
import {
  BlurOptions,
  ConfidenceThreshold,
  DeploymentConfig
} from '@/types/deployment'
import { CameraStreamWithDeviceId } from '@/types/device'
import { NotificationSettings } from '@/types/notificationSettings'
import { SavedEventEndpoint } from '../IntegrationSelection/IntegrationSelection'
import { buildBuisnessLogicParameter } from './buildBuisnessLogicParameter'
import { buildEventEndpoints } from './buildEventEndpoints'

const getFrameSize = (resolution: CameraStreamWithDeviceId['resolution']) => {
  if (typeof resolution === 'string') {
    const [height, width] = resolution.split('x') // frame_width and frame_height are swapped h x w
    return { frameWidth: Number(width), frameHeight: Number(height) }
  } else {
    return { frameWidth: resolution.width, frameHeight: resolution.height }
  }
}

type BuildDeploymentDataParams = {
  appId: string
  primaryModelId: string
  secondaryModelId?: string
  width: number
  height: number
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: NormalizedStreamBusinessLogic[]
  savedEventEndpoints: SavedEventEndpoint[]
  liveInferenceCameraStreamIds: string[]
  confidenceThresholds: ConfidenceThreshold[]
  blurOptions: BlurOptions
  eventVideoDurationTime: number
  notificationSettings: NotificationSettings
}

export const buildDeploymentData = ({
  appId,
  primaryModelId,
  secondaryModelId,
  width,
  height,
  cameraStreams,
  savedStreamLogics,
  savedEventEndpoints,
  liveInferenceCameraStreamIds,
  confidenceThresholds,
  blurOptions,
  eventVideoDurationTime,
  notificationSettings
}: BuildDeploymentDataParams): DeploymentConfig => {
  return {
    application_id: appId,
    primary_model_id: primaryModelId,
    secondary_model_id: secondaryModelId,
    config_width: width,
    config_height: height,
    confidence_threshold: confidenceThresholds,
    blur_options: blurOptions,
    event_video_duration_time: eventVideoDurationTime,
    notification_settings: notificationSettings,
    streams: cameraStreams.map((cameraStream) => {
      const streamLogics = savedStreamLogics.filter(
        (savedStreamLogic) =>
          savedStreamLogic.camera_id === cameraStream.camera_id
      )

      const businessLogics: BusinessLogic[] = streamLogics.flatMap(
        (streamLogic) => {
          const logicParameters = streamLogic.parameters.map((parameter) => {
            return buildBuisnessLogicParameter({
              type: parameter.type,
              coordinates: parameter.coordinates,
              name: parameter.name,
              alert: parameter.notification,
              upload_video: parameter.upload_video,
              upload_bbox_video: parameter.upload_bbox_video,
              rules: parameter.rules
            })
          })

          return {
            id: streamLogic.logic_id,
            parameters: logicParameters
          }
        }
      )

      const { frameWidth, frameHeight } = getFrameSize(cameraStream.resolution)

      return {
        stream_id: cameraStream.camera_id,
        device_id: cameraStream.device_id,
        frame_width: frameWidth,
        frame_height: frameHeight,
        eglsink: liveInferenceCameraStreamIds.includes(cameraStream.camera_id),
        business_logics: businessLogics,
        event_endpoints: buildEventEndpoints(savedEventEndpoints, cameraStream)
      }
    })
  }
}
