import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { getPageByUrl } from '@/api/vpApi'
import { workspaceApi } from '@/api/workspaceApi'
import { PaginatedResponse } from '@/types/pagination'
import { WorkspaceMember } from '@/types/workspace'
import { objectToQueryString } from '@/utils/url'

export const workspaceQueryKeys = {
  all: ['workspaces'] as const,
  members: ({
    pagination,
    ordering,
    filter,
    searchQuery,
    workspaceId
  }: GetWorkspaceMembersProps) =>
    [
      ...workspaceQueryKeys.all,
      'members',
      pagination,
      ordering,
      filter,
      searchQuery,
      workspaceId
    ] as const
}

export const useCreateWorkspace = () => {
  return useMutation({
    mutationFn: workspaceApi.createWorkspace
  })
}

export const useUpdateWorkspace = () => {
  return useMutation({
    mutationFn: workspaceApi.updateWorkspace
  })
}

export const useInviteMembers = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.inviteMembers,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

type Pagination = {
  limit?: number
  offset?: number
}

type GetWorkspaceMembersProps = {
  workspaceId: string
  pagination?: Pagination
  ordering?: string
  searchQuery?: string
  filter?: Record<string, string | number>
}

export const useGetWorkspaceMembers = ({
  workspaceId,
  pagination,
  ordering,
  searchQuery,
  filter
}: GetWorkspaceMembersProps) => {
  const pageUrl = `/v1/workspaces/${workspaceId}/members/?${objectToQueryString(
    { ordering, search: searchQuery, ...pagination, ...filter }
  )}`

  return useQuery({
    queryKey: workspaceQueryKeys.members({
      pagination,
      ordering,
      filter,
      searchQuery,
      workspaceId
    }),
    queryFn: () => getPageByUrl<PaginatedResponse<WorkspaceMember[]>>(pageUrl),
    placeholderData: keepPreviousData
  })
}

export const useUpdateWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.updateWorkspaceMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

export const useResendMembershipInvite = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.resendInvite,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

export const useDeactivateWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: workspaceApi.deactivateWorkspaceMember,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [...workspaceQueryKeys.all, 'members']
      })
    }
  })
}

export const useAcceptWorkspaceInvitation = () => {
  return useMutation({
    mutationFn: workspaceApi.acceptWorkspaceInvitation
  })
}
