import { useState } from 'react'
import { LoginPayload, accountApi } from '@/api/accountApi'
import { setCsrfToken } from '@/api/vpApi'
import { useSession } from '@/session/SessionContext'

export const useLogin = () => {
  const { refreshSession } = useSession()
  const [isPending, setIsPending] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const login = async (payload: LoginPayload) => {
    setError(null)
    setIsError(false)
    setIsPending(true)

    try {
      await accountApi.login(payload)

      // new csrf token must be retrieved after login
      const csrfToken = await accountApi.getCsrfToken()
      setCsrfToken(csrfToken)

      await refreshSession()
      // NOTE: The login redirect is handled by the top-level PublicAuthRoute component
    } catch (err) {
      setError(err)
      setIsError(true)
    }
    setIsPending(false)
  }

  return {
    login,
    isPending,
    isError,
    error
  }
}
