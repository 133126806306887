import { useSession } from '@/session/SessionContext'
import { Resource, ResourceAction } from '@/types/permissions'

/**
 * Hook to check if the current session has permissions for a resource.
 *
 * Input:
 *   - resource: The resource to check.
 *   - actions: Actions to check permissions for.
 *
 * Output:
 *   - Array of booleans indicating if each action is permitted.
 *
 * Example:
 *   const hasPermissions = useHasPermissions('someResource', ['read', 'write']);
 *   // hasPermissions will be an array of booleans for 'read' and 'write' actions.
 */

export const useHasPermissions = (
  resource: Resource,
  actions: ResourceAction[]
) => {
  const { permissions } = useSession()

  return actions.map(
    (action) => permissions[resource]?.includes(action) ?? false
  )
}
