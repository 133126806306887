import { useIntl } from 'react-intl'
import { WorkspaceMemberRole } from '@/types/workspace'

const hiddenRoles = [
  WorkspaceMemberRole.Owner,

  // Temporarily hide viewers and labelers from the role dropdown until application sharing is supported
  WorkspaceMemberRole.Labeler,
  WorkspaceMemberRole.Viewer
]

export const useRoleDropdownOptions = () => {
  const intl = useIntl()

  return Object.values(WorkspaceMemberRole)
    .filter(
      (value) => typeof value === 'number' && !hiddenRoles.includes(value)
    )
    .map((value) => ({
      value: String(value),
      label: intl.formatMessage({
        id: `workspace.members.role.${WorkspaceMemberRole[value as number].toLowerCase()}`
      })
    }))
}
