import { Box, LoadingOverlay } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { useValidateToken } from '@/hooks/useValidateToken'
import { LinkExpiredHandler } from './LinkExpiredHandler'
import { SignUpFormHandler } from './SignUpFormHandler'

export const CompleteSignUpPage = () => {
  const { isTokenValid, token, email, isPending } = useValidateToken()

  if (isPending) {
    return (
      <Box pos="relative" mih={200}>
        <LoadingOverlay visible={isPending} />
      </Box>
    )
  }

  if (isTokenValid && email && token) {
    return <SignUpFormHandler email={email} token={token} />
  }

  if (!isTokenValid && email) {
    return <LinkExpiredHandler email={email} />
  }

  return (
    <Box m="20px auto" maw={640}>
      <PlatformAlert variant="error" compact>
        <FormattedMessage id="verifyEmail.linkError" />
      </PlatformAlert>
    </Box>
  )
}
