import { Box, Group, LoadingOverlay, Modal } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { useDeleteApplication } from '@/queries/applicationQueries'
import { showToast } from '@/theme/notifications'
import { Application } from '@/types/app'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { DeleteApplicationForm } from './DeleteApplicationForm/DeleteApplicationForm'

type DeleteAppErrorProps = {
  error: ApiError
}

const DeleteAppError = ({ error }: DeleteAppErrorProps) => {
  const { errorMessage } = error

  return (
    <PlatformAlert variant="error" compact>
      {errorMessage || <FormattedMessage id="apps.deleteApp.error" />}
    </PlatformAlert>
  )
}

const DeleteAppModalTitle = () => (
  <Group>
    <IconTrash size={20} />
    <FormattedMessage id="apps.deleteApp" />
  </Group>
)

type DeleteApplicationModalProps = {
  app: Application
  opened: boolean
  onClose: () => void
  onAppDeleted: () => void
}

export const DeleteApplicationModal = ({
  app,
  opened,
  onClose,
  onAppDeleted
}: DeleteApplicationModalProps) => {
  const intl = useIntl()

  const {
    mutateAsync: deleteApp,
    isPending,
    isError,
    error
  } = useDeleteApplication()

  const handleConfirmDelete = async () => {
    await deleteApp(app.id)

    showToast(intl.formatMessage({ id: 'apps.deleteApp.success' }), 'success')

    onAppDeleted()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<DeleteAppModalTitle />}
      onClose={onClose}
    >
      <Box pos="relative">
        {isError && (
          <Box mb="lg">
            <DeleteAppError error={getApiError(error)} />
          </Box>
        )}

        <LoadingOverlay visible={isPending} />

        <DeleteApplicationForm
          confirmAppName={app.name}
          onCancel={onClose}
          onConfirmDelete={() => void handleConfirmDelete()}
        />
      </Box>
    </Modal>
  )
}
