import { Anchor, Box, Center, Container, Text, Title } from '@mantine/core'
import { IconError404 } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import { RouterPath } from '@/router/paths'

type PageNotFoundProps = {
  homeLink: RouterPath
}

export const PageNotFound = ({ homeLink }: PageNotFoundProps) => {
  return (
    <Container>
      <Center mih={400}>
        <Box ta="center">
          <IconError404 size={72} color="var(--mantine-color-blue-8)" />

          <Title order={3} my="lg">
            <FormattedMessage id="pageNotFound" />
          </Title>

          <Text size="sm">
            <Anchor component={Link} to={homeLink}>
              <FormattedMessage id="returnHome" />
            </Anchor>
          </Text>
        </Box>
      </Center>
    </Container>
  )
}
