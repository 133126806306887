import { Outlet } from 'react-router'
import {
  ApplicationProvider,
  useApplicationContext
} from '@/providers/ApplicationContext'
import { ModelProvider, useModelContext } from '@/providers/ModelContext'
import { AppDetailsLayout } from './AppDetailsLayout'
import { AppDetailsSidebar } from './AppDetailsSidebar'
import { ModelDetailsSidebar } from './ModelDetailsSidebar'

export const AppDetailsProvider = () => {
  return (
    <ApplicationProvider>
      <Outlet />
    </ApplicationProvider>
  )
}

export const AppDetailsLayoutWrapper = () => {
  return (
    <AppDetailsLayout navbar={<AppDetailsSidebar />}>
      <Outlet />
    </AppDetailsLayout>
  )
}

export const ModelDetailsProvider = () => {
  return (
    <ModelProvider>
      <Outlet />
    </ModelProvider>
  )
}

export const ModelDetailsLayoutWrapper = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  return (
    <AppDetailsLayout
      navbar={<ModelDetailsSidebar application={application} model={model} />}
    >
      <Outlet />
    </AppDetailsLayout>
  )
}
