import { Button, Group, SimpleGrid, Stack, Title } from '@mantine/core'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetAppMLModels } from '@/queries/modelQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { MLModel, MLModelStatus, MLModelType } from '@/types/model'
import { SelectableModelCard } from './SelectableModelCard/SelectableModelCard'

type ModelSelectionProps = {
  title: string | ReactNode
  applicationId: string
  selectedModel: MLModel | null
  modelType: MLModelType
  showBackButton?: boolean
  onSelectionChange: (model: MLModel | null) => void
  onGoBack?: () => void
  onContinue: () => void
}

export const ModelSelection = ({
  title,
  applicationId,
  selectedModel,
  modelType,
  showBackButton,
  onSelectionChange,
  onGoBack,
  onContinue
}: ModelSelectionProps) => {
  const navigate = useNavigate()

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetAppMLModels(applicationId, {
      status: [MLModelStatus.PreTrained, MLModelStatus.Trained],
      model_type: modelType
    })

  const models = data?.pages.flatMap((page) => page.results) ?? []

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleOnReload = () => {
    void refetch()
  }

  const isModelSelected = (modelId: string) => {
    return selectedModel?.id === modelId
  }

  const toggleModelSelection = (model: MLModel) => {
    if (isModelSelected(model.id)) {
      onSelectionChange(null)
    } else {
      onSelectionChange(model)
    }
  }

  const handleGoToModels = () => {
    void navigate(buildAppLink(applicationId, ApplicationNestedPath.models))
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="models.modelList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Group align="center" gap="lg" mb="md">
        <Title order={3}>{title}</Title>
      </Group>

      <StepSection
        actions={
          <Group>
            {showBackButton && (
              <Button
                size="md"
                variant="outline"
                miw={200}
                leftSection={<IconChevronLeft size={16} stroke={3} />}
                onClick={onGoBack}
              >
                <FormattedMessage id="deployments.previousStep" />
              </Button>
            )}

            <Button
              miw={200}
              size="md"
              rightSection={<IconChevronRight size={16} stroke={3} />}
              disabled={selectedModel === null}
              onClick={onContinue}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        {models.length === 0 && !isFetching && (
          <Stack align="center" justify="center" gap="xl">
            <FormattedMessage id="deployments.noTrainedModels" />

            <Button onClick={handleGoToModels}>
              <FormattedMessage id="deployments.goToMyModels" />
            </Button>
          </Stack>
        )}

        <InfiniteScroll
          hasMore={hasNextPage}
          isFetching={isFetching}
          onLoadMore={handleLoadMore}
        >
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
            {models.map((model) => (
              <SelectableModelCard
                key={model.id}
                name={model.name}
                status={model.status as MLModelStatus}
                logoUrl={model.root_model?.brand_logo || undefined}
                isSelected={isModelSelected(model.id)}
                onClick={() => toggleModelSelection(model)}
              />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      </StepSection>
    </>
  )
}
