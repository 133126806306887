import {
  Box,
  Button,
  Card,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { isInRange, useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { BlurObjectSelect } from '@/components/events/BlurObjectSelect/BlurObjectSelect'
import {
  ConfidenceSettings,
  DisplayConfidenceThreshold
} from '@/components/events/ConfidenceSettings/ConfidenceSettings'
import { IntegrationInputHandler } from '@/components/integrations/IntegrationInputHandler/IntegrationInputHandler'
import { IntegrationListItem } from '@/components/integrations/IntegrationListItem/IntegrationListItem'
import { LabelWithHint } from '@/components/ui-shared/LabelWithHint/LabelWithHint'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { useSession } from '@/session/SessionContext'
import {
  BlurOptions,
  ConfidenceThreshold,
  EventDestination
} from '@/types/deployment'
import { NotificationSettings } from '@/types/notificationSettings'
import { isValidUrl } from '@/utils/validation/url'
import { useDeploymentStepperContext } from '../DeploymentStepperContext'
import {
  MAX_EVENT_VIDEO_DURATION_TIME,
  MIN_EVENT_VIDEO_DURATION_TIME
} from '../LogicSelection/LogicHandler/constants'
import { NotificationSettingsDrawer } from '../LogicSelection/NotificationSettings/NotificationSettingsDrawer'
import { NotificationSettingsForm } from '../LogicSelection/NotificationSettings/NotificationSettingsForm/NotificationSettingsForm'
import { NotificationSettingsInfo } from '../LogicSelection/NotificationSettings/NotificationSettingsInfo/NotificationSettingsInfo'
import { ConfigValues, integrationConfig } from './config'

export type SavedEventEndpoint = {
  service: EventDestination
  endpoint?: string
}

type DisplayIntegration = {
  name: string
  description: string
  logo: string
  type: EventDestination
  endpointUrl: string
  endpointUrlRequired: boolean
  isSelected: boolean
}

type FormValues = {
  integrations: DisplayIntegration[]
  confidenceThresholds: ConfidenceThreshold[]
  blurOptions: BlurOptions
  eventVideoDurationTime: number
}

export type IntegrationSubmitValues = {
  integrations: SavedEventEndpoint[]
  confidenceThresholds: ConfidenceThreshold[]
  blurOptions: BlurOptions
  eventVideoDurationTime: number
  notificationSettings: NotificationSettings
}

type IntegrationSelectionProps = {
  selectedDeviceIds: string[]
  savedEventEndpoints: SavedEventEndpoint[]
  confidenceThresholds: ConfidenceThreshold[]
  blurOptions: BlurOptions
  eventVideoDurationTime: number
  notificationSettings: NotificationSettings
  showNotificationSettings?: boolean
  onGoBack: () => void
  onContinue: (values: IntegrationSubmitValues) => void
}

export const IntegrationSelection = ({
  selectedDeviceIds,
  savedEventEndpoints,
  confidenceThresholds,
  blurOptions,
  eventVideoDurationTime,
  notificationSettings,
  showNotificationSettings,
  onGoBack,
  onContinue
}: IntegrationSelectionProps) => {
  const intl = useIntl()

  const { activeWorkspaceMembership } = useSession()

  const [
    isNotificationSettingsDrawerOpened,
    {
      open: openNotificationSettingsDrawer,
      close: closeNotificationSettingsDrawer
    }
  ] = useDisclosure()

  const [cachedNotificationSettings, setCachedNotificationSettings] =
    useState<NotificationSettings>(notificationSettings)

  const form = useForm<FormValues>({
    initialValues: {
      integrations: (
        Object.entries(integrationConfig) as [EventDestination, ConfigValues][]
      ).map(([type, config]) => {
        const savedEndpoint = savedEventEndpoints.find(
          (endpoint) => endpoint.service === type
        )

        return {
          name: config.name,
          description: config.description,
          logo: config.logo,
          type: type,
          endpointUrl: savedEndpoint?.endpoint || '',
          endpointUrlRequired: type !== EventDestination.Visionplatform,
          isSelected: savedEndpoint !== undefined
        }
      }),
      confidenceThresholds,
      eventVideoDurationTime,
      blurOptions
    },

    validateInputOnBlur: true,

    validate: {
      integrations: {
        endpointUrl: (value, formValues, path) => {
          const index = Number(path.split('.')[1])
          const integration = formValues.integrations[index]

          if (
            integration.isSelected &&
            integration.endpointUrlRequired &&
            !isValidUrl(value)
          ) {
            return intl.formatMessage({
              id: 'validation.url.invalid'
            })
          }

          return null
        }
      },
      eventVideoDurationTime: isInRange(
        {
          min: MIN_EVENT_VIDEO_DURATION_TIME,
          max: MAX_EVENT_VIDEO_DURATION_TIME
        },
        intl.formatMessage(
          { id: 'events.video.duration.error' },
          {
            min: MIN_EVENT_VIDEO_DURATION_TIME,
            max: MAX_EVENT_VIDEO_DURATION_TIME
          }
        )
      )
    }
  })

  const { primaryLabels, secondaryLabels } = useDeploymentStepperContext()

  const formValues = form.getValues()

  const confidenceThresholdOptions = formValues.confidenceThresholds.map(
    (thresholdObj) => ({
      labelId: thresholdObj.label_id,
      labelName:
        primaryLabels
          .concat(secondaryLabels)
          .find((label) => label.id === thresholdObj.label_id)?.name || '',
      threshold: thresholdObj.threshold
    })
  )

  const notificationMyUser =
    activeWorkspaceMembership?.id &&
    (cachedNotificationSettings.workspace_users.includes(
      activeWorkspaceMembership?.id
    ) ||
      cachedNotificationSettings.is_default)
      ? activeWorkspaceMembership?.user.full_name
      : ''

  const notificationOtherUsers =
    cachedNotificationSettings.workspace_users.filter(
      (userId) => userId !== activeWorkspaceMembership?.id
    )

  const handleSelectionChange = (index: number, value: boolean) => {
    form.setFieldValue(`integrations.${index}.isSelected`, value)
    form.clearErrors()
  }

  const handleContinueClick = () => {
    const selectedIntegrations = formValues.integrations.filter(
      (integration) => integration.isSelected
    )

    const savedEventEndpoints = selectedIntegrations.map((integration) => {
      return {
        service: integration.type,
        endpoint: integration.endpointUrl
      }
    })

    onContinue({
      integrations: savedEventEndpoints,
      confidenceThresholds: formValues.confidenceThresholds,
      blurOptions: formValues.blurOptions,
      eventVideoDurationTime: formValues.eventVideoDurationTime,
      notificationSettings: cachedNotificationSettings
    })
  }

  const handleConfidenceChange = (
    index: number,
    thresholdObj: DisplayConfidenceThreshold
  ) => {
    form.setFieldValue(
      `confidenceThresholds.${index}.threshold`,
      thresholdObj.threshold
    )
  }

  const handleSaveNotificationSettings = (values: NotificationSettings) => {
    setCachedNotificationSettings(values)
    closeNotificationSettingsDrawer()
  }

  return (
    <>
      <Title order={3} mb="md">
        <FormattedMessage id="deployments.step6" />
      </Title>

      <StepSection
        actions={
          <Group>
            <Button
              size="md"
              variant="outline"
              miw={200}
              leftSection={<IconChevronLeft size={16} stroke={3} />}
              onClick={onGoBack}
            >
              <FormattedMessage id="deployments.previousStep" />
            </Button>

            <Button
              miw={200}
              size="md"
              rightSection={<IconChevronRight size={16} stroke={3} />}
              disabled={!form.isValid()}
              onClick={handleContinueClick}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        <Card p="lg">
          <Card.Section py="sm" inheritPadding>
            <Text size="sm">
              <FormattedMessage id="integrations.notificationPreference" />
            </Text>
          </Card.Section>

          {formValues.integrations.map((integration, index) => (
            <Card.Section
              key={integration.type}
              py="lg"
              inheritPadding
              withBorder
            >
              <IntegrationListItem
                isSelected={integration.isSelected}
                title={<FormattedMessage id={integration.name} />}
                description={<FormattedMessage id={integration.description} />}
                logoSrc={integration.logo}
                showDetails={integration.isSelected}
                onChange={(value) => handleSelectionChange(index, value)}
              >
                {integration.type === EventDestination.CustomApi && (
                  <IntegrationInputHandler
                    {...form.getInputProps(`integrations.${index}.endpointUrl`)}
                    label={<FormattedMessage id="integrations.endpointUrl" />}
                    placeholder="https://api.your-domain.com/events"
                    deviceId={selectedDeviceIds[0]} // TODO we have a task to validate URL for multiple devices
                    source={EventDestination.CustomApi}
                    isTestButtonDisabled={
                      !form.isValid(`integrations.${index}.endpointUrl`)
                    }
                  />
                )}

                {integration.type === EventDestination.Milestone && (
                  <>
                    <PlatformAlert variant="info">
                      <Text size="xs">
                        <FormattedMessage
                          id="integrations.milestone.info"
                          values={{
                            b: (chunks) => <b>{chunks}</b>
                          }}
                        />
                      </Text>
                    </PlatformAlert>

                    <IntegrationInputHandler
                      {...form.getInputProps(
                        `integrations.${index}.endpointUrl`
                      )}
                      label={
                        <FormattedMessage id="integrations.milestone.inputLabel" />
                      }
                      description={
                        <FormattedMessage
                          id="integrations.milestone.inputHint"
                          values={{
                            url: 'https://<aibridge host>:<aibridge port>/api/bridge/graphql'
                          }}
                        />
                      }
                      inputWrapperOrder={[
                        'label',
                        'input',
                        'description',
                        'error'
                      ]}
                      deviceId={selectedDeviceIds[0]} // TODO we have a task to validate URL for multiple devices
                      source={EventDestination.Milestone}
                      isTestButtonDisabled={
                        !form.isValid(`integrations.${index}.endpointUrl`)
                      }
                    />
                  </>
                )}
              </IntegrationListItem>
            </Card.Section>
          ))}
        </Card>

        <Card mt="sm" padding="xl">
          <Grid>
            <Grid.Col span={6}>
              <Box maw={400}>
                <ConfidenceSettings
                  confidenceThresholds={confidenceThresholdOptions}
                  onConfidenceChange={handleConfidenceChange}
                />
              </Box>
            </Grid.Col>

            <Grid.Col span={5} offset={1}>
              {showNotificationSettings && (
                <Stack gap="sm" mb="xl">
                  <Text size="sm" fw={600}>
                    <FormattedMessage id="deployments.notificationSettings.title" />
                  </Text>

                  <Card bg="gray.0">
                    <NotificationSettingsInfo
                      mainUserName={notificationMyUser}
                      otherUserCount={notificationOtherUsers.length}
                      hasCustomSchedule={
                        cachedNotificationSettings.is_default === false &&
                        cachedNotificationSettings.schedules.length > 0
                      }
                      onEdit={openNotificationSettingsDrawer}
                    />
                  </Card>
                </Stack>
              )}

              <Stack gap="sm">
                <Text size="sm" fw={600}>
                  <FormattedMessage id="events.settings" />
                </Text>

                <Card bg="gray.0">
                  <NumberInput
                    {...form.getInputProps('eventVideoDurationTime')}
                    label={
                      <LabelWithHint
                        label={<FormattedMessage id="events.video.duration" />}
                        hint={
                          <FormattedMessage id="events.video.duration.tooltip" />
                        }
                      />
                    }
                    description={
                      <FormattedMessage
                        id="events.video.duration.hint"
                        values={{
                          min: MIN_EVENT_VIDEO_DURATION_TIME,
                          max: MAX_EVENT_VIDEO_DURATION_TIME
                        }}
                      />
                    }
                    inputWrapperOrder={[
                      'label',
                      'input',
                      'description',
                      'error'
                    ]}
                    size="xs"
                    mb="lg"
                  />

                  <BlurObjectSelect
                    size="xs"
                    blurOptions={formValues.blurOptions}
                    onChange={(values) => {
                      form.setFieldValue('blurOptions', values)
                    }}
                  />
                </Card>
              </Stack>
            </Grid.Col>
          </Grid>
        </Card>
      </StepSection>

      <NotificationSettingsDrawer
        opened={isNotificationSettingsDrawerOpened}
        onClose={closeNotificationSettingsDrawer}
      >
        {activeWorkspaceMembership && (
          <NotificationSettingsForm
            workspaceId={activeWorkspaceMembership.workspace.id}
            defaultEmail={activeWorkspaceMembership.user.email}
            initialValues={cachedNotificationSettings}
            onCancel={closeNotificationSettingsDrawer}
            onSave={handleSaveNotificationSettings}
          />
        )}
      </NotificationSettingsDrawer>
    </>
  )
}
