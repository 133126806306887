import { ActionIcon } from '@mantine/core'
import { IconCaretLeftFilled, IconCaretRightFilled } from '@tabler/icons-react'
import { MouseEventHandler } from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { Event } from '@/types/event'
import { MediaItem } from './MediaItem'

type NavButtonProps = {
  position: 'left' | 'right'
  disabled: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const NavButton = ({ position, disabled, onClick }: NavButtonProps) => {
  return (
    <ActionIcon
      disabled={disabled}
      size="lg"
      radius="xl"
      mx={20}
      styles={{
        root: {
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: position === 'left' ? 0 : 'auto',
          right: position === 'right' ? 0 : 'auto',
          zIndex: 4
        }
      }}
      onClick={onClick}
    >
      {position === 'left' ? (
        <IconCaretLeftFilled style={{ width: '60%', height: '60%' }} />
      ) : (
        <IconCaretRightFilled style={{ width: '60%', height: '60%' }} />
      )}
    </ActionIcon>
  )
}

type GalleryItem = ReactImageGalleryItem & {
  video_path: string
  video_bbox_path: string
  image_path: string
  type: 'video' | 'image'
}

type EventRecordingsProps = {
  eventId: string
  recordings: Event['recordings']
}

export const EventRecordings = ({
  eventId,
  recordings
}: EventRecordingsProps) => {
  const items = recordings.map((recording) => {
    const video_bbox_path =
      'video_bbox_path' in recording ? recording.video_bbox_path : ''

    return {
      original: '',
      video_path: recording.video_path,
      video_bbox_path: video_bbox_path,
      image_path: recording.image_path,
      type: video_bbox_path || recording.video_path ? 'video' : 'image'
    }
  })

  return (
    <ImageGallery
      items={items}
      infinite={false}
      showPlayButton={false}
      showFullscreenButton={false}
      renderItem={(item) => {
        const { video_path, video_bbox_path, image_path, type } =
          item as GalleryItem

        return (
          <MediaItem
            eventId={eventId}
            video_path={video_path}
            video_bbox_path={video_bbox_path}
            image_path={image_path}
            type={type}
          />
        )
      }}
      renderLeftNav={(onClick, disabled) => (
        <NavButton position="left" disabled={disabled} onClick={onClick} />
      )}
      renderRightNav={(onClick, disabled) => (
        <NavButton position="right" disabled={disabled} onClick={onClick} />
      )}
      lazyLoad
      onErrorImageURL={ImagePlaceholder}
    />
  )
}
