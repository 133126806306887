import { SimpleGrid } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AddButtonCard } from '@/components/ui-shared/AddButtonCard/AddButtonCard'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useGetAppMLModels } from '@/queries/modelQueries'
import { buildModelLink } from '@/router/paths'
import { MLModel, MLModelStatus, MLModelType } from '@/types/model'
import { CrudAction, Resource } from '@/types/permissions'
import { ErrorWithReload } from '../../ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '../../ui-shared/InfiniteScroll/InfiniteScroll'
import { BaseModelInfo } from '../BaseModelSelection/BaseModelInfo/BaseModelInfo'
import { CreateModelModal } from '../CreateModelModal/CreateModelModal'
import { DeleteModelModal } from '../DeleteModelModal'
import { ModelCard } from '../ModelCard/ModelCard'
import { UpdateModelModal } from '../UpdateModelModal/UpdateModelModal'

type ModelListProps = {
  applicationId: string
  modelType: MLModelType
  addModelLabel: string | ReactNode
}

export const ModelList = ({
  applicationId,
  modelType,
  addModelLabel
}: ModelListProps) => {
  const [
    isCreateModelModalOpen,
    { open: openCreateModelModal, close: closeCreateModelModal }
  ] = useDisclosure(false)

  const [modelToDelete, setModelToDelete] = useState<MLModel | null>(null)
  const [modelToRename, setModelToRename] = useState<MLModel | null>(null)

  const [canCreateModel, canUpdateModel, canDeleteModel] = useHasPermissions(
    Resource.MlModels,
    [CrudAction.Create, CrudAction.Update, CrudAction.Delete]
  )

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetAppMLModels(applicationId, {
      model_type: modelType
    })

  const models = data?.pages?.flatMap((page) => page.results) || []

  const handleModelUpdated = () => {
    setModelToRename(null)
    void refetch()
  }

  const handleModelDeleted = () => {
    setModelToDelete(null)
    void refetch()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="models.modelList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={() => void fetchNextPage()}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
          {canCreateModel && (
            <AddButtonCard
              height={175}
              label={addModelLabel}
              onClick={() => void openCreateModelModal()}
            />
          )}

          {models.map((model) => (
            <ModelCard
              key={model.id}
              modelName={model.name}
              modelStatus={model.status as MLModelStatus}
              modelDetailsLink={buildModelLink(applicationId, model.id)}
              logoUrl={model.root_model?.brand_logo || undefined}
              showActions={canUpdateModel || canDeleteModel}
              showEdit={canUpdateModel}
              showDelete={canDeleteModel}
              infoComponent={
                <BaseModelInfo
                  name={model.name}
                  baseModelName={model.root_model?.name}
                  labels={
                    model.dataset_version?.labels ||
                    model?.base_model?.dataset_version?.labels ||
                    []
                  }
                  brandName={model?.root_model?.brand_name_display || undefined}
                  brandLogo={model?.root_model?.brand_logo || undefined}
                  networkType={model?.root_model?.network_type || undefined}
                />
              }
              showInfoOnHover={model.status !== MLModelStatus.Draft}
              onRename={() => setModelToRename(model)}
              onDelete={() => setModelToDelete(model)}
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      <CreateModelModal
        appId={applicationId}
        modelType={modelType}
        opened={isCreateModelModalOpen}
        onClose={closeCreateModelModal}
      />

      {modelToRename && (
        <UpdateModelModal
          opened={modelToRename !== null}
          model={modelToRename}
          onClose={() => setModelToRename(null)}
          onUpdated={handleModelUpdated}
        />
      )}

      <DeleteModelModal
        opened={modelToDelete !== null}
        modelId={modelToDelete?.id || ''}
        onClose={() => setModelToDelete(null)}
        onDeleted={handleModelDeleted}
      />
    </>
  )
}
