import { Group, Loader } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'

type TrainingInProgressBannerProps = {
  rightSection?: ReactNode
}

export const TrainingInProgressBanner = ({
  rightSection
}: TrainingInProgressBannerProps) => {
  const icon = <Loader size={16} />

  return (
    <PlatformAlert
      variant="info"
      title={<FormattedMessage id="training.status.inProgress" />}
      icon={icon}
    >
      <Group justify="space-between" align="center">
        <FormattedMessage id="training.inProgressDescription" />

        {rightSection}
      </Group>
    </PlatformAlert>
  )
}
