import { Button } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { VP_PRICING_PAGE } from '@/config/main'

export const CompareFeaturesButton = () => {
  return (
    <Button
      component="a"
      variant="default"
      radius="xl"
      size="lg"
      miw={320}
      href={VP_PRICING_PAGE}
      target="_blank"
      rightSection={<IconChevronDown size={16} stroke={3} />}
    >
      <FormattedMessage id="subscriptions.compareFeatures" />
    </Button>
  )
}
