import { Button, Center } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { Navigate, Outlet } from 'react-router'
import { AuthLayout } from '@/components/layout/AuthLayout/AuthLayout'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { useSession } from '@/session/SessionContext'
import { useLogout } from '@/session/useLogout'
import { RouterPath } from './paths'

const NoMembershipAlert = () => {
  const { logout } = useLogout()

  return (
    <AuthLayout variant="large">
      <PlatformAlert variant="warning" mb="xl" compact>
        <FormattedMessage id="workspace.noMembership" />
      </PlatformAlert>

      <Center>
        <Button size="xs" miw={120} onClick={() => void logout()}>
          <FormattedMessage id="logout" />
        </Button>
      </Center>
    </AuthLayout>
  )
}

export const WorkspaceChecker = () => {
  const { isAuthenticated, workspaceMemberships, activeWorkspaceMembership } =
    useSession()

  if (!isAuthenticated) {
    return null
  }

  // If user has no workspace memberships, redirect to create workspace page
  if (workspaceMemberships.length === 0) {
    return <Navigate to={RouterPath.createWorkspace} replace />
  }

  // If user has workspace memberships but no active workspace, ask to contact workspace admin
  if (!activeWorkspaceMembership) {
    return <NoMembershipAlert />
  }

  return <Outlet />
}
