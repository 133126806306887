import { Button, Group, Table, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { Label } from '@/types/label'
import { CrudAction, Resource } from '@/types/permissions'
import { Training, TrainingStatus } from '@/types/training'
import { getFormattedDateWithTime } from '@/utils/date'
import { TrainingStatusIndicator } from './TrainingStatusIndicator/TrainingStatusIndicator'

type TrainingParamsTableProps = {
  training: Training
  modelName: string
  labels: Label[]
  onRetry: () => void
}

export const TrainingParamsTable = ({
  training,
  modelName,
  labels,
  onRetry
}: TrainingParamsTableProps) => {
  const [canCreateTraining] = useHasPermissions(Resource.Trainings, [
    CrudAction.Create
  ])

  return (
    <>
      <Table
        verticalSpacing="md"
        horizontalSpacing="md"
        mb="xl"
        withColumnBorders
        withTableBorder
      >
        <Table.Thead bg="gray.0">
          <Table.Tr>
            <Table.Th colSpan={2}>
              <FormattedMessage id="training.summary" />
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          <Table.Tr>
            <Table.Td fw="bold" w="50%">
              <FormattedMessage id="training.modelName" />
            </Table.Td>

            <Table.Td>{modelName}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold" w="50%">
              <FormattedMessage id="training.startDate" />
            </Table.Td>

            <Table.Td>{getFormattedDateWithTime(training.created_at)}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.completedDate" />
            </Table.Td>

            <Table.Td>
              {training.done_at
                ? getFormattedDateWithTime(training.done_at)
                : '-'}
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.status" />
            </Table.Td>

            <Table.Td>
              <Group wrap="nowrap" align="center" gap="xl">
                <TrainingStatusIndicator status={training.status} />

                {training.status === TrainingStatus.Failed &&
                  canCreateTraining && (
                    <Button
                      miw={100}
                      variant="outline"
                      size="xs"
                      onClick={onRetry}
                    >
                      <FormattedMessage id="training.retry" />
                    </Button>
                  )}
              </Group>

              {training.error_body && (
                <Text size="xs" mt="md" c="red">
                  {training.error_body}
                </Text>
              )}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Table
        verticalSpacing="md"
        horizontalSpacing="md"
        withColumnBorders
        withTableBorder
      >
        <Table.Thead bg="gray.0">
          <Table.Tr>
            <Table.Th colSpan={2}>
              <FormattedMessage id="training.params" />
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          <Table.Tr>
            <Table.Td fw="bold" w="50%">
              <FormattedMessage id="training.params.frozenLayers" />
            </Table.Td>

            <Table.Td>{training.frozen_layers}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.params.patience" />
            </Table.Td>

            <Table.Td>{training.patience}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.params.epoch" />
            </Table.Td>

            <Table.Td>{training.epoch}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.params.batchSize" />
            </Table.Td>

            <Table.Td>{training.batch_size}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.params.inferenceResolution" />
            </Table.Td>

            <Table.Td>{training.resolution}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.params.classes" />
            </Table.Td>

            <Table.Td>
              <LabelGroup labels={labels} />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  )
}
