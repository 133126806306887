import { Navigate, Outlet, useLocation } from 'react-router'
import { useSession } from '@/session/SessionContext'
import { RouterPath } from './paths'

type LocationState = {
  redirectTo: string
} | null

export const PublicAuthRoute = () => {
  const { isAuthenticated } = useSession()
  const location = useLocation()
  const state = location.state as LocationState

  if (isAuthenticated) {
    return <Navigate to={state?.redirectTo || RouterPath.root} replace />
  }

  return <Outlet />
}
