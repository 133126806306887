import {
  ActionIcon,
  Anchor,
  Card,
  Center,
  HoverCard,
  Image,
  Menu,
  Stack,
  Text,
  ThemeIcon,
  rem
} from '@mantine/core'
import {
  IconDotsVertical,
  IconInfoCircle,
  IconPencil,
  IconTrash
} from '@tabler/icons-react'
import { MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import VpLogoSrc from '@/assets/vp-stripes.png'
import { AnimatedCard } from '@/components/ui-shared/AnimatedCard/AnimatedCard'
import { ModelStatus } from '../ModelStatus/ModelStatus'

type ModelCardProps = {
  modelName: string
  modelDetailsLink: string
  modelStatus: number
  logoUrl?: string
  showInfoOnHover?: boolean
  infoComponent?: React.ReactNode
  showActions?: boolean
  showEdit?: boolean
  showDelete?: boolean
  onRename: () => void
  onDelete: () => void
}

export const ModelCard = ({
  modelName,
  modelStatus,
  modelDetailsLink,
  logoUrl,
  showInfoOnHover,
  infoComponent,
  showActions,
  showEdit,
  showDelete,
  onRename,
  onDelete
}: ModelCardProps) => {
  const handleRename = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onRename()
  }

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onDelete()
  }

  return (
    <Anchor component={Link} to={modelDetailsLink} underline="never">
      <AnimatedCard h={175}>
        <Card.Section pos="relative" withBorder>
          <Center h={100} bg="gray.0">
            <Image src={logoUrl || VpLogoSrc} h={32} w="auto" />
          </Center>

          {showInfoOnHover && (
            <HoverCard width={350} shadow="lg" position="bottom-start">
              <HoverCard.Target>
                <ThemeIcon
                  pos="absolute"
                  top={4}
                  right={4}
                  size="sm"
                  variant="subtle"
                  radius="xl"
                  color="brand-primary"
                >
                  <IconInfoCircle style={{ width: '90%', height: '90%' }} />
                </ThemeIcon>
              </HoverCard.Target>

              <HoverCard.Dropdown>{infoComponent}</HoverCard.Dropdown>
            </HoverCard>
          )}
        </Card.Section>

        <Card.Section pos="relative" py="md" inheritPadding>
          <Stack align="center" gap={4}>
            <Text w="100%" ta="center" size="sm" fw="bold" pr={4} truncate>
              {modelName}
            </Text>

            {modelStatus && <ModelStatus status={modelStatus} />}
          </Stack>

          {showActions && (
            <Menu position="bottom-start" width={200}>
              <Menu.Target>
                <ActionIcon
                  pos="absolute"
                  top={2}
                  right={2}
                  variant="subtle"
                  size="sm"
                  radius="xl"
                  onClick={(event) => event.preventDefault()}
                >
                  <IconDotsVertical style={{ width: '80%', height: '80%' }} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {showEdit && (
                  <Menu.Item
                    leftSection={
                      <IconPencil style={{ width: rem(14), height: rem(14) }} />
                    }
                    onClick={handleRename}
                  >
                    <FormattedMessage id="rename" />
                  </Menu.Item>
                )}

                {showDelete && (
                  <Menu.Item
                    color="red"
                    leftSection={
                      <IconTrash style={{ width: rem(14), height: rem(14) }} />
                    }
                    onClick={handleDelete}
                  >
                    <FormattedMessage id="delete" />
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          )}
        </Card.Section>
      </AnimatedCard>
    </Anchor>
  )
}
