import { Card, Flex } from '@mantine/core'
import { ReactNode } from 'react'

type StepSectionProps = {
  children: ReactNode
  actions: ReactNode
}

export const StepSection = ({ children, actions }: StepSectionProps) => {
  return (
    <>
      <Card bg="gray.1" px={40} py={32} mih={400} mb="md">
        {children}
      </Card>

      <Flex justify="center">{actions}</Flex>
    </>
  )
}
