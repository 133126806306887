import { Box, LoadingOverlay } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useValidateToken } from '@/hooks/useValidateToken'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { LinkExpiredHandler } from './LinkExpiredHandler'
import { SetNewPasswordHandler } from './SetNewPasswordHandler'

export const SetNewPasswordPage = () => {
  const { isTokenValid, token, uid, email, isPending } = useValidateToken()

  if (isPending) {
    return (
      <Box pos="relative" mih={200}>
        <LoadingOverlay visible={isPending} />
      </Box>
    )
  }

  if (isTokenValid && email && token && uid) {
    return <SetNewPasswordHandler email={email} token={token} uid={uid} />
  }

  if (!isTokenValid && email) {
    return <LinkExpiredHandler email={email} />
  }

  return (
    <Box m="20px auto" maw={640}>
      <PlatformAlert variant="error" compact>
        <FormattedMessage id="passwordReset.genericTokenError" />
      </PlatformAlert>
    </Box>
  )
}
