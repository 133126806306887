import { Box, Card, LoadingOverlay, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { useUpdateModel } from '@/queries/modelQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { BaseModelListToolbar } from './BaseModelSelection/BaseModelListToolbar/BaseModelListToolbar'
import { MasterModelList } from './BaseModelSelection/MasterModelList/MasterModelList'
import { PreTrainedModelList } from './BaseModelSelection/PreTrainedModelList/PreTrainedModelList'
import { InferenceModal } from './InferenceModal/InferenceModal'
import { useModelStepNavigation } from './ModelDetails/useModelStepNavigation'

export const BaseModelSelectionPage = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()
  const intl = useIntl()
  const navigate = useNavigate()

  const { goToDatasetSelectionScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const { mutateAsync: updateModel, isPending } = useUpdateModel(
    model?.id || ''
  )

  const baseModel = model?.base_model || model?.root_model || null

  const [selectedModelId, setSelectedModelId] = useState<string>(
    baseModel?.id || ''
  )

  const [
    isInferenceModalOpened,
    { open: openInferenceModal, close: closeInferenceModal }
  ] = useDisclosure(false)

  const handleTrainModel = async () => {
    try {
      await updateModel({
        modelId: model?.id || '',
        data: {
          base_model_id: selectedModelId
        }
      })

      void goToDatasetSelectionScreen()
    } catch {
      showToast(
        intl.formatMessage({ id: 'models.updateModelApiError' }),
        'error'
      )
    }
  }

  const handleSkipTraining = async () => {
    try {
      await updateModel({
        modelId: model?.id || '',
        data: {
          base_model_id: selectedModelId
        }
      })

      void navigate(
        buildAppLink(application?.id || '', ApplicationNestedPath.models)
      )
    } catch {
      showToast(
        intl.formatMessage({ id: 'models.updateModelApiError' }),
        'error'
      )
    }
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <BaseModelListToolbar
          buttonsDisabled={!selectedModelId}
          onTestModel={openInferenceModal}
          onSkipTraining={() => void handleSkipTraining()}
          onTrainModel={() => void handleTrainModel()}
        />
      }
    >
      <Box pos="relative" pt="md">
        <LoadingOverlay visible={isPending} />

        {selectedModelId && (
          <PlatformAlert variant="info" mb="xl">
            <FormattedMessage id="models.test.hint" />
          </PlatformAlert>
        )}

        <Stack mb={48}>
          <Text size="md" fw="bold">
            <FormattedMessage id="models.test.startFromScratch" />
          </Text>

          <Card bg="gray.0" padding="lg">
            <MasterModelList
              selectedModelId={selectedModelId}
              onSelectionChange={setSelectedModelId}
            />
          </Card>
        </Stack>

        <Stack mb={48}>
          <Text size="md" fw="bold">
            <FormattedMessage id="models.test.preTrained" />
          </Text>

          <Card bg="gray.0" padding="lg">
            <PreTrainedModelList
              selectedModelId={selectedModelId}
              onSelectionChange={setSelectedModelId}
            />
          </Card>
        </Stack>
      </Box>

      <InferenceModal
        applicationId={application?.id || ''}
        modelId={selectedModelId}
        opened={isInferenceModalOpened}
        onClose={closeInferenceModal}
      />
    </PageWithFixedToolbar>
  )
}
