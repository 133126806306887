import { Button, Group, List, NumberInput, Text } from '@mantine/core'
import { isInRange, useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'

export type FormValues = {
  labelFps: number
}

type VideoFpsFormProps = {
  originalFps: number
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
}

export const VideoFpsForm = ({
  originalFps,
  onCancel,
  onSubmit
}: VideoFpsFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      labelFps: 1
    },

    validate: {
      labelFps: isInRange(
        { min: 1, max: originalFps },
        intl.formatMessage(
          { id: 'annotation.fpsValidation' },
          { min: 1, max: originalFps }
        )
      )
    },

    validateInputOnBlur: true
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <PlatformAlert variant="info" mb="lg" showIcon={false} compact>
        <Text size="xs" mb="md">
          <FormattedMessage id="annotation.chooseFpsHint" />
        </Text>

        <List size="xs" spacing={4} withPadding>
          <List.Item>
            <FormattedMessage id="annotation.chooseFpsHint.hint1" />
          </List.Item>

          <List.Item>
            <FormattedMessage id="annotation.chooseFpsHint.hint2" />
          </List.Item>
        </List>
      </PlatformAlert>

      <PlatformAlert variant="info" showIcon={false} mb="lg" compact>
        <Text size="xs" fw="bold">
          <FormattedMessage
            id="annotation.originalFpsValue"
            values={{ value: originalFps }}
          />
        </Text>
      </PlatformAlert>

      <NumberInput
        label={<FormattedMessage id="annotation.labelFps" />}
        mb="xl"
        allowNegative={false}
        allowDecimal={false}
        autoFocus
        data-autofocus // required for auto focus inside the modal
        {...form.getInputProps('labelFps')}
      />

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button miw={120} type="submit">
          <FormattedMessage id="continue" />
        </Button>
      </Group>
    </form>
  )
}
