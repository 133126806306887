import { Alert, AlertProps, rem } from '@mantine/core'
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle
} from '@tabler/icons-react'
import { PropsWithChildren, ReactNode, cloneElement } from 'react'

type AlertVariant = 'error' | 'warning' | 'info' | 'success'

const variantToAttributes = {
  error: { color: 'red', icon: <IconAlertTriangle /> },
  warning: { color: 'orange', icon: <IconAlertTriangle /> },
  info: { color: 'blue', icon: <IconInfoCircle /> },
  success: { color: 'green', icon: <IconCircleCheck /> }
}

type PlatformAlertProps = PropsWithChildren<
  AlertProps & {
    variant: AlertVariant
    title?: string | ReactNode
    showIcon?: boolean
    compact?: boolean
  }
>

export const PlatformAlert = ({
  variant,
  children,
  title,
  showIcon = true,
  compact = false,
  ...alertProps
}: PlatformAlertProps) => {
  return (
    <Alert
      variant="light"
      title={title}
      color={variantToAttributes[variant].color}
      icon={
        showIcon
          ? cloneElement(variantToAttributes[variant].icon, {
              size: compact ? 16 : 24
            })
          : undefined
      }
      {...alertProps}
      styles={{
        message: {
          fontSize: compact ? rem(12) : rem(14),
          marginTop: compact && !title ? rem(2) : undefined // adjust alignment when no title is present in compact mode
        },
        ...alertProps.styles
      }}
    >
      {children}
    </Alert>
  )
}
