import { Button, Card, Group, Stack, ThemeIcon } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconArrowRight } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TimePicker } from '@/components/ui-shared/TimePicker/TimePicker'
import { WeekdayValue } from '@/types/notificationSettings'
import { isEndTimeAfterStartTime } from '@/utils/validation/time'
import { WeekdaySelector } from '../WeekdaySelector/WeekdaySelector'
import { ScheduleInfoBox } from './ScheduleInfoBox'

export type ScheduleFormValues = {
  startTime: string
  endTime: string
  selectedDays: WeekdayValue[]
}

type ScheduleFormProps = {
  initialValues?: ScheduleFormValues
  onCancel: () => void
  onSave: (values: ScheduleFormValues) => void
}

export const ScheduleForm = ({
  initialValues,
  onCancel,
  onSave
}: ScheduleFormProps) => {
  const intl = useIntl()

  const form = useForm<ScheduleFormValues>({
    initialValues: initialValues || {
      startTime: '00:00',
      endTime: '23:59',
      selectedDays: []
    },
    validate: {
      startTime: (value, values) => {
        if (!value) {
          return intl.formatMessage({
            id: 'deployments.notificationSettings.schedule.timeRange.error.startRequired'
          })
        }

        if (values.endTime && !isEndTimeAfterStartTime(value, values.endTime)) {
          return intl.formatMessage({
            id: 'deployments.notificationSettings.schedule.timeRange.error.startBeforeEnd'
          })
        }

        return null
      },
      endTime: (value) => {
        if (!value) {
          return intl.formatMessage({
            id: 'deployments.notificationSettings.schedule.timeRange.error.endRequired'
          })
        }

        return null
      },
      selectedDays: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({
            id: 'deployments.notificationSettings.schedule.weekday.error.required'
          })
        }

        return null
      }
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit(onSave)}>
      <Stack gap="lg">
        <ScheduleInfoBox />

        <Card bg="gray.1">
          <Group gap="md" wrap="nowrap" align="start">
            <TimePicker
              {...form.getInputProps('startTime')}
              label={
                <FormattedMessage id="deployments.notificationSettings.schedule.timeRange.start" />
              }
              size="md"
            />

            <ThemeIcon
              variant="transparent"
              color="brand-primary"
              size="md"
              mt={32}
            >
              <IconArrowRight style={{ width: '100%', height: '100%' }} />
            </ThemeIcon>

            <TimePicker
              {...form.getInputProps('endTime')}
              label={
                <FormattedMessage id="deployments.notificationSettings.schedule.timeRange.end" />
              }
              size="md"
            />
          </Group>
        </Card>

        <WeekdaySelector {...form.getInputProps('selectedDays')} />
      </Stack>

      <Group justify="end" mt={60}>
        <Button miw={120} size="xs" variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button miw={120} size="xs" type="submit">
          <FormattedMessage id="save" />
        </Button>
      </Group>
    </form>
  )
}
