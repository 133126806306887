import { useMutation, useQuery } from '@tanstack/react-query'
import { generalApi } from '../api/generalApi'

export const generalQueryKeys = {
  all: ['general'] as const,
  pollTaskStatus: (taskId: string) =>
    [...generalQueryKeys.all, 'pollTaskStatus', taskId] as const
}

export const usePollTaskStatus = (taskId: string, enabled: boolean) => {
  return useQuery({
    queryKey: generalQueryKeys.pollTaskStatus(taskId),
    queryFn: () => generalApi.getCeleryTaskStatus(taskId),
    refetchInterval: (query) => {
      const { data } = query.state
      return data?.task_status === 'SUCCESS' || data?.task_status === 'FAILURE'
        ? false
        : 3000
    },
    enabled
  })
}

export const useTriggerServerException = () => {
  return useMutation({
    mutationFn: generalApi.triggerServerException
  })
}
