import { Drawer } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type NotificationSettingsDrawerProps = {
  opened: boolean
  onClose: () => void
  children: React.ReactNode
}

export const NotificationSettingsDrawer = ({
  opened,
  onClose,
  children
}: NotificationSettingsDrawerProps) => {
  return (
    <Drawer
      opened={opened}
      size="xl"
      position="right"
      title={<FormattedMessage id="deployments.notificationSettings.title" />}
      onClose={onClose}
    >
      {children}
    </Drawer>
  )
}
