import {
  Checkbox,
  Combobox,
  Divider,
  Group,
  Input,
  Loader,
  MultiSelectProps,
  Pill,
  PillsInput,
  Stack,
  Text,
  useCombobox
} from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetWorkspaceMembers } from '@/queries/workspaceQueries'
import { WorkspaceMemberStatus } from '@/types/workspace'
import { MemberRoleBadge } from '../WorkspaceMembers/MemberRoleBadge'

type WorkspaceUsersDropdownProps = Omit<MultiSelectProps, 'placeholder'> & {
  workspaceId: string
  placeholder?: string | ReactNode
  fallbackName?: string | ReactNode
}

export const WorkspaceUsersDropdown = ({
  value = [],
  label,
  placeholder,
  disabled,
  error,
  workspaceId,
  fallbackName,
  onChange
}: WorkspaceUsersDropdownProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  })

  const { data, isFetching } = useGetWorkspaceMembers({
    pagination: { limit: 9999 },
    filter: {
      status: WorkspaceMemberStatus.Active
    },
    ordering: 'fullName',
    workspaceId
  })

  const users =
    data?.results.map((membership) => ({
      id: membership.id,
      email: membership.user.email,
      full_name: membership.user.full_name,
      role: membership.role
    })) || []

  const handleValueSelect = (val: string) => {
    if (val === '$selectAll') {
      const allValues = users.map((user) => user.id)
      onChange?.(value.length === allValues.length ? [] : allValues)
    } else {
      onChange?.(
        value.includes(val) ? value.filter((v) => v !== val) : [...value, val]
      )
    }
  }

  const handleValueRemove = (val: string) =>
    onChange?.(value.filter((v) => v !== val))

  const values = value.map((item) => (
    <Pill
      key={item}
      withRemoveButton={!disabled}
      styles={{
        root: {
          outline: '1px solid var(--mantine-color-gray-6)'
        }
      }}
      onRemove={() => handleValueRemove(item)}
    >
      {users.find((user) => user.id === item)?.email || fallbackName}
    </Pill>
  ))

  const options = users.map((item) => (
    <Combobox.Option
      key={item.id}
      value={item.id}
      active={value.includes(item.id)}
    >
      <Group align="start" gap="sm">
        <Checkbox
          size="xs"
          checked={value.includes(item.id)}
          tabIndex={-1}
          style={{ pointerEvents: 'none' }}
          aria-hidden
          onChange={() => {}}
        />

        <Stack gap={2}>
          <Group wrap="nowrap" align="center" gap="sm">
            <Text size="xs" fw={600} fz={13}>
              {item.full_name}
            </Text>

            <MemberRoleBadge role={item.role} size="xs" />
          </Group>

          <Text size="xs" c="dimmed">
            {item.email}
          </Text>
        </Stack>
      </Group>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      disabled={disabled}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          disabled={disabled}
          error={error}
          rightSection={isFetching && <Loader size="xs" />}
          pointer
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group disabled={disabled}>
            {values.length > 0 ? (
              values
            ) : (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault()
                    handleValueRemove(value[value.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={420} style={{ overflowY: 'auto' }}>
          {options.length > 1 && (
            <>
              <Combobox.Option value="$selectAll">
                <Group gap="sm">
                  <Checkbox
                    size="xs"
                    checked={values.length === options.length}
                    tabIndex={-1}
                    style={{ pointerEvents: 'none' }}
                    aria-hidden
                    onChange={() => {}}
                  />

                  <Text fw={600} size="xs">
                    <FormattedMessage id="selectAll" />
                  </Text>
                </Group>
              </Combobox.Option>

              <Divider mt={4} mb={4} />
            </>
          )}

          {options}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
