import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { getApiError } from '@/api/helpers/apiError'
import { useCreateApplication } from '@/queries/applicationQueries'
import { RouterPath, replacePathParams } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import {
  CreateApplicationForm,
  FormValues
} from './CreateApplicationForm/CreateApplicationForm'

type CreateApplicationModalProps = {
  opened: boolean
  onClose: () => void
  onOpenChatbot: () => void
}

export const CreateApplicationModal = ({
  opened,
  onClose,
  onOpenChatbot
}: CreateApplicationModalProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { mutateAsync: createApp, isPending } = useCreateApplication()

  const handleCreateApp = async ({ appName, appType }: FormValues) => {
    try {
      const { data: application } = await createApp({
        name: appName,
        application_type: appType
      })

      showToast(intl.formatMessage({ id: 'apps.create.success' }), 'success')

      onClose()

      void navigate(
        replacePathParams(RouterPath.appDetails, {
          appId: application.id
        })
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'apps.create.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="xl"
      opened={opened}
      title={<FormattedMessage id="apps.create" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <CreateApplicationForm
          onChatbotClick={onOpenChatbot}
          onCancel={onClose}
          onSubmit={(values) => void handleCreateApp(values)}
        />
      </Box>
    </Modal>
  )
}
