import { Group } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'

type TrainingCompletedBannerProps = {
  rightSection?: ReactNode
}

export const TrainingCompletedBanner = ({
  rightSection
}: TrainingCompletedBannerProps) => {
  return (
    <PlatformAlert
      variant="success"
      title={<FormattedMessage id="training.status.completed" />}
    >
      <Group justify="space-between" align="center">
        <FormattedMessage id="training.completedDescription" />

        {rightSection}
      </Group>
    </PlatformAlert>
  )
}
