import { Chip, Group, Input } from '@mantine/core'
import { WeekdayValue } from '@/types/notificationSettings'
import { getDayOfWeekName } from '@/utils/date'

type WeekdaySelectorProps = {
  value?: WeekdayValue[]
  error?: string
  onChange: (value: WeekdayValue[]) => void
}

export const WeekdaySelector = ({
  value = [],
  error,
  onChange
}: WeekdaySelectorProps) => {
  const handleDaysChange = (value: string[]) => {
    onChange(value.map((day) => Number(day) as WeekdayValue))
  }

  return (
    <Input.Wrapper error={error}>
      <Chip.Group
        value={value.map((v) => String(v))}
        multiple
        onChange={handleDaysChange}
      >
        <Group gap="xs" mb={error ? 'xs' : 0}>
          {[1, 2, 3, 4, 5, 6, 7].map((value) => (
            <Chip key={value} size="xs" value={String(value)}>
              {getDayOfWeekName(value, 'short')}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </Input.Wrapper>
  )
}
