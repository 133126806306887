import {
  Button,
  Center,
  Group,
  Loader,
  ScrollArea,
  Space,
  Stack,
  Text
} from '@mantine/core'
import { IconCheck, IconRefresh, IconX } from '@tabler/icons-react'
import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { usePollCameraCreationStatus } from '@/queries/deviceQueries'
import { generateUUID } from '@/utils/uuid'
import { useImportContext } from '../../ImportContext'
import { ImportProgressBar } from '../ImportProgressBar/ImportProgressBar'
import { ImportStatusIndicator } from '../ImportStatusIndicator/ImportStatusIndicator'

type ImportProgressHandlerProps = {
  deviceId: string
  onBack: () => void
}

export const ImportProgressHandler = ({
  deviceId,
  onBack
}: ImportProgressHandlerProps) => {
  const { onImportCompleted, onClose } = useImportContext()
  const pollTaskId = useRef(generateUUID())

  const {
    data: cameraStatusData,
    isError,
    error,
    isLoading
  } = usePollCameraCreationStatus({
    deviceId,
    pollTaskId: pollTaskId.current,
    enabled: deviceId !== '' && pollTaskId.current !== ''
  })

  useEffect(() => {
    if (
      cameraStatusData?.status === 'Complete' ||
      cameraStatusData?.status === 'Failed'
    ) {
      onImportCompleted()
    }
    // do not add onImportCompleted to the dependencies array, otherwise it will cause an infinite loop
  }, [cameraStatusData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack>
      {isError && (
        <PlatformAlert variant="error" compact>
          {getApiError(error)?.errorMessage || (
            <FormattedMessage id="streams.import.error" />
          )}
        </PlatformAlert>
      )}

      {isLoading && (
        <Center>
          <Loader />
        </Center>
      )}

      {cameraStatusData && (
        <Stack>
          <Space h="xl" />

          <ImportStatusIndicator status={cameraStatusData.status} />

          <ImportProgressBar
            total={cameraStatusData.total_tasks}
            imported={cameraStatusData.completed_tasks}
          />

          <ScrollArea h={160}>
            {cameraStatusData.failed_rtsp_urls.length > 0 && (
              <Stack gap="xs">
                {cameraStatusData.failed_rtsp_urls.map((url) => (
                  <Group key={url} wrap="nowrap" gap="xs" align="start">
                    <IconX size={16} color="var(--mantine-color-red-8)" />

                    <Text size="xs">{url}</Text>
                  </Group>
                ))}
              </Stack>
            )}
          </ScrollArea>

          <Group justify="center">
            {cameraStatusData.status === 'Failed' && (
              <Button
                variant="outline"
                miw={160}
                leftSection={<IconRefresh size={16} />}
                onClick={onBack}
              >
                <FormattedMessage id="tryAgain" />
              </Button>
            )}

            <Button
              miw={160}
              leftSection={<IconCheck size={16} />}
              disabled={cameraStatusData.status === 'Pending'}
              onClick={onClose}
            >
              <FormattedMessage id="done" />
            </Button>
          </Group>
        </Stack>
      )}
    </Stack>
  )
}
