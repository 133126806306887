import { Button, Group, Text } from '@mantine/core'
import { IconClock } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type ScheduleHeaderProps = {
  onAddSchedule: () => void
}

export const ScheduleHeader = ({ onAddSchedule }: ScheduleHeaderProps) => (
  <Group gap="xs" justify="start">
    <Button
      size="xs"
      radius="xl"
      leftSection={<IconClock size={16} />}
      onClick={onAddSchedule}
    >
      <FormattedMessage id="deployments.notificationSettings.schedule.add" />
    </Button>

    <Text size="xs" fw={600}>
      <FormattedMessage id="deployments.notificationSettings.schedule.description" />
    </Text>
  </Group>
)
