import { Button, Group, Modal, SimpleGrid, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconAlertTriangle,
  IconChevronLeft,
  IconChevronRight,
  IconRefresh
} from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { DeviceSettingsHandler } from '@/components/devices/DeviceSettings/DeviceSettingsHandler'
import { DeviceSettingsOverlay } from '@/components/devices/DeviceSettings/DeviceSettingsOverlay'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useGetDevices } from '@/queries/deviceQueries'
import { CrudAction, Resource } from '@/types/permissions'
import { AddButtonCard } from '../../../ui-shared/AddButtonCard/AddButtonCard'
import { DeviceRegistrationSteps } from './DeviceRegistrationSteps/DeviceRegistrationSteps'
import { SelectableDeviceCard } from './SelectableDeviceCard/SelectableDeviceCard'

type DeviceSelectionProps = {
  selectedDeviceIds: string[]
  onSelectionChange: (deviceIds: string[]) => void
  onGoBack: () => void
  onContinue: () => void
}

export const DeviceSelection = ({
  selectedDeviceIds,
  onSelectionChange,
  onGoBack,
  onContinue
}: DeviceSelectionProps) => {
  const [
    addDeviceModalOpened,
    { open: openAddDeviceModal, close: closeAddDeviceModal }
  ] = useDisclosure(false)

  const [canCreateDevice] = useHasPermissions(Resource.Devices, [
    CrudAction.Create
  ])

  const [deviceSettingsId, setDeviceSettingsId] = useState('')

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetDevices()

  const devices = data?.pages.flatMap((page) => page.results) ?? []

  const isOnlineDeviceSelected = devices.some(
    (device) => selectedDeviceIds.includes(device.id) && device.active
  )

  const isOfflineDeviceSelected = devices.some(
    (device) => selectedDeviceIds.includes(device.id) && !device.active
  )

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleOnReload = () => {
    void refetch()
  }

  const handleModalRefresh = () => {
    void refetch()
    closeAddDeviceModal()
  }

  const toggleDeviceSelection = (deviceId: string) => {
    if (selectedDeviceIds.includes(deviceId)) {
      onSelectionChange(selectedDeviceIds.filter((id) => id !== deviceId))
    } else {
      onSelectionChange([...selectedDeviceIds, deviceId])
    }
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="devices.listApiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Group align="center" gap="lg" mb="md">
        <Title order={3}>
          <FormattedMessage id="deployments.step3" />
        </Title>

        <Button
          size="xs"
          variant="light"
          rightSection={<IconRefresh size={16} />}
          onClick={handleOnReload}
        >
          <FormattedMessage id="refresh" />
        </Button>
      </Group>

      <StepSection
        actions={
          <Group>
            <Button
              size="md"
              variant="outline"
              miw={200}
              leftSection={<IconChevronLeft size={16} stroke={3} />}
              onClick={onGoBack}
            >
              <FormattedMessage id="deployments.previousStep" />
            </Button>

            <Button
              miw={200}
              size="md"
              rightSection={<IconChevronRight size={16} stroke={3} />}
              disabled={!isOnlineDeviceSelected || isOfflineDeviceSelected}
              onClick={onContinue}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        {isOfflineDeviceSelected && (
          <Group gap="xs" wrap="nowrap" mb="sm">
            <IconAlertTriangle size={16} color="red" />

            <Text size="xs">
              <FormattedMessage id="devices.offlineDeviceSelected" />
            </Text>
          </Group>
        )}

        <InfiniteScroll
          hasMore={hasNextPage}
          isFetching={isFetching}
          onLoadMore={handleLoadMore}
        >
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
            {canCreateDevice && (
              <AddButtonCard
                height={175}
                label={<FormattedMessage id="devices.addDevice" />}
                onClick={openAddDeviceModal}
              />
            )}

            {devices.map((device) => (
              <SelectableDeviceCard
                key={device.id}
                name={device.label}
                isActive={device.active}
                isSelected={selectedDeviceIds.includes(device.id)}
                isSettingsShown={device.active && canCreateDevice}
                disabled={
                  !device.active && !selectedDeviceIds.includes(device.id)
                }
                onClick={() => toggleDeviceSelection(device.id)}
                onSettingsClick={() => setDeviceSettingsId(device.id)}
              />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      </StepSection>

      <Modal
        size="lg"
        title={<FormattedMessage id="devices.addDevice" />}
        opened={addDeviceModalOpened}
        onClose={closeAddDeviceModal}
      >
        <DeviceRegistrationSteps onRefresh={handleModalRefresh} />
      </Modal>

      <DeviceSettingsOverlay
        opened={deviceSettingsId !== ''}
        onClose={() => setDeviceSettingsId('')}
      >
        {deviceSettingsId && (
          <DeviceSettingsHandler deviceId={deviceSettingsId} />
        )}
      </DeviceSettingsOverlay>
    </>
  )
}
