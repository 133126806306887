import { useSearchParams } from 'react-router'

const QUERY_PARAM_KEY = 'eventId'

export const useEventIdParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setEventId = (eventId: string | null) => {
    if (eventId) {
      searchParams.set(QUERY_PARAM_KEY, eventId)
    } else {
      searchParams.delete(QUERY_PARAM_KEY)
    }

    setSearchParams(searchParams)
  }

  const getEventId = () => {
    return searchParams.get(QUERY_PARAM_KEY)
  }

  return [getEventId(), setEventId] as const
}
