import {
  MemberInvitation,
  Workspace,
  WorkspaceMember,
  WorkspaceMemberRole,
  WorkspaceType
} from '@/types/workspace'
import { vpApi } from './vpApi'

type WorkspacePayload = {
  workspace_type: WorkspaceType
  name: string
}

const createWorkspace = async (payload: WorkspacePayload) => {
  const res = await vpApi.post<Workspace>('/v1/workspaces/', payload)
  return res.data
}

const getUserWorkspace = async (workspaceId: string) => {
  const res = await vpApi.get<Workspace>(`/v1/workspaces/${workspaceId}/`)
  return res.data
}

type UpdateWorkspaceParams = {
  workspaceId: string
  payload: WorkspacePayload
}

const updateWorkspace = async ({
  workspaceId,
  payload
}: UpdateWorkspaceParams) => {
  return vpApi.patch(`/v1/workspaces/${workspaceId}/`, payload)
}

type InviteMembersParams = {
  workspaceId: string
  members: MemberInvitation[]
}

const inviteMembers = async ({ workspaceId, members }: InviteMembersParams) => {
  return vpApi.post(`/v1/workspaces/${workspaceId}/invite/`, members)
}

type UpdateMemberParams = {
  workspaceId: string
  memberId: string
  role: WorkspaceMemberRole
}

const updateWorkspaceMember = ({
  workspaceId,
  memberId,
  role
}: UpdateMemberParams) => {
  return vpApi.patch(`/v1/workspaces/${workspaceId}/members/${memberId}/`, {
    role
  })
}

type ResendInviteParams = {
  workspaceId: string
  memberId: string
}

const resendInvite = ({ workspaceId, memberId }: ResendInviteParams) => {
  return vpApi.post(
    `/v1/workspaces/${workspaceId}/members/${memberId}/resend-invite/`
  )
}

type DeactivateMemberParams = {
  workspaceId: string
  memberId: string
}

const deactivateWorkspaceMember = async ({
  workspaceId,
  memberId
}: DeactivateMemberParams) => {
  return await vpApi.post(
    `/v1/workspaces/${workspaceId}/members/${memberId}/deactivate/`
  )
}

type AcceptWorkspaceInvitationParams = {
  workspaceId: string
  token: string
}

const acceptWorkspaceInvitation = ({
  workspaceId,
  token
}: AcceptWorkspaceInvitationParams) => {
  return vpApi.post(`/v1/workspaces/${workspaceId}/accept-invite/`, { token })
}

const getMyMembership = async (workspaceId: string) => {
  const res = await vpApi.get<WorkspaceMember>(
    `/v1/workspaces/${workspaceId}/my-membership/`
  )
  return res.data
}

export const workspaceApi = {
  createWorkspace,
  getUserWorkspace,
  updateWorkspace,
  inviteMembers,
  updateWorkspaceMember,
  resendInvite,
  deactivateWorkspaceMember,
  acceptWorkspaceInvitation,
  getMyMembership
}
