import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Menu,
  Spoiler,
  Table,
  Text,
  Tooltip,
  rem
} from '@mantine/core'
import {
  IconDotsVertical,
  IconPlayerPlayFilled,
  IconPlayerStopFilled,
  IconSettings
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { DeploymentStatus } from '@/types/deployment'
import { CrudAction, Resource } from '@/types/permissions'
import { OnlineStatus } from '../../OnlineStatus/OnlineStatus'
import { DeploymentStatusIndicator } from '../DeploymentStatusIndicator/DeploymentStatusIndicator'

type LineValues = {
  deploymentId: string
  deviceId: string
  deviceName: string
  isDeviceOnline: boolean
  streamId: string
  streamName: string
  isStreamOnline: boolean
  modelName: string
  logicNames: string[]
  deploymentStatus: DeploymentStatus
  deploymentError: string
}

type DeploymentsTableProps = {
  lines: LineValues[]
  onStopDeployment: (deploymentId: string) => void
  onStartDeployment: (deploymentId: string) => void
  onEditBusinessLogic: () => void
  onDeviceSettingsClick: (deviceId: string) => void
}

export const DeploymentsTable = ({
  lines,
  onStopDeployment,
  onStartDeployment,
  onEditBusinessLogic,
  onDeviceSettingsClick
}: DeploymentsTableProps) => {
  const [canUpdateDeployment] = useHasPermissions(Resource.Deployments, [
    CrudAction.Update
  ])

  const [canCreateDevice] = useHasPermissions(Resource.Devices, [
    CrudAction.Create
  ])

  const rows = lines.map((line) => (
    <Table.Tr key={line.deploymentId}>
      <Table.Td>
        <Group align="baseline" gap={6} wrap="nowrap">
          <OnlineStatus
            status={line.isDeviceOnline ? 'online' : 'offline'}
            withText={false}
          />

          <span>{line.deviceName}</span>

          {!line.isDeviceOnline && (
            <Text size="xs" c="dimmed">
              (<FormattedMessage id="devices.status.offline" />)
            </Text>
          )}

          {line.isDeviceOnline && canCreateDevice && (
            <Tooltip label={<FormattedMessage id="devices.settings" />}>
              <ActionIcon
                variant="subtle"
                size="xs"
                onClick={() => onDeviceSettingsClick(line.deviceId)}
              >
                <IconSettings style={{ width: '80%', height: '80%' }} />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      </Table.Td>

      <Table.Td>
        <Group align="baseline" gap={6} wrap="nowrap">
          <OnlineStatus
            status={line.isStreamOnline ? 'online' : 'offline'}
            withText={false}
          />

          <span>{line.streamName}</span>

          {!line.isStreamOnline && (
            <Text size="xs" c="dimmed">
              (<FormattedMessage id="devices.status.offline" />)
            </Text>
          )}
        </Group>
      </Table.Td>

      <Table.Td>
        <span>{line.modelName}</span>
      </Table.Td>

      <Table.Td>
        {line.logicNames.map((name) => (
          <Badge key={name} variant="outline" size="xs" mr="xs">
            {name}
          </Badge>
        ))}
      </Table.Td>

      <Table.Td maw={240}>
        <DeploymentStatusIndicator status={line.deploymentStatus} />

        {line.deploymentError !== '' && (
          <Spoiler
            maxHeight={48}
            mt="sm"
            showLabel={
              <Text size="xs">
                <FormattedMessage id="showMore" />
              </Text>
            }
            hideLabel={
              <Text size="xs">
                <FormattedMessage id="showLess" />
              </Text>
            }
          >
            <Text size="xs" c="red">
              {line.deploymentError}
            </Text>
          </Spoiler>
        )}
      </Table.Td>

      <Table.Td align="right">
        {canUpdateDeployment && (
          <Menu width={180}>
            <Menu.Target>
              <Button
                size="xs"
                variant="outline"
                rightSection={<IconDotsVertical size={16} />}
              >
                <FormattedMessage id="deployments.actions" />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconPlayerStopFilled
                    style={{ width: rem(14), height: rem(14) }}
                  />
                }
                disabled={
                  line.deploymentStatus === DeploymentStatus.InProgress ||
                  line.deploymentStatus === DeploymentStatus.Removing ||
                  line.deploymentStatus === DeploymentStatus.Stopped ||
                  line.isDeviceOnline === false
                }
                onClick={() => onStopDeployment(line.deploymentId)}
              >
                <Text size="xs" fw="bold">
                  <FormattedMessage id="deployments.stopApp" />
                </Text>
              </Menu.Item>

              <Menu.Item
                leftSection={
                  <IconPlayerPlayFilled
                    style={{ width: rem(14), height: rem(14) }}
                  />
                }
                disabled={
                  line.deploymentStatus === DeploymentStatus.InProgress ||
                  line.deploymentStatus === DeploymentStatus.Removing ||
                  line.deploymentStatus === DeploymentStatus.Running ||
                  line.isDeviceOnline === false
                }
                onClick={() => onStartDeployment(line.deploymentId)}
              >
                <Text size="xs" fw="bold">
                  <FormattedMessage id="deployments.startApp" />
                </Text>
              </Menu.Item>

              <Menu.Item
                leftSection={
                  <IconSettings style={{ width: rem(14), height: rem(14) }} />
                }
                disabled={
                  line.deploymentStatus === DeploymentStatus.InProgress ||
                  line.deploymentStatus === DeploymentStatus.Removing
                }
                onClick={onEditBusinessLogic}
              >
                <Text size="xs" fw="bold">
                  <FormattedMessage id="deployments.editBusinessLogic" />
                </Text>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <Table verticalSpacing="sm" horizontalSpacing="md">
      <Table.Thead bg="gray.1">
        <Table.Tr>
          <Table.Th>
            <FormattedMessage id="deployments.table.device" />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="deployments.table.cameraStream" />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="deployments.table.model" />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="deployments.table.businessLogic" />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="deployments.status" />
          </Table.Th>

          <Table.Th>&nbsp;</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  )
}
