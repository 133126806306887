import { Box, LoadingOverlay } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useCreateCameraStreams,
  usePollCameraCreationStatus
} from '@/queries/deviceQueries'
import { showToast } from '@/theme/notifications'
import { CameraSource, Device } from '@/types/device'
import { generateUUID } from '@/utils/uuid'
import { AddStreamForm, FormValues } from '../AddStreamForm/AddStreamForm'

type AddStreamHandlerProps = {
  devices: Device[]
  onCancel: () => void
  onStreamCreated: () => void
}

export const AddStreamHandler = ({
  devices,
  onCancel,
  onStreamCreated
}: AddStreamHandlerProps) => {
  const intl = useIntl()
  const [deviceId, setDeviceId] = useState('')
  const [pollTaskId, setPollTaskId] = useState('')

  const { mutateAsync: createCameraStream, isPending } =
    useCreateCameraStreams()

  const {
    data: cameraStatusData,
    isError: isStatusError,
    isLoading: isLoadingStatus
  } = usePollCameraCreationStatus({
    deviceId,
    pollTaskId,
    enabled: deviceId !== '' && pollTaskId !== ''
  })

  useEffect(() => {
    if (cameraStatusData?.status === 'Complete') {
      setPollTaskId('')
      showToast(intl.formatMessage({ id: 'streams.streamAdded' }), 'success')
      onStreamCreated()
    }

    if (isStatusError || cameraStatusData?.status === 'Failed') {
      setPollTaskId('')

      showToast(
        intl.formatMessage({ id: 'streams.streamCreateError' }),
        'error'
      )
    }
  }, [isStatusError, cameraStatusData?.status, intl, onStreamCreated])

  const isLoading =
    isPending || isLoadingStatus || cameraStatusData?.status === 'Pending'

  const deviceOptions = devices.map((device) => ({
    value: device.id,
    label: device.label
  }))

  const handleStreamCreate = async (values: FormValues) => {
    try {
      await createCameraStream({
        deviceId: values.deviceId,
        data: [
          {
            camera_name: values.streamName,
            rtsp_url: values.rtspUrl,
            source: CameraSource.Visionplatform
          }
        ]
      })

      setDeviceId(values.deviceId)
      setPollTaskId(generateUUID())
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'streams.streamCreateError' })
      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} />

      <AddStreamForm
        deviceId={deviceOptions[0]?.value || ''}
        deviceOptions={deviceOptions}
        onCancel={onCancel}
        onSubmit={(values) => void handleStreamCreate(values)}
      />
    </Box>
  )
}
