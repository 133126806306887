import { Badge } from '@mantine/core'
import { useIntl } from 'react-intl'
import { WorkspaceMemberStatus } from '@/types/workspace'

const statusConfig = {
  [WorkspaceMemberStatus.Active]: { id: 'active', color: 'green' },
  [WorkspaceMemberStatus.Inactive]: { id: 'inactive', color: 'gray' },
  [WorkspaceMemberStatus.Pending]: { id: 'pending', color: 'orange' }
}

type MemberStatusBadgeType = {
  status: WorkspaceMemberStatus
}

export const MemberStatusBadge = ({ status }: MemberStatusBadgeType) => {
  const intl = useIntl()

  return (
    <Badge
      color={statusConfig[status].color}
      variant="dot"
      size="sm"
      radius="sm"
    >
      {intl.formatMessage({
        id: `workspace.members.status.${statusConfig[status].id}`
      })}
    </Badge>
  )
}
