import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useResendMembershipInvite } from '@/queries/workspaceQueries'
import { showToast } from '@/theme/notifications'
import { WorkspaceMember } from '@/types/workspace'

type ResendInviteModalProps = {
  opened: boolean
  member: WorkspaceMember | null
  workspaceName: string
  workspaceId: string
  onClose: () => void
}

export const ResendInviteModal = ({
  opened,
  member,
  workspaceName,
  workspaceId,
  onClose
}: ResendInviteModalProps) => {
  const intl = useIntl()

  const { mutateAsync: resendInvite, isPending } = useResendMembershipInvite()

  const handleOnResendInvite = async () => {
    try {
      await resendInvite({ workspaceId, memberId: member?.id || '' })

      showToast(
        intl.formatMessage(
          {
            id: 'workspace.members.invite.resend.success'
          },
          {
            email: member?.user.email,
            workspaceName
          }
        ),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage(
          { id: 'workspace.members.invite.resend.error' },
          {
            email: member?.user.email
          }
        )

      showToast(message, 'error')
    }

    onClose()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="workspace.members.invite.resend" />}
      mah={100}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {member && (
          <>
            <Text size="sm" mb="lg">
              <FormattedMessage
                id="workspace.members.invite.resend.confirmation"
                values={{
                  email: <strong>{member.user.email}</strong>,
                  workspaceName: <strong>{workspaceName}</strong>
                }}
              />
            </Text>

            <Group justify="end">
              <Button miw={120} variant="default" onClick={onClose}>
                <FormattedMessage id="cancel" />
              </Button>

              <Button onClick={() => void handleOnResendInvite()}>
                <FormattedMessage id="workspace.members.invite.resend" />
              </Button>
            </Group>
          </>
        )}
      </Box>
    </Modal>
  )
}
