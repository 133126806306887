import { Box, Card, Center, Flex, Overlay, Text, Title } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useCreateCustomerPortalSession } from '@/queries/accountQueries'
import { useSession } from '@/session/SessionContext'
import { showToast } from '@/theme/notifications'
import { CrudAction, Resource } from '@/types/permissions'
import { getBillingPeriod } from '@/utils/subscription'
import { AddonList } from './AddonList/AddonList'
import { UserSubscriptionInfo } from './UserSubscriptionInfo/UserSubscriptionInfo'

export const MySubscriptionPage = () => {
  const intl = useIntl()
  const { userSubscription: subscription, subscriptionAddons } = useSession()

  const { mutateAsync, isPending } = useCreateCustomerPortalSession()

  const [canUpdateSubscription] = useHasPermissions(Resource.Subscriptions, [
    CrudAction.Update
  ])

  const billingPeriod = subscription && getBillingPeriod(subscription.freq)

  const handleManageSubscriptionClick = async () => {
    try {
      const session = await mutateAsync()
      window.location.href = session.url
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'subscriptions.createPortalSessionError' })

      showToast(message, 'error')
    }
  }

  return (
    <>
      <Flex justify="center">
        <Box w="100%" maw={1000}>
          <Title order={3} mb="md">
            <FormattedMessage id="subscriptions.mySubscription" />
          </Title>

          {subscription && billingPeriod && (
            <UserSubscriptionInfo
              name={subscription.product_name}
              billingPeriod={billingPeriod}
              price={subscription.price}
              currency={subscription.price_currency}
              status={subscription.subscription_status}
              cancelledAt={subscription.cancel_at || undefined}
              showManageButton={canUpdateSubscription}
              onManageClick={() => void handleManageSubscriptionClick()}
            />
          )}

          {subscriptionAddons.length > 0 && (
            <Box mt="xl">
              <Title order={4} mb="sm">
                <FormattedMessage id="subscriptions.addons" />
              </Title>

              <Card withBorder>
                <AddonList addons={subscriptionAddons} />
              </Card>
            </Box>
          )}
        </Box>
      </Flex>

      {isPending && (
        <Overlay backgroundOpacity={0.7} fixed>
          <Center h="100%">
            <Text size="lg" c="gray.1">
              <FormattedMessage id="pleaseWait" />
            </Text>
          </Center>
        </Overlay>
      )}
    </>
  )
}
