import { ActionIcon, Menu, Space, Tooltip } from '@mantine/core'
import { IconBook, IconDownload } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import {
  JETSON_MANUAL_FILE,
  PLATFORM_NAME,
  USER_MANUAL_FILE
} from '@/config/main'

export const PlatformManuals = () => {
  return (
    <Menu
      styles={(theme) => ({
        label: {
          color: 'white',
          fontWeight: 600,
          fontSize: theme.fontSizes.xs,
          backgroundColor: theme.colors['brand-primary'][5]
        },
        itemLabel: {
          color: theme.colors['brand-primary'][5],
          fontWeight: 600,
          fontSize: theme.fontSizes.xs
        }
      })}
    >
      <Menu.Target>
        <Tooltip label={<FormattedMessage id="manuals.title" />}>
          <ActionIcon>
            <IconBook style={{ width: '80%', height: '80%' }} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <FormattedMessage id="manuals.title" />
        </Menu.Label>

        <Space h="xs" />

        <Menu.Item
          rightSection={<IconDownload size={16} />}
          component="a"
          target="_blank"
          href={USER_MANUAL_FILE}
        >
          <FormattedMessage
            id="manuals.user.download"
            values={{
              platform: PLATFORM_NAME
            }}
          />
        </Menu.Item>

        <Menu.Item
          rightSection={<IconDownload size={16} />}
          component="a"
          target="_blank"
          href={JETSON_MANUAL_FILE}
        >
          <FormattedMessage id="manuals.jetson.download" />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
