import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useInviteMembers } from '@/queries/workspaceQueries'
import { showToast } from '@/theme/notifications'
import { InviteMembersForm, SubmitValues } from './InviteMembersForm'

type InviteMembersModalProps = {
  workspaceId: string
  isOpen: boolean
  onClose: () => void
}

export const InviteMembersModal = ({
  workspaceId,
  isOpen,
  onClose
}: InviteMembersModalProps) => {
  const intl = useIntl()

  const { mutateAsync: inviteMembers, isPending } = useInviteMembers()

  const handleInviteMembers = async ({ members }: SubmitValues) => {
    try {
      await inviteMembers({ workspaceId, members })

      showToast(
        intl.formatMessage({ id: 'workspace.members.invite.success' }),
        'success'
      )

      onClose()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'workspace.members.invite.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      size="lg"
      opened={isOpen}
      title={<FormattedMessage id="workspace.members.invite" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <InviteMembersForm
          onSubmit={(values) => void handleInviteMembers(values)}
        />
      </Box>
    </Modal>
  )
}
