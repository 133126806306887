import { Badge, Button, Card, Group, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { Workspace, WorkspaceType } from '@/types/workspace'

type WorkspaceInfoProps = {
  workspace: Workspace
  showUpdateButton?: boolean
  onUpdateClick: () => void
}

export const WorkspaceInfo = ({
  workspace,
  showUpdateButton,
  onUpdateClick
}: WorkspaceInfoProps) => {
  const workspaceTypeStrId =
    workspace.workspace_type === WorkspaceType.Organization
      ? 'workspace.type.organization'
      : 'workspace.type.personal'

  return (
    <>
      <Card p="lg" withBorder>
        <Group justify="space-between">
          <Group wrap="nowrap">
            <Text fw="bold">{workspace?.name}</Text>

            <Badge size="xs" radius="xl" color="green">
              <FormattedMessage id={workspaceTypeStrId} />
            </Badge>
          </Group>

          {showUpdateButton && (
            <Button onClick={onUpdateClick}>
              <FormattedMessage id="workspace.update" />
            </Button>
          )}
        </Group>
      </Card>
    </>
  )
}
