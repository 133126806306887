import { PermissionsMap, Resource, ResourceAction } from '@/types/permissions'

/**
 * Normalizes an array of permission strings into a PermissionsMap object.
 *
 * Input:
 *   - permissions: An array of permission strings in the format "resource:action".
 *
 * Output:
 *   - A PermissionsMap object where each key is a resource, and the value is an array of actions for that resource.
 *
 * Example:
 *   const permissions = ['user:create', 'user:delete', 'post:read'];
 *   const normalizedPermissions = normalizeApiPermissions(permissions);
 *   // normalizedPermissions will be:
 *   // {
 *   //   user: ['create', 'delete'],
 *   //   post: ['read']
 *   // }
 */
export const normalizeApiPermissions = (
  permissions: string[]
): PermissionsMap => {
  return permissions.reduce((acc, permission) => {
    const [resource, action] = permission.split(':') as [
      Resource,
      ResourceAction
    ]

    if (!acc[resource]) {
      acc[resource] = []
    }

    acc[resource].push(action)

    return acc
  }, {} as PermissionsMap)
}
