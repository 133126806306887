import {
  BusinessLogicParameter,
  LabelGroup,
  LabelGroupType,
  NormalizedBusinessLogicRule,
  NormalizedLabelGroup,
  ParameterType
} from '@/types/businessLogic'
import { convertCoordinatesToString } from './coordinates'

const buildLabelGroup = (
  normalizedLabelGroup: NormalizedLabelGroup,
  parameterType: ParameterType
): LabelGroup => {
  const {
    type,
    label_id,
    threshold,
    sub_label_operator,
    sub_label_ids,
    min_dwell_time,
    alert_on_exit
  } = normalizedLabelGroup

  const labelGroup: LabelGroup = {
    type,
    label_id,
    threshold,
    ...(type === LabelGroupType.Group && {
      sub_labels: [
        {
          operator: sub_label_operator,
          label_ids: sub_label_ids
        }
      ]
    }),
    ...(parameterType === ParameterType.ROI && {
      min_dwell_time,
      alert_on_exit
    })
  }

  return labelGroup
}

type BuildBuisnessLogicParameterParams = {
  type: ParameterType
  coordinates: number[][]
  name: string
  alert: boolean
  upload_video: boolean
  upload_bbox_video: boolean
  rules: NormalizedBusinessLogicRule[]
}

export const buildBuisnessLogicParameter = ({
  type,
  coordinates,
  name,
  alert,
  upload_video,
  upload_bbox_video,
  rules
}: BuildBuisnessLogicParameterParams): BusinessLogicParameter => {
  return {
    parameter_type: type,
    coordinates: convertCoordinatesToString(coordinates),
    name,
    event: {
      alert,
      upload_video,
      upload_bbox_video
    },
    rules: rules.map((rule) => {
      return {
        operator: rule.operator,
        label_groups: rule.label_groups.map((normalizedLabelGroup) =>
          buildLabelGroup(normalizedLabelGroup, type)
        )
      }
    })
  }
}
